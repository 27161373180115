import { TStore } from "@/models/store/index";
import { Stripe, StripeCardElement } from "@stripe/stripe-js";
import {
    confirmStripeOrder,
    createStripeOrder,
} from "@/utils/queries/checkout-queries";
import {
    createPaymentMethodStripe,
    handleCard3DSecureStripe,
} from "@/utils/common/stripe";
import { getFormattedDate } from "@/utils/common/date";
import { findCountryNameById } from "@/utils/common";
import { getInvoiceData } from "@/utils/common/checkout";
import { ICheckoutOrder } from "@/models/queries/checkout";
import { getUserDetail } from "@/utils/queries/user-queries";
// eslint-disable-next-line import/no-extraneous-dependencies
import TagManager from "react-gtm-module";
import { addPaymentInfo, purchase } from "@/utils/analytics/tagmanager";
import { EPaymentMethod } from "@/models/common/enums";

export const loadOrderData = (get: () => TStore) => {
    const { checkoutState, userState } = get();
    // eslint-disable-next-line no-underscore-dangle
    const userId = userState.userDetail?._id;

    const { tourDetail } = checkoutState;

    const {
        totalAmount,
        mainCustomer,
        tour,
        rooms,
        travelers,
        coupon,
        specialRequests,
        invoice,
        toInvoiced,
        netPrice: orderNetPrice,
        markup: orderMarkup,
        pets,
    } = checkoutState.orderData;

    const amount = Number(totalAmount).toFixed(2);
    const netPrice = Number(orderNetPrice).toFixed(2);
    const markup = Number(orderMarkup).toFixed(2);
    const date = getFormattedDate(tour.date);

    const mainCustomerUpdated = {
        ...mainCustomer,
        country: findCountryNameById(mainCustomer.country || ""),
    };

    const checkCustomerId = userId ? { customerId: userId } : {};

    const couponCode = coupon?.code;

    const orderData: ICheckoutOrder = {
        ...checkCustomerId,
        ...getInvoiceData(toInvoiced, invoice),
        mainCustomer: mainCustomerUpdated,
        amount,
        netPrice,
        markup,
        chainCode: tourDetail.chainCode,
        bookingCode: tourDetail.bookingCode,
        couponCode,
        date,
        option: tour.option,
        tourId: tour.tourId,
        language: checkoutState.languageSelected,
        rooms,
        travelers,
        specialRequests,
        pets: pets ?? false,
    };

    return orderData;
};

export const loadStripeSendOrder = async (
    cardHolderName: string,
    stripe: Stripe,
    stripeCrardElement: StripeCardElement,
    onSuccessCb: () => void,
    get: () => TStore,
    _paymentMethodId?: string
) => {
    const {
        dispatchCommon,
        dispatchCheckout,
        setErrorMsg,
        checkoutState,
        afterSuccessSendOrder,
    } = get();

    const { _id: userId } = get().userState.userDetail;
    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });

        const { tourDetail } = checkoutState;

        const {
            totalAmount,
            mainCustomer,
            tour,
            rooms,
            travelers,
            coupon,
            specialRequests,
            invoice,
            toInvoiced,
            netPrice: orderNetPrice,
            markup: orderMarkup,
            pets,
        } = checkoutState.orderData;

        const amount = Number(totalAmount).toFixed(2);
        const netPrice = Number(orderNetPrice).toFixed(2);
        const markup = Number(orderMarkup).toFixed(2);
        const date = getFormattedDate(tour.date);

        const mainCustomerUpdated = {
            ...mainCustomer,
            country: findCountryNameById(mainCustomer.country || ""),
        };

        const checkCustomerId = userId ? { customerId: userId } : {};
        const couponCode = coupon?.code;

        const bookingCode = tourDetail?.bookingCode
            ? { bookingCode: tourDetail.bookingCode }
            : {};

        const striperOrderData: ICheckoutOrder = {
            ...checkCustomerId,
            ...getInvoiceData(toInvoiced, invoice),
            ...bookingCode,
            mainCustomer: mainCustomerUpdated,
            amount,
            netPrice,
            markup,
            couponCode,
            chainCode: tourDetail.chainCode,
            date,
            option: tour.option,
            tourId: tour.tourId,
            language: checkoutState.languageSelected,
            rooms,
            travelers,
            specialRequests,
            pets: pets ?? false,
        };

        const paymentMethodId =
            _paymentMethodId ||
            (
                await createPaymentMethodStripe(
                    stripe,
                    stripeCrardElement,
                    cardHolderName,
                    mainCustomer.phone || "",
                    mainCustomer.email || ""
                )
            ).paymentMethodId;

        const { clientSecret, paymentIntentId, status } =
            await createStripeOrder(
                {
                    ...striperOrderData,
                    paymentMethodNonce: paymentMethodId,
                },
                !!userId
            );

        if (status === "succeeded") {
            dispatchCheckout({
                type: "UPDATE_ORDER_DATA",
                payload: {
                    orderTransactionId: paymentIntentId,
                },
            });
            await afterSuccessSendOrder(onSuccessCb);
            return;
        }

        if (status === "requires_action") {
            await handleCard3DSecureStripe(stripe, clientSecret);
        }

        await confirmStripeOrder(
            {
                ...striperOrderData,
                paymentIntentId,
            },
            !!userId
        );
        afterSuccessSendOrder(onSuccessCb);
    } catch (error) {
        setErrorMsg(error as never);
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    } finally {
        dispatchCheckout({
            type: "SET_SUBMIT_PAYMENT",
            payload: false,
        });
    }
};

export const loadAfterSuccessSendOrder = async (
    onSuccessCb: () => void,
    get: () => TStore
) => {
    const { dispatchUser, setErrorMsg, setSuccessMsg } = get();
    const { totalAmount, tour } = get().checkoutState.orderData;
    const { tourDetail } = get().checkoutState;
    const code = get().checkoutState.orderData.coupon?.code;
    const valueDiscount = get().checkoutState.orderData.coupon?.value;

    // eslint-disable-next-line no-underscore-dangle
    const userId = get().userState.userDetail?._id;
    const amount = Number(totalAmount).toFixed(2);
    const paymentType = get().checkoutState.paymentMethod;
    const transactionId = get().checkoutState.orderData.orderTransactionId;

    try {
        if (userId) {
            const userData = await getUserDetail();
            dispatchUser({ type: "SET_USER_DETAIL", payload: userData });
        }

        if (transactionId) {
            TagManager.dataLayer(
                purchase(
                    transactionId,
                    amount,
                    tourDetail.price,
                    code,
                    tour.tourId,
                    tourDetail.tourTitle,
                    valueDiscount ?? 0,
                    tourDetail.tourType,
                    tour.option
                )
            );

            if (paymentType === EPaymentMethod.PAYPAL) {
                TagManager.dataLayer(
                    addPaymentInfo(
                        amount,
                        tourDetail.price,
                        code,
                        paymentType,
                        tour.tourId,
                        tourDetail.tourTitle,
                        valueDiscount ?? 0,
                        tourDetail.tourType,
                        tour.option
                    )
                );
            }
        }

        setSuccessMsg("Your payment was successful", onSuccessCb);
    } catch (error) {
        setErrorMsg(error as never);
    }
};
