import FileDocLine from "../../public/custom-icons/icons-2/icons/File Document/Line.svg";
import FileCheckLine from "../../public/custom-icons/icons-2/icons/File Check/Line.svg";
import QuestionLine from "../../public/custom-icons/icons-2/icons/Question Circle/Line.svg";

const fileTypesSvg = {
    FileDocLine,
    QuestionLine,
    FileCheckLine,
};

export default fileTypesSvg;
