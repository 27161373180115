import { IAuthState, TActionAuth } from "@/models/store";
import { getDefaultAuthState } from "@/utils/common/store";

export const reducerAuth = (
    state: IAuthState,
    action: TActionAuth
): IAuthState => {
    switch (action.type) {
        case "SET_AUTH_TYPE":
            return { ...state, modalAuthType: action.payload };
        case "SET_REMEMBER_ME":
            return { ...state, rememberMe: action.payload };
        case "RESET_AUTH": {
            return {
                ...getDefaultAuthState(),
                rememberMe: state.rememberMe,
            };
        }
        default:
            return state;
    }
};
