import GoogleLine from "../../public/custom-icons/icons-2/icons/Google/Line.svg";
import SketchLine from "../../public/custom-icons/icons-2/icons/Sketch/Line.svg";
import FacebookLine from "../../public/custom-icons/icons-2/icons/Facebook/Line.svg";
import TwitterLine from "../../public/custom-icons/icons-2/icons/Twitter/Line.svg";
import InstagramLine from "../../public/custom-icons/icons-2/icons/Instagram/Line.svg";
import AppleFilled from "../../public/custom-icons/icons-2/icons/Apple/Filled.svg";
import FacebookFilled from "../../public/custom-icons/icons-2/icons/Facebook/Filled.svg";

const socialAndBrandSvg = {
    SketchLine,
    FacebookLine,
    TwitterLine,
    InstagramLine,
    GoogleLine,
    AppleFilled,
    FacebookFilled,
};

export default socialAndBrandSvg;
