import { TActions, TStore } from "@/models/store";
import { ICheckoutStore } from "@/models/store/checkout";
import { getDefaultCheckoutState } from "@/utils/mockData/checkout";
import { StateCreator } from "zustand";
import { reducerCheckout } from "./reducer-checkout";
import {
    loadAfterSuccessSendOrder,
    loadOrderData,
    loadStripeSendOrder,
} from "./saga-checkout";

export const createCheckoutSlice: StateCreator<
    TStore,
    [],
    [],
    ICheckoutStore
> = (set, get) => ({
    checkoutState: { ...getDefaultCheckoutState() },
    dispatchCheckout: action =>
        set(state => ({
            ...state,
            action: action as TActions,
            checkoutState: reducerCheckout(
                state.checkoutState,
                action,
                state.userState.userDetail
            ),
        })),
    getOrderData: () => loadOrderData(get),
    sendStripeOrder: (
        cardHolderName,
        stripe,
        stripeCardElement,
        onSuccessCb,
        paymentMethodId
    ) =>
        loadStripeSendOrder(
            cardHolderName,
            stripe,
            stripeCardElement,
            onSuccessCb,
            get,
            paymentMethodId
        ),
    afterSuccessSendOrder: onSuccessCb =>
        loadAfterSuccessSendOrder(onSuccessCb, get),
});
