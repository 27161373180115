import { MDBBtn, MDBCol } from "mdb-react-ui-kit";
import { FC, useState } from "react";
import userInterfaceSvg from "@/utils/svg-icon/userInterface";
import { isValidEmail } from "@/utils/common";
import styles from "@/styles/Footer.module.scss";
import { useStore } from "@/store/index";

const NewsletterForm: FC<{ isSmall: boolean }> = ({ isSmall }) => {
    const handleNewsletter = useStore(state => state.handleNewsletter);
    const [email, setEmail] = useState("");

    const { ArrowRightLine } = userInterfaceSvg;

    return (
        <div className={styles.footInputContainer}>
            <MDBCol size={9}>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    className={`${styles.footInputCTA} d-block w-100`}
                    onChange={e => setEmail(e.target.value)}
                />
            </MDBCol>
            <MDBCol
                className={`d-inline-flex flex-row-reverse ${
                    isSmall ? "px-2" : ""
                }`}
            >
                <MDBBtn
                    tag="a"
                    floating
                    className={`${styles.footInputButton} shadow-0`}
                    disabled={!isValidEmail(email.trim())}
                    onClick={() => {
                        handleNewsletter(email);
                        setEmail("");
                    }}
                >
                    <ArrowRightLine />
                </MDBBtn>
            </MDBCol>
        </div>
    );
};

export default NewsletterForm;
