import { MDBNavbarLink } from "mdb-react-ui-kit";
import { FC, ReactNode } from "react";
import styles from "../../../../styles/navbar/NavBarM.module.scss";

const NavBarMLink: FC<{
    icon: ReactNode;
    title: string;
    subtitle: string;
    onClick: () => void;
    icon2: ReactNode;
    icon2Active: ReactNode;
    active: boolean;
    className: string;
    children: ReactNode | undefined;
}> = ({
    icon = "",
    title,
    subtitle = "",
    icon2 = "",
    icon2Active,
    active = false,
    onClick,
    className = "",
    children,
}) => {
    return (
        <div
            className={`${styles.navBarMLink} ${
                active && styles.navBarMLinkActive
            } ${className} px-2 py-2 fadeInElement`}
        >
            <MDBNavbarLink
                className="d-flex justify-content-between align-items-center"
                aria-current="page"
                onClick={onClick}
                title={title}
            >
                <div className="d-flex align-items-center">
                    <span className="me-2">{icon}</span>
                    <span className="my-2">{title}</span>
                    <span className={`me-2 ${styles.navBarMLink}`}>
                        {subtitle}
                    </span>
                </div>
                {icon2 && !active && (
                    <span className={`${styles.navBarMLinkIcon2} text-center`}>
                        {icon2}
                    </span>
                )}
                {icon2Active && active && <span>{icon2Active}</span>}
            </MDBNavbarLink>
            {children}
        </div>
    );
};

export default NavBarMLink;
