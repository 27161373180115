import { FC, useEffect } from "react";
import { BounceLoader } from "react-spinners";
import styles from "../../styles/common/Loader.module.scss";
import { useStore } from "../../store";

const LoaderPage: FC<{
    loading: boolean;
}> = ({ loading }) => {
    const dispatch = useStore(state => state.dispatchCommon);
    const loadingStore = useStore(state => state.commonState.loadingPage);

    useEffect(() => {
        dispatch({
            type: "SET_LOADER_PAGE",
            payload: false,
        });
    }, []);

    return loading || loadingStore ? (
        <div className={styles.containerSpinner}>
            <BounceLoader color="#ed6f6f" size={150} />
        </div>
    ) : null;
};

export default LoaderPage;
