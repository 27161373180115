import { RefObject, useEffect } from "react";

type Handler = (event: MouseEvent | TouchEvent) => void;

export const useOnClickOutside = (
    refs: RefObject<HTMLDivElement>[],
    handler: Handler
) => {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (
                refs.some(ref =>
                    ref.current !== null
                        ? !ref.current ||
                          ref.current.contains(event.target as Node)
                        : false
                )
            ) {
                return;
            }

            handler(event);
        };

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [refs, handler]);
};
