import { useIsClient } from "@/utils/hooks/useIsClient";
import {
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
} from "mdb-react-ui-kit";
import styles from "@/styles/auth/ConfirmModal.module.scss";
import userInterfaceSvg from "@/utils/svg-icon/userInterface";
import { useStore } from "@/store/index";
import ConfirmLogout from "./ConfirmLogout";

const ModalConfirmLogout = ({
    show,
    setShow,
}: {
    show: boolean;
    setShow: () => void;
}) => {
    const logout = useStore(state => state.logout);
    const isClient = useIsClient();
    const { CloseLine } = userInterfaceSvg;

    return isClient ? (
        <MDBModal
            show={show}
            setShow={(e: boolean) => !e && setShow()}
            tabIndex="-1"
            className={styles.modal}
        >
            <MDBModalDialog
                className={styles.modalDialog}
                shouldCloseOnOverlayClick={false}
            >
                <MDBModalContent className={styles.modalContent}>
                    <div className="text-center">
                        <span
                            className={`${styles.modalBtnClose} text-center pointer`}
                            onClick={setShow}
                        >
                            <CloseLine />
                        </span>
                    </div>
                    <MDBModalBody>
                        <ConfirmLogout
                            onClick={() => {
                                setShow();
                                logout();
                            }}
                        />
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    ) : (
        <div />
    );
};

export default ModalConfirmLogout;
