import axios from "axios";
import { Auth } from "aws-amplify";
import { IUser } from "@/models/user";

import { getUrl } from "@/utils/queries/utils/utils";
import { getHeadersAuth } from ".";

export const addCardQuery = async (tokenCard: string) => {
    const headers = await getHeadersAuth();
    const { data } = await axios.post(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/stripe/card"),
        { tokenCard },
        headers
    );
    return data;
};

export const getUserDetail = async (): Promise<IUser> => {
    const headers = await getHeadersAuth();
    const { data } = await axios.get(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/customer/details"),
        headers
    );

    return {
        ...data,
        paymentMethods: data?.paymentMethods || [],
    };
};

export const signinUser = async (userCredential: {
    username: string;
    password: string;
}) => {
    await Auth.signIn(userCredential.username, userCredential.password);
    return getUserDetail();
};

export const deletePaymentCard = async (cardId: string): Promise<IUser> => {
    const headers = await getHeadersAuth();
    const { data } = await axios.delete(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/stripe/card/${cardId}`),
        headers
    );
    return { ...data, paymentMethods: data?.paymentMethods || [] };
};
export const addCoupon = async (couponCode: string): Promise<IUser> => {
    const headers = await getHeadersAuth();
    const { data } = await axios.post(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, `/user/coupon`),
        { couponCode },
        headers
    );
    return data;
};
