import { FC, ReactNode } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from "swiper";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import "swiper/css";
import { TColumnProps } from "../../../typings/mdb-react-ui-kit";
import {
    TBreackpointsCarousel,
    TSwiperOptions,
    TSwiperSize,
} from "../../../models/common/common";

const Carousel: FC<{
    children: ReactNode[];
    carouselSizes?: TSwiperSize;
    visibleSlides?: number | "auto";
    breakpoints?: TBreackpointsCarousel;
    classNameContainer?: string;
    classNameCarousel?: string;
    classNameSlide?: string;
    spaceBetween?: number;
    setSwiperClass?: (swiper: SwiperClass) => void;
    renderContainer?: boolean;
    fullWidth?: boolean;
    centerInsufficientSlides?: boolean;
    containerSizes?: TColumnProps;
    loop?: boolean;
    autoHeight?: boolean;
    centerSlides?: boolean;
}> = ({
    carouselSizes = {},
    centerInsufficientSlides = true,
    renderContainer,
    fullWidth,
    visibleSlides = 3.5,
    breakpoints,
    setSwiperClass,
    classNameContainer = "d-flex justify-content-center h-100",
    classNameCarousel = "d-flex justify-content-center h-100",
    classNameSlide = "pb-4",
    children,
    containerSizes,
    loop = false,
    autoHeight = false,
    centerSlides = false,
}) => {
    const sizes = containerSizes || { xs: 8, md: 11, size: 11 };

    const swiperBreakpoints = fullWidth
        ? { slidesPerView: 1 }
        : {
              slidesPerView: visibleSlides,
              breakpoints: (breakpoints as {
                  [width: number]: TSwiperOptions;
              }) || {
                  0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      autoHeight: true,
                      loop,
                  },
                  576: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      autoHeight: true,
                      loop,
                  },
                  768: {
                      slidesPerView: 2,
                      spaceBetween: 32,
                      autoHeight: true,
                      loop,
                  },
                  992: {
                      slidesPerView: 2.5,
                      spaceBetween: 32,
                      autoHeight: true,
                      loop,
                  },
                  1200: {
                      slidesPerView: visibleSlides || 3,
                      spaceBetween: 32,
                      autoHeight: true,
                      loop,
                  },
                  1400: {
                      slidesPerView: visibleSlides || 3.5,
                      spaceBetween: 32,
                      height: 568,
                      autoHeight: true,
                      loop,
                  },
                  1600: {
                      slidesPerView:
                          visibleSlides && visibleSlides !== "auto"
                              ? visibleSlides + 1
                              : 4.5,
                      spaceBetween: 32,
                      height: 568,
                      autoHeight: true,
                      loop,
                  },
              },
          };
    const renderSwiper = (
        <Swiper
            onSwiper={setSwiperClass}
            className={classNameCarousel}
            {...swiperBreakpoints}
            {...carouselSizes}
            centerInsufficientSlides={centerInsufficientSlides}
            centeredSlides={centerSlides}
        >
            {children?.map((el, index) => (
                <SwiperSlide
                    // eslint-disable-next-line react/no-array-index-key
                    key={`swiper-slide-${index}`}
                    className={classNameSlide}
                >
                    {el}
                </SwiperSlide>
            ))}
        </Swiper>
    );

    return renderContainer ? (
        <MDBRow className={classNameContainer}>
            <MDBCol
                className="p-0 m-0"
                style={{ height: autoHeight ? "auto" : "40rem" }}
                {...sizes}
            >
                {renderSwiper}
            </MDBCol>
        </MDBRow>
    ) : (
        renderSwiper
    );
};

export default Carousel;
