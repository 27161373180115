import Head from "next/head";

const HeadMeta = () => {
    return (
        <Head>
            <link
                rel="apple-touch-icon"
                href="/favicon_io/apple-touch-icon.png"
            />
            <meta name="theme-color" content="#ffffff" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0', shrink-to-fit=no"
            />
            <meta name="application-name" content="Playitaly" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
                name="apple-mobile-web-app-status-bar-style"
                content="default"
            />
            <meta name="format-detection" content="telephone=no" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta
                name="msapplication-config"
                content="/icons/browserconfig.xml"
            />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-tap-highlight" content="no" />
            <link
                rel="mask-icon"
                href="/icons/safari-pinned-tab.svg"
                color="#5bbad5"
            />
            <link rel="manifest" href="/favicon_io/site.webmanifest" />
        </Head>
    );
};

export default HeadMeta;
