import { ETypeToastMessage } from "@/models/common/enums";
import { TStore } from "@/models/store";
import { IAddCreditCard } from "@/models/user";
import { removeEmptyValues } from "@/utils/common";
import { createTokenStripe } from "@/utils/common/stripe";
import {
    deleteUser,
    getSubscribeNewsletter,
    putUserDetail,
    subscribeToNewsletter,
    subscribeToNewsletterCustom,
    unsubScribeToNewsletter,
    updateAvatarUser,
} from "@/utils/queries";
import { addCardQuery, getUserDetail } from "@/utils/queries/user-queries";
import { Storage } from "aws-amplify";
import TagManager from "react-gtm-module";

export const loadAddCreditCard = async (
    crediCardData: IAddCreditCard,
    get: () => TStore
) => {
    const { dispatchCommon, setErrorMsg, setUserDetail } = get();
    const { stripe, cardElement, cardholderName, onSuccess } = crediCardData;
    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });
        const { token } = await createTokenStripe(
            stripe,
            cardElement,
            cardholderName
        );
        await addCardQuery(token.id);
        await setUserDetail();
        dispatchCommon({
            type: "SET_TOAST_MESSAGE_DATA",
            payload: {
                type: ETypeToastMessage.SUCCESS,
                message: "Added Card",
            },
        });
        onSuccess();
    } catch (err) {
        setErrorMsg(err as never);
    } finally {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};

export const loadUserDetail = async (get: () => TStore) => {
    const { dispatchCommon, dispatchUser, setErrorMsg } = get();
    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });
        const userDetail = await getUserDetail();
        dispatchUser({ type: "SET_USER_DETAIL", payload: userDetail });
    } catch (err) {
        setErrorMsg(err as never);
    } finally {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};

export const loadDeleteUser = async (get: () => TStore) => {
    const { dispatchCommon, setErrorMsg, setSuccessMsg, logout } = get();
    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });
        const res = await deleteUser();
        setSuccessMsg(res?.message || "Account deleted");
        logout();
    } catch (err) {
        setErrorMsg(err as never);
    } finally {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};

export const loadUpdateUserDetail = async (get: () => TStore) => {
    const {
        dispatchCommon,
        dispatchUser,
        setErrorMsg,
        userState,
        setSuccessMsg,
    } = get();
    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });
        await putUserDetail(removeEmptyValues(userState.userDetail));
        const userDetail = await getUserDetail();
        dispatchUser({ type: "SET_USER_DETAIL", payload: userDetail });
        setSuccessMsg("Personal info updated");
    } catch (err) {
        setErrorMsg(err as never);
    } finally {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};

export const loadHandleNewsletter = async (
    email: string,
    get: () => TStore,
    type?: "subscribe" | "unsubscribe"
) => {
    const { dispatchCommon, setErrorMsg, setSuccessMsg, dispatchUser } = get();

    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });

        if (type && type === "unsubscribe") {
            await unsubScribeToNewsletter({ email });
            setSuccessMsg("Email unsubscribed to newsletter");
            dispatchUser({ type: "SET_NEWSLETTER_MODAL", payload: false });
            return;
        }

        const resSub = await getSubscribeNewsletter(email);

        if (resSub?.subscription) {
            dispatchUser({ type: "SET_NEWSLETTER_EMAIL", payload: email });
            dispatchUser({ type: "SET_NEWSLETTER_MODAL", payload: true });
            return;
        }

        await subscribeToNewsletter({ email });
        TagManager.dataLayer({
            dataLayer: {
                event: "newsletter",
                lead_type: "iscrizione newsletter",
                email,
            },
        });
        setSuccessMsg("Email subscribed to newsletter");
    } catch (err) {
        setErrorMsg(err as never);
    } finally {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};

export const loadHandleNewsletterCustom = async (
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    emailCheck: boolean,
    phoneNumberCheck: boolean,
    get: () => TStore,
    type?: "subscribe" | "unsubscribe"
) => {
    const { dispatchCommon, setErrorMsg, setSuccessMsg, dispatchUser } = get();

    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });

        if (type && type === "unsubscribe") {
            await unsubScribeToNewsletter({ email });
            setSuccessMsg("Email unsubscribed to newsletter");
            dispatchUser({ type: "SET_NEWSLETTER_MODAL", payload: false });
            return;
        }

        const resSub = await getSubscribeNewsletter(email);

        if (resSub?.subscription) {
            dispatchUser({ type: "SET_NEWSLETTER_EMAIL", payload: email });
            dispatchUser({ type: "SET_NEWSLETTER_MODAL", payload: true });
            return;
        }

        await subscribeToNewsletterCustom({
            email,
            firstName,
            lastName,
            phoneNumber,
            emailCheck,
            phoneNumberCheck,
        });
        TagManager.dataLayer({
            dataLayer: {
                event: "newsletter",
                lead_type: "iscrizione newsletter",
                email,
            },
        });
        setSuccessMsg("Email subscribed to newsletter");
    } catch (err) {
        setErrorMsg(err as never);
    } finally {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};

export const loadUploadUserAvatar = async (
    file: File | undefined,
    get: () => TStore,
    onSuccessAfterUpload?: () => void
) => {
    const { dispatchCommon, setErrorMsg, setSuccessMsg, setUserDetail } = get();
    if (!file) {
        return;
    }
    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });

        const buffer = await file.arrayBuffer();
        const src = `play-italy-images/avatar/`;

        await Storage.put(file.name, buffer, {
            contentType: file.type,
            customPrefix: { public: src },
            resumable: false,
        });

        await updateAvatarUser({ avatar: file.name });
        setUserDetail();
        setSuccessMsg("Update successfully");
        setUserDetail();
        if (onSuccessAfterUpload) onSuccessAfterUpload();
    } catch (err) {
        setErrorMsg(err as never);
    } finally {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};
