import { useEffect } from "react";

export const useDisableBodyScroll = (open: boolean) => {
    useEffect(() => {
        if (open) {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            document.body.classList.add("overflow-hidden");
            const root = document.getElementsByTagName("html")[0];
            root.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
            const root = document.getElementsByTagName("html")[0];
            root.classList.remove("overflow-hidden");
        }
    }, [open]);
};
