import { useQuery } from "@tanstack/react-query";
import { getWishlist } from "../index";

const useWishlistData = () => {
    return useQuery({
        queryKey: ["wishlist"],
        queryFn: () => getWishlist(),
        enabled: false,
    });
};

export default useWishlistData;
