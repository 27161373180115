import { MDBCheckbox } from "mdb-react-ui-kit";
import React, { Dispatch, FC, SetStateAction } from "react";
import styles from "../../../styles/signup/Signup.module.scss";

const SignUpCheckbox: FC<{
    flagTermsAndConditions: boolean;
    flagPromotional: boolean;
    setFlagTermsAndConditions: Dispatch<SetStateAction<boolean>>;
    setFlagPromotional: Dispatch<SetStateAction<boolean>>;
}> = ({
    flagTermsAndConditions,
    flagPromotional,
    setFlagTermsAndConditions,
    setFlagPromotional,
}) => {
    return (
        <>
            <div className={styles.checkboxContainer}>
                <MDBCheckbox
                    label="Accept the terms and conditions"
                    checked={flagTermsAndConditions}
                    onChange={() =>
                        setFlagTermsAndConditions(!flagTermsAndConditions)
                    }
                    className={styles.customCheckboxChecked}
                />
            </div>
            <div className={styles.checkboxContainer}>
                <MDBCheckbox
                    label="I agree to receive promotional info about Play Italy"
                    checked={flagPromotional}
                    onChange={() => setFlagPromotional(!flagPromotional)}
                    className={styles.customCheckboxChecked}
                />
            </div>
        </>
    );
};

export default SignUpCheckbox;
