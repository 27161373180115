const removeMultipleSlashes = (url: string) =>
    url.replace(/([^:])(\/\/+)/g, "$1/");
export const getUrl = (baseUrl: string, path: string) =>
    removeMultipleSlashes(new URL(`${baseUrl}/${path}`).toString());

export const getHeaders = (token: string) => ({
    headers: {
        Authorization: `Bearer ${token}`,
    },
});
