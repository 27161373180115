import { MDBRow } from "mdb-react-ui-kit";
import { forwardRef, useState } from "react";
import styles from "../../styles/common/DatePickerButton.module.scss";

const DatePickerButton = forwardRef<
    HTMLDivElement,
    {
        value?: string;
        placeholderText?: string;
        className?: string;
        requiredValue?: boolean;
        onClick?: () => void;
    }
>(({ value, className, onClick, placeholderText = "", requiredValue }, ref) => {
    const [focused, setFocused] = useState<boolean>(false);
    const getValidationClassName = (validation: boolean) =>
        validation ? styles.success : styles.error;

    return (
        <div
            className={` ${className} ${styles.datePickerButton} ${
                requiredValue && focused ? getValidationClassName(!!value) : ""
            }`}
            onClick={() => {
                setFocused(true);
                if (onClick) {
                    onClick();
                }
            }}
            ref={ref}
        >
            <MDBRow className="w-100 d-flex align-items-center justify-content-between">
                {value || placeholderText}
            </MDBRow>
        </div>
    );
});

export default DatePickerButton;
