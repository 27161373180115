import Btn from "@/components/common/Btn";
import Input from "@/components/common/Input";
import { EBtnType } from "@/models/common/enums";
import { useStore } from "@/store/index";
import { checkPassword, isPasswordConfirmed } from "@/utils/common/auth";
import { MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const ChangePasswordBody = () => {
    const changePassword = useStore(state => state.changePassword);
    const [submitNewPassword, setSubmitNewPassword] = useState<{
        password: string;
        confirmPassword: string;
        oldPassword: string;
    }>({ oldPassword: "", password: "", confirmPassword: "" });

    useEffect(() => {
        const checkTabPress = (e: KeyboardEvent, id: string) => {
            if (e.key === "Tab") {
                switch (id) {
                    case "oldPassword":
                        document?.getElementById("password")?.focus();
                        break;
                    case "password":
                        document?.getElementById("confirmPassword")?.focus();
                        break;
                    default:
                        break;
                }
            }
        };
        document
            ?.getElementById("oldPassword")
            ?.addEventListener("keyup", event =>
                checkTabPress(event, "oldPassword")
            );
        document
            ?.getElementById("password")
            ?.addEventListener("keyup", event =>
                checkTabPress(event, "password")
            );
    }, []);

    return (
        <>
            <MDBRow>
                <form>
                    <Input
                        type="password"
                        name="oldPassword"
                        id="oldPassword"
                        placeholder="Old Password"
                        columnProps={{ size: 12, className: "my-3" }}
                        inputProps={{ autoComplete: "current-password" }}
                        required
                        hideValueAttribute
                        value={submitNewPassword.oldPassword}
                        onChange={e =>
                            setSubmitNewPassword({
                                ...submitNewPassword,
                                oldPassword: e.target.value,
                            })
                        }
                        validationCb={value => checkPassword(value as string)}
                    />
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="New Password"
                        inputProps={{ autoComplete: "new-password" }}
                        columnProps={{ size: 12, className: "my-3" }}
                        required
                        hideValueAttribute
                        value={submitNewPassword.password}
                        onChange={e =>
                            setSubmitNewPassword({
                                ...submitNewPassword,
                                password: e.target.value,
                            })
                        }
                        validationCb={value => checkPassword(value as string)}
                    />
                    <Input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        inputProps={{ autoComplete: "new-password" }}
                        columnProps={{ size: 12, className: "my-3" }}
                        required
                        hideValueAttribute
                        value={submitNewPassword.confirmPassword}
                        onChange={e =>
                            setSubmitNewPassword({
                                ...submitNewPassword,
                                confirmPassword: e.target.value,
                            })
                        }
                        validationCb={value =>
                            isPasswordConfirmed(
                                submitNewPassword.password,
                                value as string
                            )
                        }
                    />
                </form>
            </MDBRow>
            <MDBRow className="g-0 py-2">
                <Btn
                    btnType={EBtnType.PRIMARY}
                    onClick={async () =>
                        changePassword(
                            submitNewPassword.oldPassword,
                            submitNewPassword.password
                        )
                    }
                    disabled={
                        !(
                            isPasswordConfirmed(
                                submitNewPassword.password,
                                submitNewPassword.confirmPassword
                            ) && checkPassword(submitNewPassword.oldPassword)
                        )
                    }
                    fullWith
                >
                    Change Password
                </Btn>
            </MDBRow>
        </>
    );
};

export default ChangePasswordBody;
