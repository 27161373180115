export const countries = [
    {
        id: "1",
        it: "Italia",
        en: "Italy",
        ue: true,
        nationality: "Italian",
        code: "IT",
    },
    {
        id: "2",
        it: "Afghanistan",
        en: "Afghanistan",
        nationality: "Afghan",
        code: "AF",
    },
    {
        id: "3",
        it: "Albania",
        en: "Albania",
        ue: true,
        nationality: "Albanian",
        code: "AL",
    },
    {
        id: "4",
        it: "Algeria",
        en: "Algeria",
        nationality: "Algerian",
        code: "DZ",
    },
    {
        id: "5",
        it: "Andorra",
        en: "Andorra",
        ue: true,
        nationality: "Andorran",
        code: "AD",
    },
    {
        id: "6",
        it: "Angola",
        en: "Angola",
        nationality: "Angolan",
        code: "AO",
    },
    {
        id: "7",
        it: "Anguilla",
        en: "Anguilla",
        nationality: "Anguillan",
        code: "AI",
    },
    {
        id: "8",
        it: "Antartide",
        en: "Antarctica",
        nationality: "Antarctic",
        code: "AQ",
    },
    {
        id: "9",
        it: "Antigua e Barbuda",
        en: "Antigua and Barbuda",
        nationality: "Antiguan or Barbudan",
        code: "AG",
    },
    {
        id: "10",
        it: "Antille Olandesi",
        en: "Netherlands Antilles",
        nationality: "Cura\u00e7aoan",
        code: "AN",
    },
    {
        id: "11",
        it: "Arabia Saudita",
        en: "Saudi Arabia",
        nationality: "Saudi, Saudi Arabian",
        code: "SA",
    },
    {
        id: "12",
        it: "Argentina",
        en: "Argentina",
        nationality: "Argentine",
        code: "AR",
    },
    {
        id: "13",
        it: "Armenia",
        en: "Armenia",
        nationality: "Armenian",
        code: "AM",
    },
    {
        id: "14",
        it: "Aruba",
        en: "Aruba",
        nationality: "Aruban",
        code: "AW",
    },
    {
        id: "15",
        it: "Australia",
        en: "Australia",
        nationality: "Australian",
        code: "AU",
    },
    {
        id: "16",
        it: "Austria",
        en: "Austria",
        ue: true,
        nationality: "Austrian",
        code: "AT",
    },
    {
        id: "17",
        it: "Azerbaigian",
        en: "Azerbaijan",
        nationality: "Azerbaijani, Azeri",
        code: "AZ",
    },
    {
        id: "18",
        it: "Bahamas",
        en: "Bahamas",
        nationality: "Bahamian",
        code: "BS",
    },
    {
        id: "19",
        it: "Bahrain",
        en: "Bahrain",
        nationality: "Bahraini",
        code: "BH",
    },
    {
        id: "20",
        it: "Bangladesh",
        en: "Bangladesh",
        nationality: "Bangladeshi",
        code: "BD",
    },
    {
        id: "21",
        it: "Barbados",
        en: "Barbados",
        nationality: "Barbadian",
        code: "BB",
    },
    {
        id: "22",
        it: "Belgio",
        en: "Belgium",
        ue: true,
        nationality: "Belgian",
        code: "BE",
    },
    {
        id: "23",
        it: "Belize",
        en: "Belize",
        nationality: "Belizean",
        code: "BZ",
    },
    {
        id: "24",
        it: "Benin",
        en: "Benin",
        nationality: "Beninese, Beninois",
        code: "BJ",
    },
    {
        id: "25",
        it: "Bermuda",
        en: "Bermuda",
        nationality: "Bermudian, Bermudan",
        code: "BM",
    },
    {
        id: "26",
        it: "Bhutan",
        en: "Bhutan",
        nationality: "Bhutanese",
        code: "BT",
    },
    {
        id: "27",
        it: "Bielorussia",
        en: "Belarus",
        ue: true,
        nationality: "Belarusian",
        code: "BY",
    },
    {
        id: "28",
        it: "Bolivia",
        en: "Bolivia",
        nationality: "Bolivian",
        code: "BO",
    },
    {
        id: "29",
        it: "Bosnia ed Erzegovina",
        en: "Bosnia and Herzegovina",
        ue: true,
        nationality: "Bosnian or Herzegovinian",
        code: "BA",
    },
    {
        id: "30",
        it: "Botswana",
        en: "Botswana",
        nationality: "Motswana, Botswanan",
        code: "BW",
    },
    {
        id: "31",
        it: "Brasile",
        en: "Brazil",
        nationality: "Brazilian",
        code: "BR",
    },
    {
        id: "32",
        it: "Brunei",
        en: "Brunei",
        nationality: "Bruneian",
        code: "BN",
    },
    {
        id: "33",
        it: "Bulgaria",
        en: "Bulgaria",
        ue: true,
        nationality: "Bulgarian",
        code: "BG",
    },
    {
        id: "34",
        it: "Burkina Faso",
        en: "Burkina Faso",
        nationality: "Burkinab\u00e9",
        code: "BF",
    },
    {
        id: "35",
        it: "Burundi",
        en: "Burundi",
        nationality: "Burundian",
        code: "BI",
    },
    {
        id: "36",
        it: "Cambogia",
        en: "Cambodia",
        nationality: "Cambodian",
        code: "KH",
    },
    {
        id: "37",
        it: "Camerun",
        en: "Cameroon",
        nationality: "Cameroonian",
        code: "CM",
    },
    {
        id: "38",
        it: "Canada",
        en: "Canada",
        nationality: "Canadian",
        code: "CA",
    },
    {
        id: "39",
        it: "Capo Verde",
        en: "Cape Verde",
        nationality: "Cabo Verdean",
        code: "CV",
    },
    {
        id: "40",
        it: "Ciad",
        en: "Chad",
        nationality: "Chadian",
        code: "TD",
    },
    {
        id: "41",
        it: "Cile",
        en: "Chile",
        nationality: "Chilean",
        code: "CL",
    },
    {
        id: "42",
        it: "Cina",
        en: "China",
        nationality: "Chinese",
        code: "CN",
    },
    {
        id: "43",
        it: "Cipro",
        en: "Cyprus",
        ue: true,
        nationality: "Cypriot",
        code: "CY",
    },
    {
        id: "44",
        it: "Città del Vaticano",
        en: "Vatican City",
        ue: true,
        nationality: "Vatican",
        code: "VA",
    },
    {
        id: "45",
        it: "Colombia",
        en: "Colombia",
        nationality: "Colombian",
        code: "CO",
    },
    {
        id: "46",
        it: "Comore",
        en: "Comoros",
        nationality: "Comoran, Comorian",
        code: "KM",
    },
    {
        id: "47",
        it: "Corea del Nord",
        en: "North Korea",
        nationality: "North Korean",
        code: "KP",
    },
    {
        id: "48",
        it: "Corea del Sud",
        en: "South Korea",
        nationality: "South Korean",
        code: "KR",
    },
    {
        id: "49",
        it: "Costa Rica",
        en: "Costa Rica",
        nationality: "Costa Rican",
        code: "CR",
    },
    {
        id: "50",
        it: "Costa d'Avorio",
        en: "Ivory Coast",
        nationality: "Ivorian",
        code: "CI",
    },
    {
        id: "51",
        it: "Croazia",
        en: "Croatia",
        ue: true,
        nationality: "Croatian",
        code: "HR",
    },
    {
        id: "52",
        it: "Cuba",
        en: "Cuba",
        nationality: "Cuban",
        code: "CU",
    },
    {
        id: "53",
        it: "Danimarca",
        en: "Denmark",
        ue: true,
        nationality: "Danish",
        code: "DK",
    },
    {
        id: "54",
        it: "Dominica",
        en: "Dominica",
        nationality: "Dominican",
        code: "DM",
    },
    {
        id: "55",
        it: "Ecuador",
        en: "Ecuador",
        nationality: "Ecuadorian",
        code: "EC",
    },
    {
        id: "56",
        it: "Egitto",
        en: "Egypt",
        nationality: "Egyptian",
        code: "EG",
    },
    {
        id: "57",
        it: "El Salvador",
        en: "El Salvador",
        nationality: "Salvadoran",
        code: "SV",
    },
    {
        id: "58",
        it: "Emirati Arabi Uniti",
        en: "United Arab Emirates",
        nationality: "Emirati, Emirian, Emiri",
        code: "AE",
    },
    {
        id: "59",
        it: "Eritrea",
        en: "Eritrea",
        nationality: "Eritrean",
        code: "ER",
    },
    {
        id: "60",
        it: "Estonia",
        en: "Estonia",
        ue: true,
        nationality: "Estonian",
        code: "EE",
    },
    {
        id: "61",
        it: "Etiopia",
        en: "Ethiopia",
        nationality: "Ethiopian",
        code: "ET",
    },
    {
        id: "62",
        it: "Fiji",
        en: "Fiji",
        nationality: "Fijian",
        code: "FJ",
    },
    {
        id: "63",
        it: "Filippine",
        en: "Philippines",
        nationality: "Philippine, Filipino",
        code: "PH",
    },
    {
        id: "64",
        it: "Finlandia",
        en: "Finland",
        ue: true,
        nationality: "Finnish",
        code: "FI",
    },
    {
        id: "65",
        it: "Francia",
        en: "France",
        ue: true,
        nationality: "French",
        code: "FR",
    },
    {
        id: "66",
        it: "Gabon",
        en: "Gabon",
        nationality: "Gabonese",
        code: "GA",
    },
    {
        id: "67",
        it: "Gambia",
        en: "Gambia",
        nationality: "Gambian",
        code: "GM",
    },
    {
        id: "68",
        it: "Georgia",
        en: "Georgia",
        nationality: "Georgian",
        code: "GE",
    },
    {
        id: "69",
        it: "Georgia del Sud e isole Sandwich",
        en: "South Georgia and the South Sandwich Islands",
        nationality: "South Georgia or South Sandwich Islands",
        code: "GS",
    },
    {
        id: "70",
        it: "Germania",
        en: "Germany",
        ue: true,
        nationality: "German",
        code: "DE",
    },
    {
        id: "71",
        it: "Ghana",
        en: "Ghana",
        nationality: "Ghanaian",
        code: "GH",
    },
    {
        id: "72",
        it: "Giamaica",
        en: "Jamaica",
        nationality: "Jamaican",
        code: "JM",
    },
    {
        id: "73",
        it: "Giappone",
        en: "Japan",
        nationality: "Japanese",
        code: "JP",
    },
    {
        id: "74",
        it: "Gibilterra",
        en: "Gibraltar",
        ue: true,
        nationality: "Gibraltar",
        code: "GI",
    },
    {
        id: "75",
        it: "Gibuti",
        en: "Djibouti",
        nationality: "Djiboutian",
        code: "DJ",
    },
    {
        id: "76",
        it: "Giordania",
        en: "Jordan",
        nationality: "Jordanian",
        code: "JO",
    },
    {
        id: "77",
        it: "Grecia",
        en: "Greece",
        ue: true,
        nationality: "Greek, Hellenic",
        code: "GR",
    },
    {
        id: "78",
        it: "Grenada",
        en: "Grenada",
        nationality: "Grenadian",
        code: "GD",
    },
    {
        id: "79",
        it: "Groenlandia",
        en: "Greenland",
        nationality: "Greenlandic",
        code: "GL",
    },
    {
        id: "80",
        it: "Guadalupa",
        en: "Guadeloupe",
        nationality: "Guadeloupe",
        code: "GP",
    },
    {
        id: "81",
        it: "Guam",
        en: "Guam",
        nationality: "Guamanian, Guambat",
        code: "GU",
    },
    {
        id: "82",
        it: "Guatemala",
        en: "Guatemala",
        nationality: "Guatemalan",
        code: "GT",
    },
    {
        id: "83",
        it: "Guernsey - Channel Islands",
        en: "Guernsey - Channel Islands",
        nationality: "Channel Island",
        code: "GG",
    },
    {
        id: "84",
        it: "Guinea",
        en: "Guinea",
        nationality: "Guinean",
        code: "GN",
    },
    {
        id: "85",
        it: "Guinea Equatoriale",
        en: "Equatorial Guinea",
        nationality: "Equatorial Guinean, Equatoguinean",
        code: "GQ",
    },
    {
        id: "86",
        it: "Guinea-Bissau",
        en: "Guinea-Bissau",
        nationality: "Bissau-Guinean",
        code: "GW",
    },
    {
        id: "87",
        it: "Guyana",
        en: "Guyana",
        nationality: "Guyanese",
        code: "GY",
    },
    {
        id: "88",
        it: "Guyana Francese",
        en: "French Guiana",
        nationality: "French Guianese",
        code: "GF",
    },
    {
        id: "89",
        it: "Haiti",
        en: "Haiti",
        nationality: "Haitian",
        code: "HT",
    },
    {
        id: "90",
        it: "Heard Island e McDonald Islands",
        en: "Heard Island and McDonald Islands",
        nationality: "Heard Island or McDonald Islands",
        code: "HM",
    },
    {
        id: "91",
        it: "Honduras",
        en: "Honduras",
        nationality: "Honduran",
        code: "HN",
    },
    {
        id: "92",
        it: "Hong Kong",
        en: "Hong Kong",
        nationality: "Hong Kong, Hong Kongese",
        code: "HK",
    },
    {
        id: "93",
        it: "India",
        en: "India",
        nationality: "Indian",
        code: "IN",
    },
    {
        id: "94",
        it: "Indonesia",
        en: "Indonesia",
        nationality: "Indonesian",
        code: "ID",
    },
    {
        id: "95",
        it: "Iran",
        en: "Iran",
        nationality: "Iranian, Persian",
        code: "IR",
    },
    {
        id: "96",
        it: "Iraq",
        en: "Iraq",
        nationality: "Iraqi",
        code: "IQ",
    },
    {
        id: "97",
        it: "Irlanda",
        en: "Ireland",
        ue: true,
        nationality: "Irish",
        code: "IE",
    },
    {
        id: "98",
        it: "Islanda",
        en: "Iceland",
        ue: true,
        nationality: "Icelandic",
        code: "IS",
    },
    {
        id: "99",
        it: "Isola Bouvet",
        en: "Bouvet Island",
        nationality: "Bouvet Island",
        code: "BV",
    },
    {
        id: "100",
        it: "Isola Christmas",
        en: "Christmas Island",
        nationality: "Christmas Island",
        code: "CX",
    },
    {
        id: "101",
        it: "Isola di Man",
        en: "Isle of Man",
        ue: true,
        nationality: "Manx",
        code: "IM",
    },
    {
        id: "102",
        it: "Isola Norfolk",
        en: "Norfolk Island",
        nationality: "Norfolk Island",
        code: "NF",
    },
    {
        id: "103",
        it: "Isole Canarie (Spagna)",
        en: "Canary Islands (Spain)",
        nationality: "canarian",
        code: "IC",
    },
    {
        id: "104",
        it: "Isole Cayman",
        en: "Cayman Islands",
        nationality: "Caymanian",
        code: "KY",
    },
    {
        id: "105",
        it: "Isole Cocos e Keeling",
        en: "Cocos (Keeling) Islands",
        nationality: "Cocos Island",
        code: "CC",
    },
    {
        id: "106",
        it: "Isole Cook",
        en: "Cook Islands",
        nationality: "Cook Island",
        code: "CK",
    },
    {
        id: "107",
        it: "Isole Falkland",
        en: "Falkland Islands",
        nationality: "Falkland Island",
        code: "FK",
    },
    {
        id: "108",
        it: "Isole Fær Øer",
        en: "Faroe Islands",
        nationality: "Faroese",
        code: "FO",
    },
    {
        id: "109",
        it: "Isole Marianne Settentrionali",
        en: "Northern Mariana Islands",
        nationality: "Northern Marianan",
        code: "MP",
    },
    {
        id: "110",
        it: "Isole Marshall",
        en: "Marshall Islands",
        nationality: "Marshallese",
        code: "MH",
    },
    {
        id: "111",
        it: "Isole Minori Esterne degli USA",
        en: "United States Minor Outlying Islands",
        nationality: "American",
        code: "UM",
    },
    {
        id: "112",
        it: "Isole Pitcairn",
        en: "Pitcairn Islands",
        nationality: "Pitcairn Island",
        code: "PN",
    },
    {
        id: "113",
        it: "Isole Salomone",
        en: "Solomon Islands",
        nationality: "Solomon Island",
        code: "SB",
    },
    {
        id: "114",
        it: "Isole Vergini Americane",
        en: "U.S. Virgin Islands",
        nationality: "U.S. Virgin Island",
        code: "VI",
    },
    {
        id: "115",
        it: "Isole Vergini Britanniche",
        en: "British Virgin Islands",
        nationality: "British Virgin Island",
        code: "VG",
    },
    {
        id: "116",
        it: "Isole Åland",
        en: "Åland Islands",
        ue: true,
        nationality: "Åland Island",
        code: "AX",
    },
    {
        id: "117",
        it: "Israele",
        en: "Israel",
        nationality: "Israeli",
        code: "IL",
    },
    {
        id: "118",
        it: "Jersey - Channel Islands",
        en: "Jersey - Channel Islands",
        ue: true,
        nationality: "Channel Island",
        code: "JE",
    },
    {
        id: "119",
        it: "Kazakistan",
        en: "Kazakhstan",
        nationality: "Kazakhstani, Kazakh",
        code: "KZ",
    },
    {
        id: "120",
        it: "Kenia",
        en: "Kenya",
        nationality: "Kenyan",
        code: "KE",
    },
    {
        id: "121",
        it: "Kirghizistan",
        en: "Kyrgyzstan",
        nationality: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
        code: "KG",
    },
    {
        id: "122",
        it: "Kiribati",
        en: "Kiribati",
        nationality: "I-Kiribati",
        code: "KI",
    },
    {
        id: "123",
        it: "Kosovo",
        en: "Kosovo",
        ue: true,
        nationality: "kosovar",
        code: "XK",
    },
    {
        id: "124",
        it: "Kuwait",
        en: "Kuwait",
        nationality: "Kuwaiti",
        code: "KW",
    },
    {
        id: "125",
        it: "Laos",
        en: "Laos",
        nationality: "Lao, Laotian",
        code: "LA",
    },
    {
        id: "126",
        it: "Lesotho",
        en: "Lesotho",
        nationality: "Basotho",
        code: "LS",
    },
    {
        id: "127",
        it: "Lettonia",
        en: "Latvia",
        ue: true,
        nationality: "Latvian",
        code: "LV",
    },
    {
        id: "128",
        it: "Libano",
        en: "Lebanon",
        nationality: "Lebanese",
        code: "LB",
    },
    {
        id: "129",
        it: "Liberia",
        en: "Liberia",
        nationality: "Liberian",
        code: "LR",
    },
    {
        id: "130",
        it: "Libia",
        en: "Libya",
        nationality: "Libyan",
        code: "LY",
    },
    {
        id: "131",
        it: "Liechtenstein",
        en: "Liechtenstein",
        ue: true,
        nationality: "Liechtenstein",
        code: "LI",
    },
    {
        id: "132",
        it: "Lituania",
        en: "Lithuania",
        ue: true,
        nationality: "Lithuanian",
        code: "LT",
    },
    {
        id: "133",
        it: "Lussemburgo",
        en: "Luxembourg",
        ue: true,
        nationality: "Lithuanian",
        code: "LU",
    },
    {
        id: "134",
        it: "Macao",
        en: "Macao",
        nationality: "Macanese, Chinese",
        code: "MO",
    },
    {
        id: "135",
        it: "Macedonia",
        en: "Macedonia",
        nationality: "Macedonian",
        code: "MK",
    },
    {
        id: "136",
        it: "Macedonia del Nord",
        en: "North Macedonia",
        ue: true,
        nationality: "Macedonian",
        code: "MK",
    },
    {
        id: "137",
        it: "Madagascar",
        en: "Madagascar",
        nationality: "Malagasy",
        code: "MG",
    },
    {
        id: "138",
        it: "Malawi",
        en: "Malawi",
        nationality: "Malawian",
        code: "MW",
    },
    {
        id: "139",
        it: "Malaysia",
        en: "Malaysia",
        nationality: "Malaysian",
        code: "MY",
    },
    {
        id: "140",
        it: "Maldive",
        en: "Maldives",
        nationality: "Maldivian",
        code: "MV",
    },
    {
        id: "141",
        it: "Mali",
        en: "Mali",
        nationality: "Malian, Malinese",
        code: "ML",
    },
    {
        id: "142",
        it: "Malta",
        en: "Malta",
        ue: true,
        nationality: "Maltese",
        code: "MT",
    },
    {
        id: "143",
        it: "Marocco",
        en: "Morocco",
        nationality: "Moroccan",
        code: "MA",
    },
    {
        id: "144",
        it: "Martinica",
        en: "Martinique",
        nationality: "Martiniquais, Martinican",
        code: "MQ",
    },
    {
        id: "145",
        it: "Mauritania",
        en: "Mauritania",
        nationality: "Mauritanian",
        code: "MR",
    },
    {
        id: "146",
        it: "Mauritius",
        en: "Mauritius",
        nationality: "Mauritian",
        code: "MU",
    },
    {
        id: "147",
        it: "Mayotte",
        en: "Mayotte",
        nationality: "Mahoran",
        code: "YT",
    },
    {
        id: "148",
        it: "Messico",
        en: "Mexico",
        nationality: "Mexican",
        code: "MX",
    },
    {
        id: "149",
        it: "Micronesia",
        en: "Micronesia",
        nationality: "Micronesian",
        code: "FM",
    },
    {
        id: "150",
        it: "Moldavia",
        en: "Moldova",
        ue: true,
        nationality: "Moldovan",
        code: "MD",
    },
    {
        id: "151",
        it: "Monaco",
        en: "Monaco",
        ue: true,
        nationality: "Mon\u00e9gasque, Monacan",
        code: "MC",
    },
    {
        id: "152",
        it: "Mongolia",
        en: "Mongolia",
        nationality: "Mongolian",
        code: "MN",
    },
    {
        id: "153",
        it: "Montenegro",
        en: "Montenegro",
        ue: true,
        nationality: "Montenegrin",
        code: "ME",
    },
    {
        id: "154",
        it: "Montserrat",
        en: "Montserrat",
        nationality: "Montserratian",
        code: "MS",
    },
    {
        id: "155",
        it: "Mozambico",
        en: "Mozambique",
        nationality: "Montenegrin",
        code: "MZ",
    },
    {
        id: "156",
        it: "Myanmar",
        en: "Myanmar",
        nationality: "Burmese",
        code: "MM",
    },
    {
        id: "157",
        it: "Namibia",
        en: "Namibia",
        nationality: "Namibian",
        code: "NA",
    },
    {
        id: "158",
        it: "Nauru",
        en: "Nauru",
        nationality: "Nauruan",
        code: "NR",
    },
    {
        id: "159",
        it: "Nepal",
        en: "Nepal",
        nationality: "Nepali, Nepalese",
        code: "NP",
    },
    {
        id: "160",
        it: "Nicaragua",
        en: "Nicaragua",
        nationality: "Nicaraguan",
        code: "NI",
    },
    {
        id: "161",
        it: "Niger",
        en: "Niger",
        nationality: "Nigerien",
        code: "NE",
    },
    {
        id: "162",
        it: "Nigeria",
        en: "Nigeria",
        nationality: "Nigerian",
        code: "NG",
    },
    {
        id: "163",
        it: "Niue",
        en: "Niue",
        nationality: "Niuean",
        code: "NU",
    },
    {
        id: "164",
        it: "Norvegia",
        en: "Norway",
        ue: true,
        nationality: "Norwegian",
        code: "NO",
    },
    {
        id: "165",
        it: "Nuova Caledonia",
        en: "New Caledonia",
        nationality: "New Caledonian",
        code: "NC",
    },
    {
        id: "166",
        it: "Nuova Zelanda",
        en: "New Zealand",
        nationality: "New Zealand, NZ",
        code: "NZ",
    },
    {
        id: "167",
        it: "Oman",
        en: "Oman",
        nationality: "Omani",
        code: "OM",
    },
    {
        id: "168",
        it: "Paesi Bassi",
        en: "Netherlands",
        ue: true,
        nationality: "Dutch, Netherlandic",
        code: "NL",
    },
    {
        id: "169",
        it: "Pakistan",
        en: "Pakistan",
        nationality: "Pakistani",
        code: "PK",
    },
    {
        id: "170",
        it: "Palau",
        en: "Palau",
        nationality: "Palauan",
        code: "PW",
    },
    {
        id: "171",
        it: "Panama",
        en: "Panama",
        nationality: "Panamanian",
        code: "PA",
    },
    {
        id: "172",
        it: "Papua Nuova Guinea",
        en: "Papua New Guinea",
        nationality: "Papua New Guinean, Papuan",
        code: "PG",
    },
    {
        id: "173",
        it: "Paraguay",
        en: "Paraguay",
        nationality: "Paraguayan",
        code: "PY",
    },
    {
        id: "174",
        it: "Perù",
        en: "Peru",
        nationality: "Peruvian",
        code: "PE",
    },
    {
        id: "175",
        it: "Polinesia Francese",
        en: "French Polynesia",
        nationality: "French Polynesian",
        code: "PF",
    },
    {
        id: "176",
        it: "Polonia",
        en: "Poland",
        ue: true,
        nationality: "Polish",
        code: "PL",
    },
    {
        id: "177",
        it: "Porto Rico",
        en: "Puerto Rico",
        nationality: "Puerto Rican",
        code: "PR",
    },
    {
        id: "178",
        it: "Portogallo",
        en: "Portugal",
        nationality: "Portuguese",
        code: "PT",
    },
    {
        id: "179",
        it: "Qatar",
        en: "Qatar",
        nationality: "Qatari",
        code: "QA",
    },
    {
        id: "180",
        it: "Regno Unito",
        en: "United Kingdom",
        ue: true,
        nationality: "British, UK",
        code: "GB",
    },
    {
        id: "181",
        it: "Repubblica Ceca",
        en: "Czech Republic",
        ue: true,
        nationality: "Czech",
        code: "CZ",
    },
    {
        id: "182",
        it: "Repubblica Centrafricana",
        en: "Central African Republic",
        nationality: "Central African",
        code: "CF",
    },
    {
        id: "183",
        it: "Repubblica Democratica del Congo",
        en: "Democratic Republic of the Congo",
        nationality: "Congolese",
        code: "CD",
    },
    {
        id: "184",
        it: "Repubblica Dominicana",
        en: "Dominican Republic",
        nationality: "Dominican",
        code: "DO",
    },
    {
        id: "185",
        it: "Repubblica del Congo",
        en: "Republic of the Congo",
        nationality: "Congolese",
        code: "CG",
    },
    {
        id: "186",
        it: "Riunione",
        en: "Réunion",
        nationality: "R\u00e9unionese, R\u00e9unionnais",
        code: "RE",
    },
    {
        id: "187",
        it: "Romania",
        en: "Romania",
        ue: true,
        nationality: "Romanian",
        code: "RO",
    },
    {
        id: "188",
        it: "Ruanda",
        en: "Rwanda",
        nationality: "Rwandan",
        code: "RW",
    },
    {
        id: "189",
        it: "Russia",
        en: "Russia",
        nationality: "Russian",
        code: "RU",
    },
    {
        id: "190",
        it: "Sahara Occidentale",
        en: "Western Sahara",
        nationality: "Sahrawi, Sahrawian, Sahraouian",
        code: "EH",
    },
    {
        id: "191",
        it: "Saint Barthelemy",
        en: "Saint Barthélemy",
        nationality: "Barth\u00e9lemois",
        code: "BL",
    },
    {
        id: "192",
        it: "Saint Kitts e Nevis",
        en: "Saint Kitts and Nevis",
        nationality: "Kittitian or Nevisian",
        code: "KN",
    },
    {
        id: "193",
        it: "Saint Pierre e Miquelon",
        en: "Saint Pierre and Miquelon",
        nationality: "Saint-Pierrais or Miquelonnais",
        code: "PM",
    },
    {
        id: "194",
        it: "Saint Vincent e Grenadine",
        en: "Saint Vincent and the Grenadines",
        nationality: "Saint Vincentian, Vincentian",
        code: "VC",
    },
    {
        id: "195",
        it: "Samoa",
        en: "Samoa",
        nationality: "Samoan",
        code: "WS",
    },
    {
        id: "196",
        it: "Samoa Americane",
        en: "American Samoa",
        nationality: "American Samoan",
        code: "AS",
    },
    {
        id: "197",
        it: "San Marino",
        en: "San Marino",
        ue: true,
        nationality: "Sammarinese",
        code: "SM",
    },
    {
        id: "198",
        it: "Sant'Elena",
        en: "Saint Helena",
        nationality: "Saint Helenian",
        code: "SH",
    },
    {
        id: "199",
        it: "Santa Lucia",
        en: "Saint Lucia",
        nationality: "Saint Lucian",
        code: "LC",
    },
    {
        id: "200",
        it: "Senegal",
        en: "Senegal",
        nationality: "Senegalese",
        code: "SN",
    },
    {
        id: "201",
        it: "Serbia",
        en: "Serbia",
        ue: true,
        nationality: "Serbian",
        code: "RS",
    },
    {
        id: "202",
        it: "Seychelles",
        en: "Seychelles",
        nationality: "Seychellois",
        code: "SC",
    },
    {
        id: "203",
        it: "Sierra Leone",
        en: "Sierra Leone",
        nationality: "Sierra Leonean",
        code: "SL",
    },
    {
        id: "204",
        it: "Singapore",
        en: "Singapore",
        nationality: "Singaporean",
        code: "SG",
    },
    {
        id: "205",
        it: "Siria",
        en: "Syria",
        nationality: "Syrian",
        code: "SY",
    },
    {
        id: "206",
        it: "Slovacchia",
        en: "Slovakia",
        ue: true,
        nationality: "Slovak",
        code: "SK",
    },
    {
        id: "207",
        it: "Slovenia",
        en: "Slovenia",
        ue: true,
        nationality: "Slovenian, Slovene",
        code: "SI",
    },
    {
        id: "208",
        it: "Somalia",
        en: "Somalia",
        nationality: "Somali, Somalian",
        code: "SO",
    },
    {
        id: "209",
        it: "Spagna",
        en: "Spain",
        ue: true,
        nationality: "Spanish",
        code: "ES",
    },
    {
        id: "210",
        it: "Sri Lanka",
        en: "Sri Lanka",
        nationality: "Sri Lankan",
        code: "LK",
    },
    {
        id: "211",
        it: "Stato di Palestina",
        en: "State of Palestine",
        nationality: "Palestinian",
        code: "PS",
    },
    {
        id: "212",
        it: "Stati Uniti",
        en: "United States",
        nationality: "American",
        code: "US",
    },
    {
        id: "213",
        it: "Sudafrica",
        en: "South Africa",
        nationality: "South African",
        code: "ZA",
    },
    {
        id: "214",
        it: "Sud Sudan",
        en: "South Sudan",
        nationality: "South Sudanese",
        code: "SS",
    },
    {
        id: "215",
        it: "Sudan",
        en: "Sudan",
        nationality: "Sudanese",
        code: "SD",
    },
    {
        id: "216",
        it: "Suriname",
        en: "Suriname",
        nationality: "Surinamese",
        code: "SR",
    },
    {
        id: "217",
        it: "Svalbard e Jan Mayen",
        en: "Svalbard and Jan Mayen",
        ue: true,
        nationality: "Svalbard",
        code: "SJ",
    },
    {
        id: "218",
        it: "Svezia",
        en: "Sweden",
        ue: true,
        nationality: "Swedish",
        code: "SE",
    },
    {
        id: "219",
        it: "Svizzera",
        en: "Switzerland",
        ue: true,
        nationality: "Swiss",
        code: "CH",
    },
    {
        id: "220",
        it: "Swaziland",
        en: "Eswatini",
        nationality: "Swazi",
        code: "SZ",
    },
    {
        id: "221",
        it: "São Tomé e Príncipe",
        en: "São Tomé and Príncipe",
        nationality: "Santomean",
        code: "ST",
    },
    {
        id: "222",
        it: "Tagikistan",
        en: "Tajikistan",
        nationality: "Tajikistani",
        code: "TJ",
    },
    {
        id: "223",
        it: "Taiwan",
        en: "Taiwan",
        nationality: "Chinese, Taiwanese",
        code: "TW",
    },
    {
        id: "224",
        it: "Tanzania",
        en: "Tanzania",
        nationality: "Tanzanian",
        code: "TZ",
    },
    {
        id: "225",
        it: "Terre australi e ant. francesi",
        en: "French Southern and Antarctic Lands",
        nationality: "French Southern Territories",
        code: "TF",
    },
    {
        id: "226",
        it: "Terr. Britannico dell'Oc. Ind.",
        en: "British Indian Ocean Territory",
        nationality: "BIOT",
        code: "IO",
    },
    {
        id: "227",
        it: "Thailandia",
        en: "Thailand",
        nationality: "Thai",
        code: "TH",
    },
    {
        id: "228",
        it: "Timor Est",
        en: "East Timor",
        nationality: "Timorese",
        code: "TL",
    },
    {
        id: "229",
        it: "Togo",
        en: "Togo",
        nationality: "Togolese",
        code: "TG",
    },
    {
        id: "230",
        it: "Tokelau",
        en: "Tokelau",
        nationality: "Tokelauan",
        code: "TK",
    },
    {
        id: "231",
        it: "Tonga",
        en: "Tonga",
        nationality: "Tongan",
        code: "TO",
    },
    {
        id: "232",
        it: "Trinidad e Tobago",
        en: "Trinidad and Tobago",
        nationality: "Trinidadian or Tobagonian",
        code: "TT",
    },
    {
        id: "233",
        it: "Tunisia",
        en: "Tunisia",
        nationality: "Tunisian",
        code: "TN",
    },
    {
        id: "234",
        it: "Turchia",
        en: "Turkey",
        nationality: "Turkish",
        code: "TR",
    },
    {
        id: "235",
        it: "Turkmenistan",
        en: "Turkmenistan",
        nationality: "Turkmen",
        code: "TM",
    },
    {
        id: "236",
        it: "Turks e Caicos",
        en: "Turks and Caicos",
        nationality: "Turks and Caicos Island",
        code: "TC",
    },
    {
        id: "237",
        it: "Tuvalu",
        en: "Tuvalu",
        nationality: "Tuvaluan",
        code: "TV",
    },
    {
        id: "238",
        it: "Ucraina",
        en: "Ukraine",
        ue: true,
        nationality: "Ukrainian",
        code: "UA",
    },
    {
        id: "239",
        it: "Uganda",
        en: "Uganda",
        nationality: "Ugandan",
        code: "UG",
    },
    {
        id: "240",
        it: "Ungheria",
        en: "Hungary",
        ue: true,
        nationality: "Hungarian, Magyar",
        code: "HU",
    },
    {
        id: "241",
        it: "Uruguay",
        en: "Uruguay",
        nationality: "Uruguayan",
        code: "UY",
    },
    {
        id: "242",
        it: "Uzbekistan",
        en: "Uzbekistan",
        nationality: "Uzbekistani, Uzbek",
        code: "UZ",
    },
    {
        id: "243",
        it: "Vanuatu",
        en: "Vanuatu",
        nationality: "Ni-Vanuatu, Vanuatuan",
        code: "VU",
    },
    {
        id: "244",
        it: "Venezuela",
        en: "Venezuela",
        nationality: "Venezuelan",
        code: "VE",
    },
    {
        id: "245",
        it: "Vietnam",
        en: "Vietnam",
        nationality: "Vietnamese",
        code: "VN",
    },
    {
        id: "246",
        it: "Wallis e Futuna",
        en: "Wallis and Futuna",
        nationality: "Wallis and Futuna, Wallisian or Futunan",
        code: "WF",
    },
    {
        id: "247",
        it: "Yemen",
        en: "Yemen",
        nationality: "Yemeni",
        code: "YE",
    },
    {
        id: "248",
        it: "Zambia",
        en: "Zambia",
        nationality: "Zambian",
        code: "ZM",
    },
    {
        id: "249",
        it: "Zimbabwe",
        en: "Zimbabwe",
        nationality: "Zimbabwean",
        code: "ZW",
    },
];
