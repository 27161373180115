import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import Image from "next/image";
import { useRouter } from "next/router";
import { URLS } from "@/utils/constants";
import { forwardRef, ReactElement, useEffect, useMemo, useState } from "react";
import SwiperClass from "swiper";
import styles from "../../../styles/navbar/DropdownMenu.module.scss";
import userInterfaceSvg from "../../../utils/svg-icon/userInterface";
import { DropDownItems } from "../../../models/common/enums";
import CardTour from "../../home/cards/cardTour/CardTour";
import ButtonBack from "../carousel/ButtonBack";
import ButtonNext from "../carousel/ButtonNext";
import Carousel from "../carousel/Carousel";
import {
    ToursByDestinationEntity,
    ToursByTourTypeEntity,
} from "../../../models/home";
import { TBreackpointsCarousel } from "../../../models/common/common";

// TODO: Remove eslint disable
const DropdownMenu = forwardRef<
    HTMLDivElement,
    {
        dropdownChoice: DropDownItems;
        dropdownDataDestinations: ToursByDestinationEntity[];
        dropdownDataTours: ToursByTourTypeEntity[];
        onMouseLeave: () => void;
    }
>(
    (
        {
            dropdownChoice,
            dropdownDataDestinations,
            dropdownDataTours,
            onMouseLeave,
        },
        ref
        // eslint-disable-next-line sonarjs/cognitive-complexity
    ): ReactElement => {
        const [swiperClass, setSwiperClass] = useState<SwiperClass | null>(
            null
        );

        const path = URLS.allTours;
        const [optionHover, setOptionHover] = useState<number>(0);
        const [loadMore, setLoadMore] = useState<number>(0);
        const [coverError, setCoverError] = useState<boolean>(false);

        const router = useRouter();

        const dropdownData = useMemo(() => {
            return dropdownChoice === DropDownItems.TOURS
                ? dropdownDataTours
                : dropdownDataDestinations;
        }, [dropdownChoice, dropdownDataTours, dropdownDataDestinations]);

        const selectedDropdownOption = useMemo(() => {
            return dropdownData.at(optionHover);
        }, [dropdownData, optionHover]);

        const options = useMemo(() => {
            return dropdownData.map(item => {
                if (dropdownChoice === DropDownItems.TOURS) {
                    return (item as ToursByTourTypeEntity).tourType;
                }
                return (item as ToursByDestinationEntity).city;
            });
        }, [dropdownData]);

        const hasOtherItemsToShow = () => {
            return options.slice(loadMore + 5).length > 0;
        };

        useEffect(() => {
            setLoadMore(0);
            setOptionHover(0);
        }, [dropdownChoice]);

        const visibleCards = useMemo(() => {
            return (
                selectedDropdownOption?.tours?.map(element => {
                    return (
                        /* eslint-disable no-underscore-dangle */
                        <CardTour
                            className={`mx-2 mx-md-0 ${styles.dropDownCarouselCard}`}
                            classNameBody={styles.dropDownCarouselCardBody}
                            key={element._id}
                            content={element}
                            isSmall
                        />
                        /* eslint-enable no-underscore-dangle */
                    );
                }) ?? []
            );
        }, [selectedDropdownOption]);

        const handleRouting = () => {
            const url = {
                pathname: path,
                query: {
                    tourType:
                        dropdownChoice === DropDownItems.TOURS
                            ? (selectedDropdownOption as ToursByTourTypeEntity)
                                  .type
                            : null,
                    destination:
                        dropdownChoice === DropDownItems.DESTINATIONS
                            ? (
                                  selectedDropdownOption as ToursByDestinationEntity
                              ).city
                            : null,
                },
            };
            if (router.pathname !== path) {
                router.push(url);
            } else {
                router.replace(url).then(() => router.reload());
            }
        };

        const breackPointCarousel: TBreackpointsCarousel = {
            0: {
                slidesPerView: 1.3,
                spaceBetween: 32,
                height: 530,
            },
            576: {
                slidesPerView: 1.3,
                spaceBetween: 32,
                height: 530,
            },
            768: {
                slidesPerView: 2,
                autoHeight: true,
                width: 200,
            },
            992: {
                slidesPerView: 1,
                autoHeight: true,
                width: 220,
                spaceBetween: 32,
            },
            1200: {
                slidesPerView: 1,
                autoHeight: true,
                width: 220,
                spaceBetween: 32,
            },
        };

        return (
            <div
                className={`${styles.dropdownContainer} position-absolute w-100`}
                onMouseLeave={onMouseLeave}
            >
                <div
                    ref={ref}
                    className={`${styles.dropdownContent} bg-light container-fluid`}
                >
                    <MDBRow className="h-100">
                        <MDBCol
                            size={3}
                            className={`${styles.dropdownColumnLeft} position-relative `}
                        >
                            <Image
                                alt="tour-image"
                                src={
                                    coverError
                                        ? "/images/tours/across-italy-reduced.jpg"
                                        : selectedDropdownOption?.image ??
                                          "/images/tours/across-italy-reduced.jpg"
                                }
                                fill
                                className={styles.dropdownColumnLeft}
                                onError={() => setCoverError(true)}
                            />
                            <div
                                className={`d-flex flex-column justify-content-end  px-3 ${styles.dropdownInnerColumn}`}
                            >
                                <MDBBtn
                                    className={`${styles.dropdownColumnLeftButton} rounded-9`}
                                    onClick={() => {
                                        router
                                            .push(
                                                {
                                                    pathname: path,
                                                    query: {
                                                        tourType: null,
                                                        destination: null,
                                                    },
                                                },
                                                path,
                                                { shallow: true }
                                            )
                                            .then(() => router.reload());
                                    }}
                                >
                                    {"Discover all of our tours"}{" "}
                                    <div className="ps-3">
                                        <userInterfaceSvg.StarFilled />
                                    </div>
                                </MDBBtn>
                            </div>
                        </MDBCol>
                        <MDBCol size={6} className="bg-light p-0">
                            <MDBRow className="h-100 g-0">
                                <MDBCol
                                    size={6}
                                    className={styles.dropdownInnerColumn}
                                >
                                    <div
                                        className={`border-end d-flex flex-column justify-content-between h-100 ${styles.dropdownColumnContent}`}
                                    >
                                        <div>
                                            <h4
                                                className={
                                                    styles.dropdownColumnTitle
                                                }
                                            >
                                                {dropdownChoice ===
                                                DropDownItems.TOURS
                                                    ? "Tour types"
                                                    : "Destinations"}
                                            </h4>
                                            <div className="pt-3">
                                                {options
                                                    .slice(
                                                        loadMore,
                                                        loadMore + 5
                                                    )
                                                    .map(
                                                        (
                                                            el: string,
                                                            i: number
                                                        ) => {
                                                            return (
                                                                /* eslint-disable react/no-array-index-key */
                                                                <div
                                                                    key={`${el}-${i}`}
                                                                    className={
                                                                        styles.dropdownColumnOptions
                                                                    }
                                                                    onMouseEnter={() =>
                                                                        setOptionHover(
                                                                            i +
                                                                                loadMore
                                                                        )
                                                                    }
                                                                    onClick={
                                                                        handleRouting
                                                                    }
                                                                >
                                                                    <p
                                                                        className={
                                                                            optionHover ===
                                                                            i +
                                                                                loadMore
                                                                                ? styles.titleSelected
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {el}
                                                                    </p>
                                                                    <userInterfaceSvg.ArrowRightSimpleLine />
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                styles.dropdownColumnCenterButtonContainer
                                            }
                                        >
                                            {options.length > 5 && (
                                                <div className="d-flex justify-content-between ">
                                                    <MDBBtn
                                                        className={`${
                                                            styles.dropdownColumnCenterButton
                                                        } 
                                                                    ${
                                                                        hasOtherItemsToShow()
                                                                            ? "d-flex"
                                                                            : "invisible"
                                                                    }
                                                                    rounded-9 w-50`}
                                                        onClick={() =>
                                                            setLoadMore(
                                                                () =>
                                                                    loadMore + 5
                                                            )
                                                        }
                                                    >
                                                        Load More
                                                    </MDBBtn>
                                                    <MDBBtn
                                                        tag="a"
                                                        floating
                                                        className={`${
                                                            styles.dropdownColumnCenterBackButton
                                                        } ${
                                                            loadMore > 0
                                                                ? "d-flex"
                                                                : "d-none "
                                                        } shadow-0`}
                                                        onClick={() =>
                                                            setLoadMore(
                                                                () =>
                                                                    loadMore - 5
                                                            )
                                                        }
                                                    >
                                                        <userInterfaceSvg.ArrowLeftLine />
                                                    </MDBBtn>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </MDBCol>
                                <MDBCol
                                    size={6}
                                    className={styles.dropdownInnerColumn}
                                >
                                    <div
                                        className={`${styles.dropdownColumnContent} d-flex flex-column justify-content-between h-100 `}
                                    >
                                        <div>
                                            <h4
                                                className={
                                                    styles.dropdownColumnTitle
                                                }
                                            >
                                                {selectedDropdownOption?.title}
                                            </h4>
                                            <div className="pt-4">
                                                <p
                                                    className={
                                                        styles.columnParagraph
                                                    }
                                                >
                                                    {
                                                        selectedDropdownOption?.body
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <MDBBtn
                                                className={`${styles.dropdownColumnCenterButton} rounded-9`}
                                                onClick={handleRouting}
                                            >
                                                View More
                                            </MDBBtn>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol
                            size={3}
                            className={`${styles.dropdownColumnRight} `}
                        >
                            <MDBRow className="h-100">
                                <MDBCol className={styles.dropdownInnerColumn}>
                                    <div
                                        className="h-full"
                                        style={{ position: "relative" }}
                                    >
                                        <div
                                            className={`${styles.dropdownColumnContent} d-flex justify-content-between`}
                                        >
                                            <h4
                                                className={
                                                    styles.dropdownColumnTitle
                                                }
                                            >
                                                Our Tours
                                            </h4>
                                            {visibleCards.length > 1 && (
                                                <div className="d-flex mt-3 mb-3">
                                                    <ButtonBack
                                                        swiperClass={
                                                            swiperClass
                                                        }
                                                    />
                                                    <ButtonNext
                                                        className="ms-2"
                                                        swiperClass={
                                                            swiperClass
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                position: "relative",
                                                right: -1,
                                            }}
                                        >
                                            <Carousel
                                                renderContainer
                                                classNameContainer="d-flex justify-content-center align-items-center h-full"
                                                breakpoints={
                                                    breackPointCarousel
                                                }
                                                setSwiperClass={setSwiperClass}
                                            >
                                                {visibleCards}
                                            </Carousel>
                                        </div>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>
        );
    }
);

export default DropdownMenu;
