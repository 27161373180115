import { useQuery } from "@tanstack/react-query";
import { getNavBarData } from "../index";

const useNavBarData = () => {
    return useQuery({
        queryKey: ["navBar"],
        queryFn: () => getNavBarData(),
        refetchOnWindowFocus: false,
    });
};

export default useNavBarData;
