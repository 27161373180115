import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { EModalAuthType } from "@/models/common/enums";
import LoginBody from "./login/LoginBody";
import SignUpBody from "./signup/SignUpBody";
import ForgotPasswordBody from "./forgotPassword/ForgotPasswordBody";
import ChangePasswordBody from "./changePassword/ChangePasswordBody";
import DeleteUserBody from "./deleteUser/DeleteUserBody";

type Props = {
    authType: EModalAuthType;
    setStaticBackdrop: (val: boolean) => void;
};

const AuthModalBody = ({ authType, setStaticBackdrop }: Props) => (
    <MDBRow className="justify-content-center w-100 mx-0">
        <MDBCol size={12} className="mb-3 px-2 px-lg-5 mx-0">
            {(authType === EModalAuthType.LOGIN ||
                authType === EModalAuthType.PRELOGIN) && <LoginBody />}
            {authType === EModalAuthType.SIGNUP && (
                <SignUpBody setStaticBackdrop={setStaticBackdrop} />
            )}
            {authType === EModalAuthType.FORGOT_PASSWORD && (
                <ForgotPasswordBody setStaticBackdrop={setStaticBackdrop} />
            )}
            {authType === EModalAuthType.CHANGE_PASSWORD && (
                <ChangePasswordBody />
            )}
            {authType === EModalAuthType.DELETE_USER && <DeleteUserBody />}
        </MDBCol>
    </MDBRow>
);

export default AuthModalBody;
