import Btn from "@/components/common/Btn";
import { EBtnType } from "@/models/common/enums";
import { useStore } from "@/store/index";
import { MDBRow } from "mdb-react-ui-kit";
import React from "react";

const DeleteUserBody = () => {
    const deleteUser = useStore(state => state.deleteUser);

    return (
        <MDBRow className="g-0 py-2">
            <Btn
                btnType={EBtnType.PRIMARY}
                onClick={async () => deleteUser()}
                fullWith
            >
                Delete
            </Btn>
        </MDBRow>
    );
};

export default DeleteUserBody;
