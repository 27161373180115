import { MDBBtn } from "mdb-react-ui-kit";
import { useRouter } from "next/router";
import contactsSvg from "@/utils/svg-icon/contacts";
import { randomUid } from "@/utils/randomIds";
import { ZendeskAPI } from "react-zendesk";
import { EZenDeskButton } from "@/models/common/enums";
import style from "../../../../styles/navbar/NavBarM.module.scss";

const NavBarMActionBtn = () => {
    const router = useRouter();
    const actionButtons = [
        {
            title: "Choose your tour",
            id: randomUid(),
            icon: contactsSvg.MapsLine,
            style: style.navBarMActionBtn,
            identificator: "navigate-tours",
            onClick: () => {
                router.push("/tours-destinations");
            },
        },
        {
            title: "Chat with us",
            id: randomUid(),
            icon: contactsSvg.Comment,
            style: style.navBarMActionBtn,
            identificator: "navigate-home",
            path: "/",
            onClick: () => {
                ZendeskAPI("messenger", EZenDeskButton.OPEN);
            },
        },
    ];

    return (
        <>
            {actionButtons.map(button => (
                <MDBBtn
                    key={button.id}
                    outline
                    rounded
                    color="white"
                    className={`${button.style} ${style.navBarMParagraph} align-self-center p-2`}
                    size="sm"
                    type="button"
                    id={button.identificator}
                    onClick={button.onClick}
                >
                    {button.title}
                    <button.icon className={`${style.svg} ms-1`} alt="" />
                </MDBBtn>
            ))}
        </>
    );
};

export default NavBarMActionBtn;
