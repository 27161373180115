import { MDBCheckbox, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, {
    ChangeEvent,
    Dispatch,
    FC,
    SetStateAction,
    useState,
} from "react";
import styles from "@/styles/home/Home.ModalNewsletter.module.scss";
import { ISectionTitle } from "@/models/common/common";
import { EBtnType } from "@/models/common/enums";
import { useStore } from "@/store/index";
import { isValidEmail } from "@/utils/common";
import SectionTitle from "../common/SectionTitle";
import Input from "../common/Input";
import Btn from "../common/Btn";

type ValueProps = {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailCheck: boolean;
    phoneNumberCheck: boolean;
};

const ModalNewsletter: FC<{
    sectionTitle: ISectionTitle;
    setShowModal: Dispatch<SetStateAction<boolean>>;
}> = ({ sectionTitle, setShowModal }) => {
    const handleNewsletterCustom = useStore(
        state => state.handleNewsletterCustom
    );

    const [valueForm, setValueForm] = useState<ValueProps>({
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        emailCheck: false,
        phoneNumberCheck: false,
    });

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValueForm({ ...valueForm, [name]: value });
    };

    return (
        <MDBRow>
            <MDBCol sm={12} md={5}>
                <div className={styles.background} />
            </MDBCol>
            <MDBCol sm={12} md={7}>
                <SectionTitle
                    sectionTitle={{
                        classNameTitle: `text-center mt-3 ${styles.title}`,
                        classNameSubTitle: `text-center ${styles.subtitle}`,
                        ...sectionTitle,
                    }}
                />
                <p style={{ textAlign: "right", fontSize: "13px" }}>
                    <span style={{ color: "#ed6f6f" }}>*</span> indicates
                    required
                </p>
                <MDBRow>
                    <MDBCol size={12}>
                        <div className="mt-3">
                            <Input
                                name="email"
                                label="Email Address"
                                required
                                value={valueForm.email}
                                placeholder="Email Address"
                                onChange={onChange}
                                type="text"
                            />
                        </div>
                        <div className="mt-3">
                            <Input
                                name="firstName"
                                label="First Name"
                                required
                                value={valueForm.firstName}
                                placeholder="First Name"
                                onChange={onChange as never}
                                type="text"
                            />
                        </div>
                        <div className="mt-3">
                            <Input
                                name="lastName"
                                label="Last Name"
                                required
                                value={valueForm.lastName}
                                placeholder="Last Name"
                                onChange={onChange as never}
                                type="text"
                            />
                        </div>
                        <div className="mt-3">
                            <Input
                                name="phoneNumber"
                                label="Phone Number"
                                required
                                value={valueForm.phoneNumber}
                                placeholder="Phone Number"
                                onChange={onChange as never}
                                type="text"
                            />
                        </div>
                        <MDBRow>
                            <MDBRow className="mt-3 ">
                                <p className={styles.bigText}>
                                    Marketing Permissions
                                </p>
                            </MDBRow>
                            <MDBRow className="mt-1">
                                <p className={styles.smallText}>
                                    Please select all the ways you would like to
                                    hear from Play Italy:
                                </p>
                            </MDBRow>
                            <MDBRow>
                                <MDBCheckbox
                                    label="Email"
                                    className={`mx-3 ${styles.formCheckLabel}`}
                                    name="emailCheck"
                                    onChange={() => {
                                        setValueForm({
                                            ...valueForm,
                                            emailCheck: !valueForm.emailCheck,
                                        });
                                    }}
                                    value={
                                        valueForm.emailCheck as unknown as string
                                    }
                                    labelClass={styles.formCheckLabel}
                                />
                            </MDBRow>
                            <MDBRow>
                                <MDBCheckbox
                                    label="Phone Number"
                                    className="mx-3"
                                    name="phoneNumberCheck"
                                    onChange={() => {
                                        setValueForm({
                                            ...valueForm,
                                            phoneNumberCheck:
                                                !valueForm.phoneNumberCheck,
                                        });
                                    }}
                                    value={
                                        valueForm.phoneNumberCheck as unknown as string
                                    }
                                    labelClass={styles.formCheckLabel}
                                />
                            </MDBRow>
                            <MDBRow className="mt-3">
                                <p className={styles.smallText}>
                                    You can unsubscribe at any time by clicking
                                    the link in the footer of our emails. For
                                    information about our privacy practices,
                                    please visit our website.
                                </p>
                            </MDBRow>
                            <MDBRow>
                                <p className={styles.smallText}>
                                    We use Mailchimp as our marketing platform.
                                    By clicking below to subscribe, you
                                    acknowledge that your information will be
                                    transferred to Mailchimp for processing.
                                    Learn more about Mailchimp&apos;s privacy
                                    practices.
                                </p>
                            </MDBRow>
                            <MDBRow className="px-sm-5">
                                <Btn
                                    btnType={EBtnType.PRIMARY}
                                    onClick={() => {
                                        handleNewsletterCustom(
                                            valueForm.email,
                                            valueForm.firstName,
                                            valueForm.lastName,
                                            valueForm.phoneNumber,
                                            valueForm.emailCheck,
                                            valueForm.phoneNumberCheck
                                        );
                                        setValueForm({
                                            email: "",
                                            firstName: "",
                                            lastName: "",
                                            phoneNumber: "",
                                            emailCheck: valueForm.emailCheck,
                                            phoneNumberCheck:
                                                valueForm.phoneNumberCheck,
                                        });
                                        setShowModal(false);
                                    }}
                                    disabled={
                                        !isValidEmail(valueForm.email.trim()) ||
                                        valueForm.firstName.length === 0 ||
                                        valueForm.lastName.length === 0 ||
                                        valueForm.phoneNumber.length === 0 ||
                                        (!valueForm.emailCheck &&
                                            !valueForm.phoneNumberCheck)
                                    }
                                >
                                    Subscribe
                                </Btn>
                            </MDBRow>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBCol>
        </MDBRow>
    );
};

export default ModalNewsletter;
