import { URLS } from "../constants";
import { getUserToken } from "../queries";

export type AuthCheckResult = {
    redirectData?: {
        redirect: {
            destination: string;
            permanent: boolean;
        };
    };
    isAuth: boolean;
};

// eslint-disable-next-line
export const checkAuth = async (
    onSuccess?: () => void,
    onFailure?: () => void
): Promise<AuthCheckResult> => {
    try {
        const res = await getUserToken();
        if (res) {
            if (onSuccess) onSuccess();
            return {
                isAuth: true,
                redirectData: {
                    redirect: {
                        destination: URLS.userProfile,
                        permanent: false,
                    },
                },
            };
        }
        throw new Error("Not Authenticated");
    } catch (err) {
        if (onFailure) onFailure();
        return {
            isAuth: false,
            redirectData: {
                redirect: {
                    destination: URLS.home,
                    permanent: false,
                },
            },
        };
    }
};

export const checkPassword = (value: string | undefined) =>
    !!(value && value.length >= 8);

export const isPasswordConfirmed = (
    password: string | undefined,
    confirmPassword: string | undefined
) =>
    checkPassword(password) &&
    checkPassword(confirmPassword) &&
    password === confirmPassword;
