import { MDBBtn } from "mdb-react-ui-kit";
import { FC, useEffect, useState } from "react";
import SwiperClass from "swiper";
import styles from "../../../styles/common/carousel/Buttons.module.scss";
import arrowsSvg from "../../../utils/svg-icon/arrows";

const ButtonNext: FC<{
    className?: string;
    swiperClass: SwiperClass | null;
}> = ({ className = "", swiperClass }) => {
    const { ArrowRight } = arrowsSvg;
    const [active, setActive] = useState(false);
    const isActive = () => swiperClass && !swiperClass?.isEnd;

    swiperClass?.on("slideChange", () => setActive(!!isActive()));

    const onNext = () => {
        if (isActive()) swiperClass?.slideNext();
    };

    useEffect(() => {
        if (swiperClass) setActive(!!isActive());
    }, [swiperClass]);

    return (
        <MDBBtn
            className={`${
                active ? styles.buttonActive : styles.buttonInactive
            } ${className} d-flex justify-content-center`}
            tag="a"
            floating
            onClick={onNext}
        >
            <ArrowRight className="align-self-center" />
        </MDBBtn>
    );
};

export default ButtonNext;
