import React from "react";
import { useStore } from "@/store/index";
import { EModalAuthType } from "@/models/common/enums";
import PreLogin from "./PreLogin";
import LoginWithEmail from "./LoginWithEmail";

export type TLogin = {
    email: string;
    password: string;
};

const LoginBody = () => {
    const authType = useStore(state => state.authState.modalAuthType);

    return (
        <>
            {authType === EModalAuthType.PRELOGIN && <PreLogin />}
            {authType === EModalAuthType.LOGIN && <LoginWithEmail />}
        </>
    );
};

export default LoginBody;
