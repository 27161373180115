import { FC } from "react";
import userInterfaceSvg from "../../../utils/svg-icon/userInterface";

const BurgerIconMenu: FC<{ isOpened: boolean; openMenu: () => void }> = ({
    isOpened,
    openMenu,
}) => {
    const Burger = userInterfaceSvg.BurgerLine;
    const Close = userInterfaceSvg.CloseLine;

    return (
        <div className="fadeInElement">
            {isOpened ? (
                <Close onClick={() => openMenu()} />
            ) : (
                <Burger onClick={() => openMenu()} />
            )}
        </div>
    );
};

export default BurgerIconMenu;
