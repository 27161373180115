import axios from "axios";
import {
    ICheckoutOrder,
    IConfirmStripeOrderArgs,
    ICreateStripeOrderArgs,
    ICreateStripeOrderRes,
    IPaypalOrderArgs,
    PaypalCreateResponseTO,
} from "@/models/queries/checkout";
import { getUrl } from "@/utils/queries/utils/utils";
import { getHeadersAuth } from ".";

export const createStripeOrder = async (
    orderData: ICreateStripeOrderArgs,
    authenticated: boolean
): Promise<ICreateStripeOrderRes> => {
    const headers = authenticated ? await getHeadersAuth() : {};

    const { data } = await axios.post(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/order/create/stripe"),
        orderData,
        headers
    );
    return data;
};

export interface IConfirmStripeOrderRes {
    paymentIntentId: string;
}

export const confirmStripeOrder = async (
    orderData: IConfirmStripeOrderArgs,
    authenticated: boolean
): Promise<IConfirmStripeOrderRes> => {
    const headers = authenticated ? await getHeadersAuth() : {};
    const { data } = await axios.post(
        getUrl(`${process.env.NEXT_PUBLIC_BASE_URL}`, "/order/confirm/stripe"),
        orderData,
        headers
    );
    return data;
};

export const createPaypal = async (
    body: ICheckoutOrder,
    authenticated: boolean
): Promise<PaypalCreateResponseTO> => {
    const headers = authenticated ? await getHeadersAuth() : {};
    try {
        const { data } = await axios.post(
            getUrl(
                `${process.env.NEXT_PUBLIC_BASE_URL}`,
                "/order/create/paypal"
            ),
            body,
            headers
        );
        return data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            // Access to config, request, and response
            throw new Error(err.response?.data.message ?? err.message);
        } else {
            // Just a stock error
            throw err;
        }
    }
};

export const confirmPaypal = async (
    body: IPaypalOrderArgs,
    authenticated: boolean
) => {
    try {
        const headers = authenticated ? await getHeadersAuth() : {};
        const { data } = await axios.post(
            getUrl(
                `${process.env.NEXT_PUBLIC_BASE_URL}`,
                "/order/confirm/paypal"
            ),
            body,
            headers
        );
        return data;
    } catch (err) {
        if (axios.isAxiosError(err)) {
            // Access to config, request, and response
            throw new Error(err.response?.data.message ?? err.message);
        } else {
            // Just a stock error
            throw err;
        }
    }
};

export const hasAcceptedPrivacyPolicy = () => {
    return localStorage.getItem("privacyAccepted") === "true";
};
