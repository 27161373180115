import { IUserState, TActionUser } from "@/models/store/user";
import { getDefaultUserState, setUserAddress } from "../../utils/common/store";

export const reducerUser = (
    state: IUserState,
    action: TActionUser
): IUserState => {
    switch (action.type) {
        case "SET_USER_DETAIL":
            return { ...state, userDetail: action.payload };
        case "UPDATE_USER_DETAIL":
            return {
                ...state,
                userDetail: { ...state.userDetail, ...action.payload },
            };
        case "SET_NEWSLETTER_EMAIL":
            return { ...state, newsletterEmail: action.payload };
        case "SET_NEWSLETTER_MODAL":
            return { ...state, newsletterModal: action.payload };
        case "RESET_USER":
            return getDefaultUserState();
        case "SET_USER_ADDRESS":
            return setUserAddress(
                action.payload.address,
                state,
                action.payload.addressValue
            );
        case "SET_LAST_PAGE_BEFORE_LOGIN":
            return { ...state, lastPageBeforeLogin: action.payload };
        default:
            return state;
    }
};
