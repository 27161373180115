import { EPaymentMethod, ETourOption } from "@/models/common/enums";
import { TourGAnalytics } from "@/models/tour";
import { TSignUp } from "@/models/user";

export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID ?? "GTM-NFZMKR5D";
export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_META_PIXEL_ID;
// eslint-disable-next-line camelcase
const item_brand = "Play italy";

type TBeginCheckout = {
    currency: string;
    value: number;
    items: {
        item_id: string;
        item_name: string;
        item_brand: string;
        item_category: string;
        item_variant: ETourOption;
        price: string | number;
        quantity: number;
    }[];
};

type TViewItem = {
    currency: string;
    value: string | number;
    items: TourGAnalytics[];
};

export const completeRegistration = (user: TSignUp, countryName: string) => {
    return {
        dataLayer: {
            event: "complete_registration",
            name: user.name,
            surname: user.surname,
            email: user.email,
            country: countryName,
        },
    };
};

export const shareFacebook = (url: string) => {
    return {
        dataLayer: {
            event: "share",
            method: "Facebook",
            content_type: "article",
            item_id: url,
        },
    };
};

export const shareTwitter = (url: string) => {
    return {
        dataLayer: {
            event: "share",
            method: "Twitter",
            content_type: "article",
            item_id: url,
        },
    };
};

export const videoStart = (video: string, videoTitle: string) => {
    return {
        dataLayer: {
            event: "video_start",
            video_url: video,
            // eslint-disable-next-line camelcase
            video_title: videoTitle,
        },
    };
};
export const videoStartReview = (video: string, videoTitle: string) => {
    return {
        dataLayer: {
            event: "video_start_review",
            video_url: video,
            // eslint-disable-next-line camelcase
            video_title: videoTitle,
        },
    };
};

export const videoProgress = (
    video: string,
    videoTitle: string,
    percent: number
) => {
    return {
        dataLayer: {
            event: "video_progress",
            video_url: video,
            // eslint-disable-next-line camelcase
            video_title: videoTitle,
            video_percent: percent,
        },
    };
};
export const videoProgressReview = (
    video: string,
    videoTitle: string,
    percent: number
) => {
    return {
        dataLayer: {
            event: "video_progress50%_review",
            video_url: video,
            // eslint-disable-next-line camelcase
            video_title: videoTitle,
            video_percent: percent,
        },
    };
};

export const videoComplete = (video: string, videoTitle: string) => {
    return {
        dataLayer: {
            event: "video_complete",
            video_url: video,
            // eslint-disable-next-line camelcase
            video_title: videoTitle,
        },
    };
};

export const fileDownload = (fileName: string, pageTitle: string) => {
    return {
        dataLayer: {
            event: "file_download",
            file_name: fileName,
            page_title: pageTitle,
        },
    };
};

export const beginCheckout = (beginCheckoutParams: TBeginCheckout) => {
    return {
        dataLayer: {
            event: "begin_checkout",
            beginCheckoutParams,
        },
    };
};

export const viewItem = (prop: TViewItem) => {
    return {
        dataLayer: {
            event: "view_item",
            prop,
        },
    };
};

export const purchase = (
    transactionId: string,
    amount: string,
    price: string,
    code: string | undefined,
    tourId: string,
    tourTitle: string,
    valueDiscount: number,
    tourType: string,
    option: ETourOption
) => {
    return {
        dataLayer: {
            event: "purchase",
            transaction_id: transactionId,
            value: amount,
            tax: 4.0,
            currency: "EUR",
            coupon: code || "",
            items: [
                {
                    item_id: tourId,
                    item_name: tourTitle,
                    coupon: code || "",
                    discount: code ? valueDiscount : 0,
                    index: 0,
                    // eslint-disable-next-line camelcase
                    item_brand,
                    item_category: tourType,
                    item_variant: option,
                    price,
                    quantity: 1,
                },
            ],
        },
    };
};

export const addPaymentInfo = (
    amount: string,
    price: string,
    code: string | undefined,
    paymentType: EPaymentMethod,
    tourId: string,
    tourTitle: string,
    valueDiscount: number,
    tourType: string,
    option: ETourOption
) => {
    return {
        dataLayer: {
            event: "add_payment_info",
            value: amount,
            currency: "EUR",
            coupon: code || "",
            payment_type: paymentType,
            items: [
                {
                    item_id: tourId,
                    item_name: tourTitle,
                    coupon: code || "",
                    discount: code ? valueDiscount : 0,
                    index: 0,
                    // eslint-disable-next-line camelcase
                    item_brand,
                    item_category: tourType,
                    item_variant: option,
                    price,
                    quantity: 1,
                },
            ],
        },
    };
};

export const laodMore = () => {
    return {
        dataLayer: {
            event: "load_more",
        },
    };
};
