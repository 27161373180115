import {
    Stripe,
    StripeCardElement,
    StripeCardNumberElement,
} from "@stripe/stripe-js";

export const getStripeOptionsForm = () => ({
    style: {
        base: {
            fontFamily: "GeneralSans-Regular, sans-serif",
            fontSize: "16px",
            color: "#777e91",
            "::placeholder": {
                color: "#777e",
            },
        },
        empty: {
            color: "#777e91",
        },
        invalid: {
            color: "#ed6f6f",
        },
    },
    hidePostalCode: true,
    hideIcon: false,
});

export const createPaymentMethodStripe = async (
    stripe: Stripe,
    stripeCardElement: StripeCardElement,
    name: string,
    phone: string,
    email: string
) => {
    const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: stripeCardElement,
        billing_details: {
            name,
            phone,
            email,
        },
    });
    if (error) {
        throw new Error("Payment failed (s1), try again later");
    }
    return { paymentMethodId: paymentMethod?.id, paymentMethod };
};

export const handleCard3DSecureStripe = async (
    stripe: Stripe,
    clientSecret: string
) => {
    const { error } = await stripe.handleCardAction(clientSecret);
    if (error) {
        throw new Error("Payment failed (s2), try again later");
    }
};

export const createTokenStripe = async (
    stripe: Stripe,
    stripeCardElement: StripeCardNumberElement,
    name: string
) => {
    const { token, error } = await stripe.createToken(stripeCardElement, {
        name,
    });
    if (error) {
        throw new Error("Payment failed (s1), try again later");
    }
    return { token };
};
