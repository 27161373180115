import { RoomReadbleTO } from "@/models/detail";
import {
    ReviewReadableTO,
    SectionsTitleText,
} from "../../models/common/common";
import colors from "../../styles/colors.module.scss";

/* eslint-disable */
//mi segnala la necessità di create template per via di strutture di dato ridondanti
//tuttavia al fine dei dati mock irrilevante
export const whyChoosePlayTitle: SectionsTitleText[] = [
    {
        text: "Why choose",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "Play Italy?",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const photoGalleryTitle: SectionsTitleText[] = [
    {
        text: "Photo",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "Gallery",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const photoGallerySubTitle: SectionsTitleText[] = [
    {
        text: "Want a sneak peak? Here are some of our shootings. A magical adventure in Italy awaits you!",
        color: colors.textGrayLight,
        fontWeight: "",
        newLine: false,
    },
];
export const interestedInTitle: SectionsTitleText[] = [
    {
        text: "You might also",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "be interested in...",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const interestedInSubTitle: SectionsTitleText[] = [
    {
        text: "Let’s go on an adventure",
        color: colors.textGrayLight,
        fontWeight: "",
        newLine: false,
    },
];

export const accomodationsTitle: SectionsTitleText[] = [
    {
        text: "Our options of",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "overnight",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: true,
    },
    {
        text: "accommodations",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const accomodationSubTitle: SectionsTitleText[] = [
    {
        text: "We sought out the best Hotel for your staying. Your comfort is always our top priority.",
        color: colors.textGrayLight,
        fontWeight: "",
        newLine: false,
    },
];

const reviewContent: ReviewReadableTO = {
    tourId: "63f78d7f380a7e3c8cbcaef6",
    title: "BA at Robin",
    body: "Stacks help us reached more potential clients that we never think how to reach via the website.",
    rate: "5",
    user: "Isabelle O'Conner",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
};

export const reviewContents = [
    reviewContent,
    reviewContent,
    reviewContent,
    reviewContent,
];

export const defaultRooms: RoomReadbleTO[] = [
    {
        capacity: 1,
        extraPrice: {
            grossPrice: "0.00",
            netPrice: "0.00",
            markup: "0.00",
        },
        grossPrice: "0.00",
        netPrice: "0.00",
        markup: "0.00",
        type: "single",
    },
    {
        capacity: 2,
        extraPrice: {
            grossPrice: "0.00",
            netPrice: "0.00",
            markup: "0.00",
        },
        grossPrice: "0.00",
        netPrice: "0.00",
        markup: "0.00",
        type: "double",
    },
    {
        capacity: 3,
        extraPrice: {
            grossPrice: "0.00",
            netPrice: "0.00",
            markup: "0.00",
        },
        grossPrice: "0.00",
        netPrice: "0.00",
        markup: "0.00",
        type: "triple",
    },
];
