import {
    MDBCol,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBIcon,
} from "mdb-react-ui-kit";
import { FC } from "react";
import style from "../../../styles/navbar/NavBar.module.scss";
import { randomUid } from "../../../utils/randomIds";

const NavSubMenu: FC<{
    className: string;
    callBackTitle: string;
    items: string[];
    onClick: () => void;
}> = ({ className, callBackTitle, items, onClick }) => {
    return (
        <MDBCol xl={6} className={`${className} flex-column py-3`}>
            <MDBNavbarItem className={`${style.overlay} active flex-fill px-5`}>
                <MDBNavbarLink
                    className="d-flex justify-content-between"
                    aria-current="page"
                    onClick={onClick}
                >
                    <p className={`${style.link_text} my-0 text-primary`}>
                        {callBackTitle}
                    </p>
                    <MDBIcon
                        className={`${style.mobile} align-self-center text-primary`}
                        fas
                        icon="chevron-circle-left"
                    />
                </MDBNavbarLink>
            </MDBNavbarItem>
            {items.map(item => (
                <div key={randomUid()} className="d-flex py-2 px-5">
                    <MDBNavbarItem className="active flex-fill">
                        <MDBNavbarLink
                            className="d-flex justify-content-between"
                            aria-current="page"
                            href="#"
                        >
                            <p
                                className={`${style.link_text} my-0 text-primary`}
                            >
                                {item}
                            </p>
                            <MDBIcon
                                className={`${style.mobile} align-self-center text-primary`}
                                fas
                                icon="chevron-circle-right"
                            />
                        </MDBNavbarLink>
                    </MDBNavbarItem>
                </div>
            ))}
        </MDBCol>
    );
};

export default NavSubMenu;
