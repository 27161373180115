import { FC, useState } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import styles from "@/styles/common/Wishlist.module.scss";
import userInterfaceSvg from "@/utils/svg-icon/userInterface";
import contactsSvg from "@/utils/svg-icon/contacts";
import Btn from "@/common/Btn";
import { EBtnType } from "@/models/common/enums";
import Image from "next/image";
import { WishlistElement } from "@/models/common/common";
import { slugify } from "@/utils/searchUtils";
import { useRouter } from "next/router";
import { useRemoveFavourite } from "@/utils/queries/hooks/useRemoveFavourite";
import { useIsMD } from "@/hooks/useWindowSizes";
import MediaControl from "@/utils/svg-icon/mediaControl";
import { useStore } from "@/store/index";
import { checkRating } from "@/utils/common";

/* eslint-disable no-underscore-dangle */
const WishlistCard: FC<{ content: WishlistElement }> = ({ content }) => {
    const router = useRouter();
    const { mutate: removeCard } = useRemoveFavourite();
    const [coverError, setCoverError] = useState<boolean>(false);
    const dispatch = useStore(state => state.dispatchFavourite);
    const closeModal = () => {
        dispatch({
            type: "RESET_FAVOURITE",
        });
    };

    const isMD = useIsMD();
    return (
        <MDBRow className="my-4">
            <MDBCol size={12} md={3} className={`${styles.imageContainer} p-0`}>
                <div
                    className={`${styles.image} position-relative h-100 mx-auto`}
                >
                    <Image
                        alt={`${content.title}-image`}
                        src={
                            coverError
                                ? "/images/tours/across-italy-reduced.jpg"
                                : content.image
                        }
                        className={styles.image}
                        fill
                        onError={() => setCoverError(true)}
                    />
                </div>
            </MDBCol>
            <MDBCol size={12} md={5} lg={6} className="my-4 my-md-0">
                <div className="px-0 px-md-3 d-flex flex-column">
                    <h3 className={styles.cardTitle}>{content.title}</h3>
                    <div className="d-flex align-items-center">
                        {checkRating(content?.rating) && (
                            <>
                                <span className={styles.cardStarIcon}>
                                    <userInterfaceSvg.StarFilled />
                                </span>
                                <span className={styles.starText}>
                                    {Number(content.rating)}
                                </span>
                            </>
                        )}
                        {+(content?.reviews ?? "0") > 0 && (
                            <span
                                className={`${styles.cardSecondaryText} ps-2`}
                            >
                                ({content.reviews} reviews)
                            </span>
                        )}
                    </div>
                    <div>
                        <span className={styles.cardClockIcon}>
                            <contactsSvg.ClockLine />
                        </span>
                        <span className={styles.cardSecondaryText}>
                            {content.duration} days {content.nightDuration}{" "}
                            nights
                        </span>
                    </div>
                    <div>
                        <span className={styles.flagContainer}>
                            <contactsSvg.FlagLine />
                        </span>
                        <span className={styles.cardSecondaryText}>
                            {content.destinations.length} Destinations
                        </span>
                    </div>
                    <div>
                        <span className={styles.textPrice}>
                            From {content.price} €
                        </span>
                    </div>
                </div>
            </MDBCol>
            <MDBCol
                size={12}
                md={3}
                className="d-flex flex-column justify-content-between"
            >
                <Btn
                    className="fw-bold"
                    fullWith={isMD}
                    btnType={EBtnType.TRANSPARENT}
                    onClick={() =>
                        router
                            .push({
                                pathname: "/detail/[slug]",
                                query: {
                                    slug: slugify(content.title ?? "Title"),
                                },
                            })
                            .finally(closeModal)
                    }
                >
                    Check the product page
                </Btn>
                <Btn
                    className="border-0 fw-bold"
                    btnType={EBtnType.TRANSPARENTNOHOVER}
                    onClick={() => removeCard(content._id)}
                    fullWith={isMD}
                >
                    <MediaControl.CloseCircleLine /> Remove
                </Btn>
            </MDBCol>
        </MDBRow>
    );
};
/* eslint-enable no-underscore-dangle */

export default WishlistCard;
