import Play from "../../public/custom-icons/icons-2/icons/Play/Filled.svg";
import PlayLine from "../../public/custom-icons/icons-2/icons/Play/Line.svg";
import DownloadLine from "../../public/custom-icons/icons-2/icons/Download/Line.svg";
import CloseCircleFilled from "../../public/custom-icons/icons-2/icons/Close Circle/Filled.svg";
import CloseCircleLine from "../../public/custom-icons/icons-2/icons/Close Circle/Line.svg";

const mediaControlSvg = {
    Play,
    PlayLine,
    DownloadLine,
    CloseCircleFilled,
    CloseCircleLine,
};

export default mediaControlSvg;
