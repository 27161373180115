import { TError, TServerError } from "@/models/common/common";
import { countries } from "../mockData/countries";

export const copyToClipboard = async (link: string) => {
    try {
        await navigator?.clipboard?.writeText(link);
    } catch (error) {
        console.error("Failed to copy link to clipboard");
    }
};

export const generateArray = (length: number) => {
    return new Array(length).fill(1).map((_, index) => index + 1);
};

type TCheckUndefineNull = <T = number>(value: T) => boolean;

export const checkUndefineNull: TCheckUndefineNull = value =>
    value !== undefined && value !== null;

export const arrayRange = (
    start: number,
    stop?: number,
    size?: number,
    step = 1
) => {
    const checkStart = checkUndefineNull(start);

    if (checkStart && checkUndefineNull(stop)) {
        return Array.from(
            { length: (stop! - start) / step + 1 },
            (value, index) => start + index * step
        );
    }
    if (checkStart && checkUndefineNull(size)) {
        const stopBySize = start + size! - 1;
        return Array.from(
            { length: (stopBySize - start) / step + 1 },
            (value, index) => start + index * step
        );
    }
    return [];
};

export const validateNumber = (val: string | number | undefined) =>
    /^\d+$/.test(val as string);

export const isValidPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) return false;

    const allNumbersPart = phoneNumber.slice(1);
    return (
        phoneNumber.startsWith("+") &&
        !(phoneNumber.indexOf(" ") >= 0) &&
        validateNumber(allNumbersPart)
    );
};

export const checkPrivacyTerms = (
    pathName: string | undefined | string[]
): "privacy" | "terms" | null =>
    pathName === "terms" || pathName === "privacy" ? pathName : null;

export const getErrorMessage = (err: TServerError | TError): string => {
    if (!err) return "Something went wrong";

    if ((err as TServerError)?.response?.data?.message) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (err as any)?.response.data.message;
    }
    if (err?.message) return err?.message;

    return "Something went wrong";
};

export const isNumeric = (value: string) => {
    return /^-?\d+$/.test(value);
};

export const removeEmptyValues = <T = object>(obj: T): T => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Object.entries(obj as object).reduce((acc: any, [key, value]) => {
        if (value !== null && value !== undefined && value !== "") {
            acc[key] = value;
        }
        return acc;
    }, {});
};

export const isValidWithoutSpace = (valueString: string | undefined) => {
    if (!valueString) return false;
    return !/\s/g.test(valueString);
};

export const chekStartOrEndWithSpace = (name: string | undefined) => {
    if (!name) return false;
    const regexStart = /^\s/;
    const regexEnd = /\s$/;
    const boolStart = !regexStart.test(name);
    const boolEnd = !regexEnd.test(name);
    return boolStart && boolEnd;
};

export const isValidEmail = (email: string | undefined) => {
    // eslint-disable-next-line security/detect-unsafe-regex
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,}$/;
    return emailRegex.test(email || "");
};

export const findCountryNameById = (id: string): string =>
    countries.find(country => country.id === id)?.it || "";

export const checkRating = (rate: number | string | undefined): boolean =>
    (typeof rate === "number" || typeof rate === "string") && +rate > 0;

export const getDestinationsSize = (
    destinations: string[] | number | undefined | null
): number => {
    if (!destinations) return 0;
    if (typeof destinations === "number") return destinations;
    return destinations.length;
};

export const transformPrice = (n: number) => {
    const decimals = 0;
    if (typeof n === "undefined" || n == null) return "-"; // nessun numero passato
    if (typeof n !== "number") return NaN;
    let sn = n.toFixed(decimals);
    const s = sn;
    let sd = "";
    const p = s.indexOf(".");
    if (p !== -1) {
        sn = s.substr(0, p);
        sd = s.substr(p + 1);
    }
    // separatore delle migliaia
    let m = sn.length % 3;
    let n1 = sn.substring(0, m);
    while (m < sn.length) {
        n1 = (m === 0 ? "" : `${n1}.`) + sn.substring(m, m + 3);
        m += 3;
    }
    // return sd !== "" ? n1 + "," + sd : n1;
    return (sd !== "" ? `${n1},${sd}` : n1).replace("-.", "-");
};
