import { MDBCol } from "mdb-react-ui-kit";
import NewsletterForm from "@/components/common/NewsLetterForm";
import styles from "@/styles/Footer.module.scss";

const FooterCta = () => {
    return (
        <MDBCol
            lg={9}
            xl="3"
            className={`${styles.footerCta} mx-auto mb-md-0 mb-4 px-4`}
        >
            <p
                className={`${styles.footerCtaTitle} text-start text-md-center fw-bold mb-4 fs-5`}
                id="footer-community"
            >
                Join our community &#128293;
            </p>
            <NewsletterForm isSmall />
        </MDBCol>
    );
};

export default FooterCta;
