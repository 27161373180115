import { useStore } from "@/store/index";
import colors from "@/styles/colors.module.scss";
import Btn from "./Btn";
import CustomModal from "./CustomModal";

const NewsletterModal = () => {
    const displayModal = useStore(state => state.userState.newsletterModal);
    const newsletterEmail = useStore(state => state.userState.newsletterEmail);
    const dispatch = useStore(state => state.dispatchUser);
    const handleNewsletter = useStore(state => state.handleNewsletter);

    return displayModal ? (
        <CustomModal
            displayModal={displayModal}
            setDisplayModal={() =>
                dispatch({ type: "SET_NEWSLETTER_MODAL", payload: false })
            }
            sectionTitle={{
                title: [
                    {
                        text: "You are already subscribed to our newsletter",
                        color: colors.textPrimary,
                        fontWeight: "",
                        newLine: false,
                    },
                ],
                subTitle: [
                    {
                        text: "You can unsubscribe your email address ",
                        color: colors.textGray,
                        fontWeight: "",
                        newLine: false,
                    },
                    {
                        text: newsletterEmail,
                        color: colors.textGray,
                        fontWeight: "fw-bold",
                        newLine: false,
                    },
                    {
                        text: "from our newsletter",
                        color: colors.textGray,
                        fontWeight: "",
                        newLine: false,
                    },
                ],
            }}
            classNameBody="d-flex justify-content-center align-items-center"
        >
            <Btn
                onClick={() => handleNewsletter(newsletterEmail, "unsubscribe")}
            >
                Unsubscribe
            </Btn>
        </CustomModal>
    ) : null;
};

export default NewsletterModal;
