import {
    FilterResponseTO,
    FiltersOptions,
    SearchCard,
    SearchCardActivitiesSvg,
    SearchCardWithTags,
    SearchFilters,
} from "../models/search";

export const addTagsPackage = (packageData: SearchCard[]) => {
    return packageData.map(el => {
        const tagsArray: string[] = [
            ...el.quality.activities,
            ...el.quality.destinations,
            ...el.premium.activities,
            ...el.premium.destinations,
            el.type,
        ];
        const uniqueTags = new Set(tagsArray);
        const packageWithTags: SearchCardWithTags = {
            ...el,
            tags: Array.from(uniqueTags),
        };

        return packageWithTags;
    });
};

export const packageSorting = (
    sortingMethod: string,
    filteredPackage: SearchCardWithTags[],
    setFilteredPackage: (arg: SearchCardWithTags[]) => void
) => {
    switch (sortingMethod) {
        case "Ascending": {
            const sortedAscendingArray: SearchCardWithTags[] = [
                ...filteredPackage,
            ].sort((a, b) => a.quality.price - b.quality.price);
            setFilteredPackage(sortedAscendingArray);
            break;
        }
        case "Descending": {
            const sortedDiscendingArray = [...filteredPackage].sort(
                (a, b) => b.quality.price - a.quality.price
            );
            setFilteredPackage(sortedDiscendingArray);
            break;
        }
        default: {
            const sortedRaccomendedArray = [...filteredPackage].sort(
                (a, b) => b.rating - a.rating
            );
            setFilteredPackage(sortedRaccomendedArray);
            break;
        }
    }
};

export const packageFilterAndSort = (
    activeFilters: SearchFilters,
    packageData: SearchCardWithTags[],
    setFilteredPackage: (arg: SearchCardWithTags[]) => void,
    filtersData: FiltersOptions,
    setPremiumGlobal: (arg: boolean) => void,
    sortingMethod: string
) => {
    const filtersArray = [
        ...activeFilters.activities,
        ...activeFilters.destinations,
        ...activeFilters.tours,
    ];

    let newFilteredPackage: SearchCardWithTags[] = packageData;

    if (filtersArray.length > 0) {
        newFilteredPackage = newFilteredPackage.filter(el =>
            el.tags.some(item => filtersArray.includes(item))
        );
    }

    if (activeFilters.price > filtersData.priceRange.min) {
        newFilteredPackage = newFilteredPackage.filter(
            el => el.quality.price < activeFilters.price
        );
    }

    if (activeFilters.level === "Premium") {
        setPremiumGlobal(true);
    } else {
        setPremiumGlobal(false);
    }

    packageSorting(sortingMethod, newFilteredPackage, setFilteredPackage);
};

export const resetFilters = (
    setActiveFilters: (arg: SearchFilters) => void
) => {
    setActiveFilters({
        tours: [],
        destinations: [],
        activities: [],
        price: 0,
        level: "Quality",
    });
};

export const isFilterActive = (
    activeFilters: SearchFilters,
    filtersData: FilterResponseTO
) => {
    return (
        activeFilters.tours.length > 0 ||
        activeFilters.destinations.length > 0 ||
        activeFilters.activities.length > 0 ||
        activeFilters.price > +filtersData.minPrice ||
        activeFilters.level === "Premium"
    );
};

/* eslint-disable */
export const filterHandler = (
    key: string,
    value: string | number,
    setActiveFilters: (arg: SearchFilters) => void,
    activeFilters: SearchFilters
) => {
    if (key === "price" || key === "level") {
        setActiveFilters({ ...activeFilters, [key]: value });
    } else {
        if ((activeFilters as any)[key].includes(value)) {
            setActiveFilters({
                ...activeFilters,
                [key]: [
                    ...(activeFilters as any)[key].filter(
                        (el: string) => el !== value
                    ),
                ],
            });
        } else {
            setActiveFilters({
                ...activeFilters,
                [key]: [...(activeFilters as any)[key], value],
            });
        }
    }
};
/* eslint-enable */

export const slugify = (str: string) =>
    str
        .toLowerCase()
        .trim()
        .replace(/[^a-zA-Z0-9 ]/g, "")
        .replace(/[^a-zA-Z0-9]/g, "-");

export const activitiesSvgHandler = (
    tags: string[],
    activityIcons: SearchCardActivitiesSvg[]
) => {
    const arrayOfActivity: SearchCardActivitiesSvg[] = [];

    activityIcons.map(el => {
        if (tags?.some(item => item === el.name)) {
            arrayOfActivity.push(el);
        }
        return null;
    });
    return arrayOfActivity;
};
