import style from "../../../../styles/navbar/NavBarM.module.scss";
import userInterfaceSvg from "../../../../utils/svg-icon/userInterface";

const NavBarMBackIcon = () => {
    const { ArrowLeftSimpleLine } = userInterfaceSvg;

    return (
        <div
            className={`${style.navBarMBackIcon} d-flex text-center align-items-center`}
        >
            <span className="fw-light">Go Back </span>
            <div className="ms-2 text-center">
                <ArrowLeftSimpleLine />
            </div>
        </div>
    );
};

export default NavBarMBackIcon;
