import { TActions, TStore } from "@/models/store";
import { IUserStore } from "@/models/store/user";
import { getDefaultUserState } from "@/utils/common/store";
import { StateCreator } from "zustand";
import { reducerUser } from "./reducer-user";
import {
    loadAddCreditCard,
    loadDeleteUser,
    loadHandleNewsletter,
    loadHandleNewsletterCustom,
    loadUpdateUserDetail,
    loadUploadUserAvatar,
    loadUserDetail,
} from "./saga-user";

export const createUserSlice: StateCreator<TStore, [], [], IUserStore> = (
    set,
    get
) => ({
    userState: { ...getDefaultUserState() },
    dispatchUser: action =>
        set(state => ({
            ...state,
            action: action as TActions,
            userState: reducerUser(state.userState, action),
        })),
    addCreditCard: crediCardData => loadAddCreditCard(crediCardData, get),
    setUserDetail: () => loadUserDetail(get),
    updateUserDetail: () => loadUpdateUserDetail(get),
    deleteUser: () => loadDeleteUser(get),
    handleNewsletter: (email, type) => loadHandleNewsletter(email, get, type),
    handleNewsletterCustom: (
        email: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        emailCheck: boolean,
        phoneNumberCheck: boolean,
        type
    ) =>
        loadHandleNewsletterCustom(
            email,
            firstName,
            lastName,
            phoneNumber,
            emailCheck,
            phoneNumberCheck,
            get,
            type
        ),
    uploadUserAvatar: (file, onSuccessCb) =>
        loadUploadUserAvatar(file, get, onSuccessCb),
});
