import { useMutation, useQueryClient } from "@tanstack/react-query";
import { WishlistResponseTo } from "@/models/common/common";
import { putFavourite } from "@/utils/queries";
import { useStore } from "@/store/index";
import { ETypeToastMessage } from "@/models/common/enums";

export const useRemoveFavourite = () => {
    const queryClient = useQueryClient();
    const dispatchUser = useStore(state => state.dispatchUser);
    const dispatchCommon = useStore(state => state.dispatchCommon);

    const userDetail = useStore(state => state.userState.userDetail);

    return useMutation({
        mutationFn: putFavourite,
        // Notice the second argument is the variables object that the `mutate` function receives
        onSuccess: ({ data }) => {
            queryClient.setQueryData(
                ["wishlist"],
                (oldData: WishlistResponseTo | undefined) =>
                    oldData
                        ? oldData.filter(element =>
                              // eslint-disable-next-line no-underscore-dangle
                              data.favouriteTours.includes(element._id)
                          )
                        : oldData
            );
            dispatchUser({
                type: "SET_USER_DETAIL",
                payload: { ...userDetail, favouriteTours: data.favouriteTours },
            });
            dispatchCommon({
                type: "SET_TOAST_MESSAGE_DATA",
                payload: {
                    type: ETypeToastMessage.SUCCESS,
                    message: "Tour has been removed from your Wishlist",
                },
            });
        },
    });
};
