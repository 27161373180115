import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import Link from "next/link";
import { useRouter } from "next/router";
import useNavBarData from "@/utils/queries/hooks/useNavBarData";
import {
    footerSectionsOne,
    footerSectionsTwo,
} from "@/utils/mockData/homeMocks";
import { useMemo } from "react";
import axios from "axios";
import style from "../../../styles/Footer.module.scss";

const FooterLinks = () => {
    const router = useRouter();
    const { data: dropDownData } = useNavBarData();

    const destinationList = useMemo(
        () => dropDownData?.toursByDestination.slice(0, 5),
        [dropDownData]
    );

    const path = "/tours-destinations";

    const handleRouting = (tour: string | null, destination: string | null) => {
        const url = {
            pathname: path,
            query: {
                tourType: tour,
                destination,
            },
        };
        if (router.pathname !== path) {
            router.push(url);
        } else {
            router.replace(url).then(() => router.reload());
        }
    };

    const downloadFileAtURL = (url: string | undefined, fileName: string) => {
        if (url) {
            axios
                .get(url, { responseType: "arraybuffer" })
                .then(blob => {
                    const newBlob = new Blob([blob.data], {
                        type: "application/pdf",
                    });
                    const blobURL = window.URL.createObjectURL(newBlob);
                    const aTag = document.createElement("a");
                    aTag.href = blobURL;
                    aTag.setAttribute("download", fileName);
                    document.body.appendChild(aTag);
                    aTag.click();
                    aTag.remove();
                })
                .catch(error => console.log(error));
        }
    };

    return (
        <>
            <MDBCol lg="3" xl="3" className={`mb-4 ${style.desktop}`}>
                <MDBRow>
                    {footerSectionsOne.map(section => (
                        <MDBCol className="mx-auto mb-4" key={section.id}>
                            <h6
                                className={`${style.text_title} text-start fw-bold mb-4`}
                            >
                                {section.title}
                            </h6>
                            {section.links.map(link => {
                                return link.title === "Terms & Conditions" ? (
                                    <p
                                        className={`${style.text_content} text-start`}
                                        key={link.id}
                                        onClick={() =>
                                            downloadFileAtURL(
                                                "https://d3t4iiwihaed0b.cloudfront.net/terms-condition/Terms_Conditions.pdf",
                                                "Terms & Conditions"
                                            )
                                        }
                                    >
                                        {link.title}
                                    </p>
                                ) : (
                                    <p
                                        className={`${style.text_content} text-start`}
                                        key={link.id}
                                    >
                                        <Link
                                            href={link.href}
                                            className="text-reset"
                                        >
                                            {link.title}
                                        </Link>
                                    </p>
                                );
                            })}
                        </MDBCol>
                    ))}
                </MDBRow>
            </MDBCol>
            <MDBCol lg="3" xl="3" className={`mx-auto mb-4 ${style.desktop}`}>
                <MDBRow>
                    {footerSectionsTwo.map(section => (
                        <MDBCol className="mx-auto mb-4" key={section.id}>
                            <h6
                                className={`${style.text_title} text-start fw-bold mb-4`}
                            >
                                {section.title}
                            </h6>
                            {section.id === "Tours" &&
                                dropDownData?.toursHome.map(link => (
                                    <p
                                        className={`${style.text_content} text-start`}
                                        key={link.tourType}
                                    >
                                        <span
                                            onClick={() =>
                                                handleRouting(link.type, null)
                                            }
                                            className="text-reset"
                                        >
                                            {link.tourType}
                                        </span>
                                    </p>
                                ))}
                            {section.id === "Destinations" &&
                                destinationList?.map(link => (
                                    <p
                                        className={`${style.text_content} text-start`}
                                        key={link.city}
                                    >
                                        <span
                                            onClick={() =>
                                                handleRouting(null, link.city)
                                            }
                                            className="text-reset"
                                        >
                                            {link.city}
                                        </span>
                                    </p>
                                ))}
                        </MDBCol>
                    ))}
                </MDBRow>
            </MDBCol>
        </>
    );
};
export default FooterLinks;
