import { MDBBtn } from "mdb-react-ui-kit";
import SwiperClass from "swiper";
import { FC, useEffect, useState } from "react";
import arrowsSvg from "../../../utils/svg-icon/arrows";
import styles from "../../../styles/common/carousel/Buttons.module.scss";

const ButtonBack: FC<{
    className?: string;
    swiperClass: SwiperClass | null;
}> = ({ className = "", swiperClass }) => {
    const { ArrowLeft } = arrowsSvg;
    const [active, setActive] = useState(false);
    const isActive = () => swiperClass && !swiperClass?.isBeginning;

    swiperClass?.on("slideChange", () => setActive(!!isActive()));

    const onBack = () => {
        if (isActive()) swiperClass?.slidePrev();
    };

    useEffect(() => {
        if (swiperClass) {
            setActive(!!isActive());
            swiperClass?.on("slideChange", () => setActive(!!isActive()));
        }

        return () => {
            if (swiperClass) swiperClass?.off("slideChange");
        };
    }, [swiperClass]);

    return (
        <MDBBtn
            className={`${
                active ? styles.buttonActive : styles.buttonInactive
            } ${className} d-flex justify-content-center shadow-0`}
            tag="a"
            floating
            onClick={onBack}
        >
            <ArrowLeft className="align-self-center" />
        </MDBBtn>
    );
};

ButtonBack.defaultProps = {
    className: "",
};

export default ButtonBack;
