import { useState } from "react";
import { useIsClient } from "@/utils/hooks/useIsClient";
import {
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
} from "mdb-react-ui-kit";
import styles from "@/styles/auth/Auth.module.scss";
import userInterfaceSvg from "@/utils/svg-icon/userInterface";
import { useStore } from "@/store/index";
import { EModalAuthType } from "@/models/common/enums";
import {
    subTitleChangePassword,
    subTitleForgotPassword,
    subTitleLogin,
    subTitlePreLogin,
    subTitleSignup,
    titleChangePassword,
    titleDeteleteUser,
    titleForgotPassword,
    titleLogin,
    titlePrelogin,
    titleSignup,
} from "@/utils/mockData/auth";
import shoppingSvg from "@/utils/svg-icon/shopping";
import { useRouter } from "next/router";
import { URLS } from "@/utils/constants";
import { useDisableBodyScroll } from "@/utils/hooks/useDisableBodyScroll";
import SectionTitle from "../common/SectionTitle";
import AuthModalBody from "./AuthModalBody";
import LoggedModalBody from "./LoggedModalBody";
import ModalConfirmLogout from "./logout/ModalConfirmLogout";

const getTitle = (authType: EModalAuthType) => {
    switch (authType) {
        case EModalAuthType.LOGIN:
            return {
                title: titleLogin,
                subTitle: subTitleLogin,
            };
        case EModalAuthType.SIGNUP:
            return {
                title: titleSignup,
                subTitle: subTitleSignup,
            };

        case EModalAuthType.FORGOT_PASSWORD:
            return {
                title: titleForgotPassword,
                subTitle: subTitleForgotPassword,
            };
        case EModalAuthType.CHANGE_PASSWORD:
            return {
                title: titleChangePassword,
                subTitle: subTitleChangePassword,
            };
        case EModalAuthType.DELETE_USER:
            return {
                title: titleDeteleteUser,
            };
        default:
            return {
                title: titlePrelogin,
                subTitle: subTitlePreLogin,
            };
    }
};

const ModalAuth = () => {
    const authType = useStore(state => state.authState.modalAuthType);
    const dispatch = useStore(state => state.dispatchAuth);
    const router = useRouter();
    const [staticBackdrop, setStaticBackdrop] = useState(false);
    const [showModalLogout, setShowModalLogout] = useState(false);
    const isNone = authType === EModalAuthType.NONE;
    const isLogged = authType === EModalAuthType.LOGGED;
    const isSignupOpen = authType === EModalAuthType.SIGNUP;
    const isClient = useIsClient();
    const { CloseLine, StarLine } = userInterfaceSvg;
    const { ShoppingTagLine } = shoppingSvg;

    const closeModal = () => {
        if (showModalLogout) {
            setShowModalLogout(prev => !prev);
        }
        dispatch({
            type: "RESET_AUTH",
        });
        setStaticBackdrop(false);
    };

    const buttonLoggedList = [
        {
            title: "Your Booking",
            icon: <ShoppingTagLine />,
            onClick: () => {
                closeModal();
                router.push(URLS.booking);
            },
        },
        {
            title: "Your Account",
            icon: <StarLine />,
            onClick: () => {
                closeModal();
                router.push(URLS.userProfile);
            },
        },
    ];

    const renderCloseBtn = !isLogged && (
        <span
            className={`${styles.modalBtnClose} text-center pointer`}
            onClick={closeModal}
        >
            <CloseLine />
        </span>
    );

    useDisableBodyScroll(isSignupOpen);

    if (!isClient || isNone) return <div />;

    return !showModalLogout ? (
        <MDBModal
            show={!isNone}
            setShow={(e: boolean) => !e && closeModal()}
            tabIndex="-1"
            staticBackdrop={staticBackdrop}
        >
            <MDBModalDialog
                className={`${styles.modal} ${
                    isLogged ? styles.modalLogged : ""
                }
                mt-3 mt-md-4
                `}
                size="lg"
                shouldCloseOnOverlayClick={false}
            >
                <MDBModalContent>
                    <div
                        className={`text-center w-100 border-0 px-2 px-lg-5 ${
                            isLogged ? "mt-0" : "mt-5"
                        }`}
                    >
                        {renderCloseBtn}
                        {!isLogged && (
                            <SectionTitle
                                sectionTitle={{
                                    ...getTitle(authType),
                                    classNameTitle: `text-center ${styles.title}`,
                                    classNameSubTitle: `text-center ${styles.subtitle}`,
                                }}
                            />
                        )}
                    </div>
                    <MDBModalBody className="my-3">
                        {isLogged ? (
                            <LoggedModalBody
                                buttonLoggedList={buttonLoggedList}
                                onClick={() =>
                                    setShowModalLogout(prev => !prev)
                                }
                            />
                        ) : (
                            <AuthModalBody
                                authType={authType}
                                setStaticBackdrop={val =>
                                    setStaticBackdrop(val)
                                }
                            />
                        )}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    ) : (
        <ModalConfirmLogout
            show={showModalLogout}
            setShow={() => setShowModalLogout(false)}
        />
    );
};

export default ModalAuth;
