import { EBtnType } from "@/models/common/enums";
import Btn from "../../common/Btn";

const ConfirmLogout = ({ onClick }: { onClick: () => void }) => (
    <div className="d-flex align-items-center justify-content-around">
        <span className="me-2">Are you sure want to log out?</span>
        <Btn btnType={EBtnType.PRIMARY} onClick={onClick}>
            Logout
        </Btn>
    </div>
);

export default ConfirmLogout;
