import "core-js";
import { useEffect, useState } from "react";
import type { AppProps } from "next/app";
import {
    Hydrate,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";
import { Amplify, Hub, Storage } from "aws-amplify";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import Script from "next/script";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ModalAuth from "@/components/auth/ModalAuth";
import "../styles/globals.scss";
import "react-datepicker/dist/react-datepicker.css";
import Wishlist from "@/common/wishlist/Wishlist";
import { useRouter } from "next/router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useStore } from "@/store/index";
import { AWS_BUCKET_NAME, AWS_REGION, URLS } from "@/utils/constants";
import Logger from "@/utils/Logger";
import { zenSettings } from "@/utils/hooks/useZenDesk";
import { ERemeberMe, ETypeToastMessage } from "@/models/common/enums";
import ErrorBoundaryApp from "@/components/error/ErrorBoundaryApp";
import NewsletterModal from "@/components/common/NewsletterModal";
import "../styles/page_loader.scss";
// eslint-disable-next-line import/no-extraneous-dependencies
import TagManager from "react-gtm-module";
import { FB_PIXEL_ID, GTM_ID } from "@/utils/analytics/tagmanager";
import LoaderPage from "../components/common/LoaderPage";
import ToastMessage from "../components/common/ToastMessage";
import awsconfig from "../src/aws-exports";
// eslint-disable-next-line import/no-extraneous-dependencies
import "@fontsource/poppins/500.css";
// eslint-disable-next-line import/no-extraneous-dependencies, import/order
import { ToastContainer, toast } from "react-toastify";
// eslint-disable-next-line import/no-extraneous-dependencies
import "react-toastify/dist/ReactToastify.css";
import style from "../styles/common/ToastMessage.module.scss";
import { getTypeMsgSvgIcon } from "../utils/common/toastMessage";

Storage.configure({
    AWSS3: {
        bucket: AWS_BUCKET_NAME,
        region: AWS_REGION,
    },
});

try {
    Logger.getInstance();
    Logger.instance.record("instanced");
} catch (error) {
    // ignore error Cloud Watch on instance RUM client web
}

Hub.listen("auth", ({ payload: { event } }) => {
    if (event === "customState_failure") {
        toast("This email already exists", {
            className: style.messageError,
            type: "error",
            closeButton: false,
            bodyStyle: {
                color: "#ed6f6f",
                fontFamily: "GeneralSans-SemiBold",
                backgroundColor: "#f5f5f5",
                top: "0",
                left: "0",
                position: "absolute",
                textAlign: "center",
                border: "0.5px solid #e6e8ec",
                borderRadius: "8px",
                boxShadow: "0px 3px 12px #e6e8ec",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                alignContent: "center",
            },

            hideProgressBar: true,

            icon: getTypeMsgSvgIcon(ETypeToastMessage.ERROR),
        });
    }
});

const App = ({ Component, pageProps }: AppProps) => {
    const router = useRouter();
    const initStore = useStore(state => state.initStore);
    const dispatchUser = useStore(state => state.dispatchUser);
    const rememberMe = useStore(state => state.authState.rememberMe);

    const [queryClient] = useState(() => new QueryClient());
    const [isLoading, setIsLoading] = useState(false);

    // useZenDesk(false);
    const showReactQueryDevtools =
        process.env.NEXT_PUBLIC_NODE_ENV === "develop" ||
        process.env.NEXT_PUBLIC_NODE_ENV === "local";

    useEffect(() => {
        const handleStart = (url: string) =>
            (url === "/" || url !== router.asPath) && setIsLoading(true);

        const handleComplete = (url: string) => {
            // console.log("url => ", url);
            // console.log("asPath => ", router.asPath);
            // eslint-disable-next-line no-unused-expressions
            url === router.asPath &&
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
        };

        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleComplete);
        router.events.on("routeChangeError", handleComplete);

        return () => {
            router.events.off("routeChangeStart", handleStart);
            router.events.off("routeChangeComplete", handleComplete);
            router.events.off("routeChangeError", handleComplete);
        };
    });

    useEffect(() => {
        initStore();
    }, [initStore]);

    useEffect(() => {
        const isRememberMe =
            localStorage.getItem("rememberMe") === ERemeberMe.REMEMBER;
        awsconfig.oauth.domain = "auth.playitaly.com";
        Amplify.configure({
            ...awsconfig,
            storage: isRememberMe ? localStorage : sessionStorage,
        });
    }, [rememberMe]);

    const tagManagerArgs = {
        gtmId: GTM_ID,
    };

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
        Hub.listen("auth", ({ payload: { event } }) => {
            if (event === "cognitoHostedUI") {
                router.push(URLS.userProfile);
            }
            if (event === "signOut") {
                dispatchUser({ type: "RESET_USER" });
                router.push(URLS.home);
            }
        });
    }, []);

    useEffect(() => {
        const path = router.asPath;
        if (!path.toString().includes("checkout")) {
            setTimeout(() => {
                TagManager.dataLayer({
                    dataLayer: {
                        event: "time_on_page",
                    },
                });
            }, parseInt(process.env.NEXT_PUBLIC_TIMER_ON_PAGE ?? "10000", 10));
        }
    }, [router.asPath]);

    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundaryApp>
                <Zendesk
                    defer
                    zendeskKey={process.env.NEXT_PUBLIC_ZEN_DESK_KEY as string}
                    {...zenSettings}
                    onLoaded={() => {
                        ZendeskAPI("messenger:set", "zIndex", 1020);
                    }}
                />
                <Hydrate state={pageProps.dehydratedState}>
                    <LoaderPage loading={isLoading} />
                    <ToastMessage />
                    <ToastContainer
                        position="top-center"
                        hideProgressBar={false}
                    />
                    <ModalAuth />
                    <NewsletterModal />
                    <Wishlist />

                    <Script
                        id="fb-pixel"
                        dangerouslySetInnerHTML={{
                            __html: `
                            !function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', ${FB_PIXEL_ID});
                            `,
                        }}
                    />
                    <Component {...pageProps} />
                </Hydrate>
                {showReactQueryDevtools && (
                    <ReactQueryDevtools initialIsOpen={false} />
                )}
            </ErrorBoundaryApp>
        </QueryClientProvider>
    );
};

export default App;
