import { MDBCol } from "mdb-react-ui-kit";
import useContactsData from "@/utils/queries/hooks/useContactsData";
import Link from "next/link";
import { URLS } from "@/utils/constants";
import style from "../../../styles/Footer.module.scss";

const FooterLogo = () => {
    const { data: contactsData } = useContactsData();

    return (
        <MDBCol md="12" lg="3" xl="3" className="mb-3 mb-sm-4">
            <h6 className="d-flex justify-content-start fw-bold mb-4 px-4 px-lg-0">
                <Link href={URLS.home}>
                    <img src="/logofoot.svg" alt="Logo" />
                </Link>
            </h6>
            <h6
                className={`${style.text_title} text-start fw-bold mb-2 mb-md-4 mb-lg-4 px-4 px-lg-0`}
            >
                Address
            </h6>
            <p
                className={`${style.text_content} text-start px-4 px-lg-0`}
                id="footer-address"
            >
                {`${contactsData?.address || ""} n. ${
                    contactsData?.streetNumber || ""
                }, ${contactsData?.cap || ""},`}
                <br className={style.desktop} />
                {`${contactsData?.city || ""} (${contactsData?.countryCode})`}
                <br />
                {`P.IVA ${contactsData?.piva || ""}`}
            </p>
        </MDBCol>
    );
};

export default FooterLogo;
