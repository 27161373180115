import {
    MDBBtn,
    MDBCol,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalHeader,
    MDBModalTitle,
    MDBRow,
} from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import styles from "@/styles/common/Wishlist.module.scss";
import WishlistCard from "@/common/wishlist/WishlistCard";
import { useStore } from "@/store/index";
import { useIsMD } from "@/hooks/useWindowSizes";
import useWishlistData from "@/utils/queries/hooks/useWishlistData";
import { BounceLoader } from "react-spinners";

const Wishlist = () => {
    const isOpen = useStore(state => state.favouriteState.isOpen);
    const dispatch = useStore(state => state.dispatchFavourite);

    const { data: wishlist, isFetched } = useWishlistData();
    const [isBrowser, setIsBrowser] = useState(false);
    useEffect(() => {
        setIsBrowser(typeof window !== "undefined");
    }, []);

    const closeModal = () => {
        dispatch({
            type: "RESET_FAVOURITE",
        });
    };

    const isMD = useIsMD();

    return isBrowser ? (
        <MDBModal
            show={isOpen}
            setShow={(e: boolean) => !e && closeModal()}
            tabIndex="-1"
        >
            <MDBModalDialog centered size={isMD ? "fullscreen" : "xl"}>
                <MDBModalContent
                    style={{ borderRadius: isMD ? "0px" : "24px" }}
                >
                    <MDBModalHeader className="position-relative border border-0 d-flex justify-content-center px-md-5 py-4 mx-4">
                        <MDBModalTitle
                            className={`${styles.modalTitle} fw-bold`}
                        >
                            Your Wishlist ❤️
                        </MDBModalTitle>
                        <MDBBtn
                            className={`${styles.closeButton} btn-close`}
                            color="none"
                            onClick={closeModal}
                        />
                    </MDBModalHeader>
                    <MDBModalBody
                        className={`${styles.contentBody} px-md-5 mx-4 overflow-auto`}
                        style={{ maxHeight: "75vh" }}
                    >
                        {!isFetched && (
                            <MDBRow className="w-100 justify-content-center my-5">
                                <BounceLoader color="#ed6f6f" size={150} />
                            </MDBRow>
                        )}
                        {isFetched &&
                            wishlist?.map((element, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={element.title + index}>
                                    <WishlistCard content={element} />
                                    {index + 1 < wishlist?.length && (
                                        <hr className="hr" />
                                    )}
                                </div>
                            ))}
                        {isFetched && !wishlist?.length && (
                            <MDBRow className={styles.noElement}>
                                <MDBCol
                                    size={12}
                                    center
                                    className="d-flex justify-content-center"
                                >
                                    <p>
                                        Your favorite tours will be shown here!
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        )}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    ) : (
        <div />
    );
};
export default Wishlist;
