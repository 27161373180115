import { AwsRum, AwsRumConfig } from "aws-rum-web";

/* eslint-disable no-use-before-define, @typescript-eslint/no-explicit-any */
class Logger {
    static instance: Logger;

    private config: AwsRumConfig = {
        sessionSampleRate: 1,
        guestRoleArn: process.env.NEXT_PUBLIC_AWS_RUN_GUEST_ROLE_ARN,
        identityPoolId: process.env.NEXT_PUBLIC_AWS_RUN_IDENTITY_POOL_ID,
        endpoint: process.env.NEXT_PUBLIC_AWS_RUN_ENDPOINT,
        telemetries: ["errors"],
        allowCookies: true,
        enableXRay: false,
    };

    private awsRun: AwsRum;

    private constructor() {
        this.awsRun = new AwsRum(
            process.env.NEXT_PUBLIC_AWS_RUN_APPLICATION_ID ?? "",
            process.env.NEXT_PUBLIC_AWS_RUN_APPLICATION_VERSION ?? "",
            process.env.NEXT_PUBLIC_AWS_REGION ?? "",
            this.config
        );
    }

    public static getInstance(): Logger {
        if (!Logger.instance) {
            Logger.instance = new Logger();
        }
        return Logger.instance;
    }

    public record(error: any) {
        this.awsRun.recordError(error);
    }
}

export default Logger;
