import {
    MDBBtn,
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
} from "mdb-react-ui-kit";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useStore } from "@/store/index";
import useWishlistData from "@/utils/queries/hooks/useWishlistData";
import { useDisableBodyScroll } from "@/hooks/useDisableBodyScroll";
import { randomUid } from "../../../../utils/randomIds";
import contactsSvg from "../../../../utils/svg-icon/contacts";
import styles from "../../../../styles/navbar/NavBarM.module.scss";
import stylesTwo from "../../../../styles/navbar/NavBar.module.scss";
import BurgerIconMenu from "../BurgerIconMenu";
import NavBarMSubMenu from "./NavBarMSubMenu";
import { useWindowSizes } from "../../../../utils/hooks/useWindowSizes";
import NavBarMActionBtn from "./NavBarMActionBtn";
import {
    ToursByDestinationEntity,
    ToursByTourTypeEntity,
} from "../../../../models/home";
import userInterfaceSvg from "../../../../utils/svg-icon/userInterface";

const NavBarM: FC<{
    dropdownDataDestinations: ToursByDestinationEntity[];
    dropdownDataTours: ToursByTourTypeEntity[];
    setShowModal: Dispatch<SetStateAction<boolean>>;
}> = ({ dropdownDataDestinations, dropdownDataTours, setShowModal }) => {
    const router = useRouter();
    const openModalAuth = useStore(state => state.openModalAuth);
    const openModalFavourite = useStore(state => state.openModalFavourite);
    const { refetch } = useWishlistData();
    // eslint-disable-next-line no-underscore-dangle
    const userId = useStore(state => state.userState.userDetail._id);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [, height] = useWindowSizes();

    const handleWishlist = () => {
        if (userId) {
            refetch();
        }
        openModalFavourite();
    };

    const buttonIcons = [
        {
            Icon: userInterfaceSvg.HeartLine,
            id: randomUid(),
            onClick: handleWishlist,
        },
        {
            Icon: contactsSvg.User,
            id: randomUid(),
            onClick: openModalAuth,
        },
    ];

    const toggleMenu = () => {
        setIsOpened(!isOpened);
    };

    useEffect(() => {
        if (isOpened) {
            setIsOpened(!isOpened);
        }
    }, [router]);

    useDisableBodyScroll(isOpened);

    return (
        <>
            {!isOpened && (
                <div
                    className={stylesTwo.fixedBanner}
                    onClick={() => setShowModal(true)}
                >
                    <span className={stylesTwo.label}>
                        Click here to receive a discount worth up to €500 on
                        your first Play Italy trip
                    </span>
                </div>
            )}

            <MDBNavbar
                className={`${
                    styles.navBarM
                } d-flex justify-content-center pb-3 ${
                    isOpened ? "pt-2" : "pt-4"
                } shadow-0`}
                dark
                bgColor="light"
            >
                <MDBContainer fluid className="px-0">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <MDBNavbarBrand
                            id="play-italy-logo"
                            className={`${styles.navBarMLogo} pointer align-self-center mx-0 text-primary fw-bold ps-4`}
                            onClick={() => {
                                router.push("/");
                            }}
                        >
                            <img className="me-3" src="/logo.svg" alt="Logo" />
                        </MDBNavbarBrand>
                        <div className="d-flex me-3">
                            {buttonIcons.map(button => (
                                <MDBBtn
                                    key={randomUid()}
                                    tag="a"
                                    color="none"
                                    className={`align-self-center m-1 mx-2 ${
                                        isOpened
                                            ? "fadeOutElement d-none"
                                            : "fadeInElement"
                                    }`}
                                    onClick={() => button.onClick()}
                                >
                                    <button.Icon className={styles.svg} />
                                </MDBBtn>
                            ))}
                            <MDBBtn
                                key={randomUid()}
                                tag="a"
                                color="none"
                                className="align-self-center m-1 px-2"
                            >
                                <BurgerIconMenu
                                    isOpened={isOpened}
                                    openMenu={toggleMenu}
                                />
                            </MDBBtn>
                        </div>
                    </div>
                    {isOpened && (
                        <div
                            className={`container-fluid d-flex flex-column px-0 mx-0 ${styles.navBarMSubMenu}`}
                            style={{ height: height - 50.6 }}
                        >
                            <NavBarMSubMenu
                                className="h-100"
                                dropdownDataDestinations={
                                    dropdownDataDestinations
                                }
                                dropdownDataTours={dropdownDataTours}
                            />
                            <div
                                className={`d-flex mt-auto f-auto justify-content-between align-items-center w-100 p-2 pb-4 px-4 pt-3 ${styles.navBarMActionContainer}`}
                            >
                                <NavBarMActionBtn />
                            </div>
                        </div>
                    )}
                </MDBContainer>
            </MDBNavbar>
        </>
    );
};

export default NavBarM;
