import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import styles from "@/styles/auth/Auth.module.scss";
import userInterfaceSvg from "@/utils/svg-icon/userInterface";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import socialAndBrandSvg from "@/utils/svg-icon/socialAndBrand";
import { EBtnType, EModalAuthType } from "@/models/common/enums";
import React from "react";
import { useStore } from "@/store/index";
import Btn from "@/common/Btn";
import AuthPolicyAndTerms from "@/components/auth/AuthPolicyAndTerms";
import { useRouter } from "next/router";

const PreLogin = () => {
    const router = useRouter();
    const dispatch = useStore(state => state.dispatchAuth);
    const dispatchUser = useStore(state => state.dispatchUser);
    const { ArrowRightLine } = userInterfaceSvg;
    const { AppleFilled, FacebookFilled, GoogleLine } = socialAndBrandSvg;

    return (
        <>
            <MDBRow className="g-0 py-2">
                <Btn
                    btnType={EBtnType.TRANSPARENT}
                    onClick={() =>
                        dispatch({
                            type: "SET_AUTH_TYPE",
                            payload: EModalAuthType.LOGIN,
                        })
                    }
                >
                    <div className="d-flex justify-content-between w-100">
                        <span>Continue with Email</span>
                        <span className={styles.preLoginIconArrow}>
                            <ArrowRightLine />
                        </span>
                    </div>
                </Btn>
            </MDBRow>
            <div className="PISectionDivider" />
            <MDBRow className="mb-5">
                <MDBCol size={12} lg={4} className="my-2 my-xl-0">
                    <Btn
                        onClick={() => {
                            dispatch({ type: "RESET_AUTH" });
                            dispatchUser({
                                type: "SET_LAST_PAGE_BEFORE_LOGIN",
                                payload: router.asPath,
                            });
                            Auth.federatedSignIn({
                                provider:
                                    CognitoHostedUIIdentityProvider.Google,
                            });
                        }}
                        fullWith
                    >
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <span className={styles.preLoginIcon}>
                                <GoogleLine />
                            </span>
                            <span>Google</span>
                        </div>
                    </Btn>
                </MDBCol>{" "}
                <MDBCol size={12} lg={4} className="my-2 my-xl-0">
                    <Btn
                        onClick={() => {
                            dispatch({ type: "RESET_AUTH" });
                            dispatchUser({
                                type: "SET_LAST_PAGE_BEFORE_LOGIN",
                                payload: router.asPath,
                            });
                            Auth.federatedSignIn({
                                provider: CognitoHostedUIIdentityProvider.Apple,
                            });
                        }}
                        btnType={EBtnType.BLACK}
                        fullWith
                    >
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <span className={styles.preLoginIcon}>
                                <AppleFilled />
                            </span>
                            <span>Apple</span>
                        </div>
                    </Btn>
                </MDBCol>
                <MDBCol size={12} lg={4} className="my-2 my-xl-0">
                    <Btn
                        onClick={() => {
                            dispatch({ type: "RESET_AUTH" });
                            dispatchUser({
                                type: "SET_LAST_PAGE_BEFORE_LOGIN",
                                payload: router.asPath,
                            });
                            Auth.federatedSignIn({
                                provider:
                                    CognitoHostedUIIdentityProvider.Facebook,
                            });
                        }}
                        btnType={EBtnType.BLUE}
                        fullWith
                    >
                        <div className="d-flex justify-content-center align-items-center w-100">
                            <span className={styles.preLoginIcon}>
                                <FacebookFilled />
                            </span>
                            <span>Facebook</span>
                        </div>
                    </Btn>
                </MDBCol>
            </MDBRow>
            <MDBRow className="text-center">
                <p className={styles.infoText}>
                    <span className="d-inline-flex">
                        Don&apos;t have an account?
                    </span>
                    <span
                        className={`ms-1 d-inline-flex ${styles.infoTextLinks}`}
                        onClick={() =>
                            dispatch({
                                type: "SET_AUTH_TYPE",
                                payload: EModalAuthType.SIGNUP,
                            })
                        }
                    >
                        Sign Up
                    </span>
                </p>
            </MDBRow>
            <AuthPolicyAndTerms />
        </>
    );
};

export default PreLogin;
