import { useQuery } from "@tanstack/react-query";
import { getContactsData } from "../index";

const useContactsData = () => {
    return useQuery({
        queryKey: ["contacts"],
        queryFn: () => getContactsData(),
        refetchOnWindowFocus: false,
    });
};

export default useContactsData;
