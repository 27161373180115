export enum EMenuType {
    TOURS = "tours",
    DESTINATIONS = "destinations",
}

export enum CouruselStatus {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    ACROSSITALY = "Across Italy",
    // eslint-disable-next-line sonarjs/no-duplicate-string
    INTOTHEREGION = "Into the Region",
    // eslint-disable-next-line sonarjs/no-duplicate-string
    DOWNTHECITY = "Down the City",
}

export enum DropDownItems {
    TOURS = "tours",
    DESTINATIONS = "destinations",
    HOWITWORKS = "howItWorks",
}

export enum FaqCategory {
    BOOKINGATRIP = "Booking a trip",
    OURTOURPACKAGES = "Our tour packages",
    CHILDRENPOLICY = "Children Policy",
    PROMOTIONS = "Promotions",
}

export enum TourType {
    ACROSSITALY = "Across Italy",
    INTOTHEREGION = "Into the Region",
    DOWNTHECITY = "Down the City",
}

export enum LayoutType {
    MAIN = 0,
    SUBMODAL = 1,
    SUBSUBMODAL = 2,
}

export enum ENavBarMenuMobile {
    ALL_TOURS = "All Tours",
    DESTINATIONS = "Destinations",
    HOW_IT_WORKS = "How it works",
    ITALIAN_STORIES = "Italian Stories",
    CONTACTS = "Contacts",
    NONE = "none",
}

export enum InfoType {
    PRICE,
    DATE,
    NIGHTS,
    LANGUAGE,
    TOURTYPE,
    VIDEO_JOURNEY,
    CANCELLATION_POLICY,
    EXTRA_NIGHTS,
    MODAL_PREMIUM,
}

export enum InfoExperience {
    QUALITY,
    PREMIUM,
}

export enum ModalSize {
    LG = "lg",
    MD = "undefined",
    FULL = "fullscreen",
}

export enum ERoomType {
    SINGLE = "single",
    DOUBLE = "double",
    TRIPLE = "triple",
}

export enum EBtnType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    GRAY = "gray",
    TRANSPARENT = "transparent",
    BLACK = "black",
    BLUE = "blue",
    TRANSPARENTBLACK = "transparentBlack",
    TRANSPARENTNOHOVER = "transparentNoHover",
}

export enum EFilterType {
    TOURTYPE,
    DESTINATIONS,
    ACTIVITIES,
    PRICE,
}

export enum ETourType {
    italy = "Across Italy",
    region = "Into the Region",
    city = "Down the City",
}

export enum SearchSort {
    RACCOMANDED = "Recommended",
    BESTSELLER = "Bestsellers",
    LARGE_TO_LOW = "Price: highest to lowest",
    LOW_TO_LARGE = "Price: lowest to highest",
}

export enum EStatusStepper {
    STEP1 = 1,
    STEP2 = 2,
    STEP3 = 3,
    STEP4 = 4,
}

export enum EGender {
    MALE = "Male",
    FEMALE = "Female",
    OTHER = "Other",
    NONE = "None",
}

export enum ETourOption {
    QUALITY = "quality",
    PREMIUM = "premium",
}

export enum ETypeToastMessage {
    INFO = "Info",
    SUCCESS = "Success",
    ERROR = "Error",
}

export enum EDetailSection {
    GENERAL,
    ITINERARY,
    ACCOMMODATION,
    HIW,
}

export enum EBreakpoints {
    XXS = 375,
    XS = 576,
    SM = 768,
    MD = 992,
    LG = 1200,
    XL = 1400,
    XXL = 1600,
}

export enum EButtonBooking {
    YOURBOOKING,
    PERSONALINFO,
    PAYMENTS,
}

export enum ESignCodeStatus {
    NOVERIFY,
    VERIFY,
}

export enum EButtonUserPage {
    YOURACCOUNT,
    PERSONALINFOUSERPAGE,
    LOGINANDSECURITY,
    PAYMENTSUSERPAGE,
    NOTIFICATIONS,
}

export enum EModalAuthType {
    PRELOGIN = "prelogin",
    LOGIN = "login",
    SIGNUP = "signup",
    FORGOT_PASSWORD = "forgotPassword",
    CHANGE_PASSWORD = "changePassword",
    LOGGED = "logged",
    LOGOUT = "logout",
    NONE = "none",
    DELETE_USER = "delete",
}

export enum EActiveInvoiceStatus {
    RECEIPTED = "RECEIPTED",
    INVOICED = "INVOICED",
    REFUNDED = "REFUNDED",
}

export enum ECognitoError {
    USERNOTCONFIRMED = "UserNotConfirmedException",
}

export enum ETourLanguage {
    EN = "english",
    IT = "italian",
    ES = "spanish",
    FR = "french",
}

export enum ETypePackage {
    ONLY_QUALITY,
    ONLY_PREMIUM,
    QUALITY_AND_PREMIUM,
}

export enum EZenDeskButton {
    OPEN = "open",
    CLOSE = "close",
}

export enum EPaymentMethod {
    NONE = "none",
    CARD = "card",
    PAYPAL = "paypal",
}

export enum ERemeberMe {
    REMEMBER = "remember",
    NOTREMEMBER = "notRemember",
}

export enum ENUM_ECCESSO {
    ADULT = "ADULT",
    CHILDREN = "CHILDREN",
}
