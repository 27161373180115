import React, { Component, ErrorInfo } from "react";
import ErrorPageBody from "../ErrorPage/ErrorPageBody";
import Layout from "../common/Layout";
import type { Props, State } from "./types";

class ErrorBoundaryApp extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false };
    }

    /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    /* eslint-enable */

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return (
                <Layout metaTags={{}}>
                    <ErrorPageBody
                        isGlobal
                        errorCode="500"
                        errorMessage="Sorry, something went wrong, please try again later!"
                    />
                </Layout>
            );
        }

        return children;
    }
}

export default ErrorBoundaryApp;
