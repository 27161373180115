import { MDBCol, MDBNavbarItem } from "mdb-react-ui-kit";
import { FC, useState } from "react";
import { useRouter } from "next/router";
import styles from "@/styles/common/NavbarMSubMeu.module.scss";
import { ENavBarMenuMobile } from "../../../../models/common/enums";
import contactsSvg from "../../../../utils/svg-icon/contacts";
import fileTypesSvg from "../../../../utils/svg-icon/fileTypes";
import generalSvg from "../../../../utils/svg-icon/general";
import userInterfaceSvg from "../../../../utils/svg-icon/userInterface";
import NavBarMLink from "./NavBarMLink";
import NavBarMBackIcon from "./NavBarMBackIcon";
import NavBarMSubMenuSelected from "./NavBarMSubMenuSelected";
import NavBarMHowItWorks from "./NavBarMHowItWorks";
import {
    ToursByDestinationEntity,
    ToursByTourTypeEntity,
} from "../../../../models/home";
import { urls } from "../../../../utils/common/urls";

const NavBarMSubMenu: FC<{
    dropdownDataDestinations: ToursByDestinationEntity[];
    dropdownDataTours: ToursByTourTypeEntity[];
    className: string;
}> = ({ className, dropdownDataDestinations, dropdownDataTours }) => {
    const router = useRouter();

    const { PhoneLine2 } = generalSvg;
    const { MapsLine, FlagLine, UserLine } = contactsSvg;
    const { FileCheckLine } = fileTypesSvg;
    const { ArrowRightSimpleLine, ArrowDownSimpleLine, ArrowUpSimpleLine } =
        userInterfaceSvg;
    const [activeMenu, setActiveMenu] = useState<ENavBarMenuMobile>(
        ENavBarMenuMobile.NONE
    );

    const isNone = activeMenu === ENavBarMenuMobile.NONE;
    const isHowItWorks = activeMenu === ENavBarMenuMobile.HOW_IT_WORKS;

    const onClick = (selected: ENavBarMenuMobile) => {
        setActiveMenu(
            selected === activeMenu ? ENavBarMenuMobile.NONE : selected
        );
    };

    const getSelectedMenu = (select: ENavBarMenuMobile) => {
        if (select === ENavBarMenuMobile.ALL_TOURS) {
            return (
                <NavBarMSubMenuSelected
                    icon={<MapsLine />}
                    onClick={() => setActiveMenu(ENavBarMenuMobile.NONE)}
                    className="h-100"
                    dropdownOptions={dropdownDataTours}
                    dropdownChoice={ENavBarMenuMobile.ALL_TOURS}
                />
            );
        }
        if (select === ENavBarMenuMobile.DESTINATIONS) {
            return (
                <NavBarMSubMenuSelected
                    icon={<FlagLine />}
                    onClick={() => setActiveMenu(ENavBarMenuMobile.NONE)}
                    className="h-100"
                    dropdownOptions={dropdownDataDestinations}
                    dropdownChoice={ENavBarMenuMobile.DESTINATIONS}
                />
            );
        }

        return "";
    };

    return (
        <div
            className={`${styles.fixMenu} d-flex flex-column overflow-hidden align-items-center justify-content-center`}
        >
            {(isNone || isHowItWorks) && (
                <MDBCol
                    className={`${className} container-fluid flex-column py-3 px-0`}
                >
                    <MDBNavbarItem className="active flex-fill overflow-hidden overflow-y-scroll">
                        <NavBarMLink
                            icon={<MapsLine />}
                            icon2={<ArrowRightSimpleLine />}
                            icon2Active={<NavBarMBackIcon />}
                            title={ENavBarMenuMobile.ALL_TOURS}
                            onClick={() => onClick(ENavBarMenuMobile.ALL_TOURS)}
                            subtitle=""
                            active={false}
                            className=""
                        >
                            {undefined}
                        </NavBarMLink>
                        <NavBarMLink
                            icon={<FlagLine />}
                            icon2={<ArrowRightSimpleLine />}
                            icon2Active={<NavBarMBackIcon />}
                            title={ENavBarMenuMobile.DESTINATIONS}
                            onClick={() =>
                                onClick(ENavBarMenuMobile.DESTINATIONS)
                            }
                            subtitle=""
                            active={false}
                            className=""
                        >
                            {undefined}
                        </NavBarMLink>
                        <NavBarMLink
                            icon={<FileCheckLine />}
                            icon2={
                                isHowItWorks ? (
                                    <ArrowUpSimpleLine />
                                ) : (
                                    <ArrowDownSimpleLine />
                                )
                            }
                            icon2Active=""
                            title={ENavBarMenuMobile.HOW_IT_WORKS}
                            onClick={() =>
                                onClick(ENavBarMenuMobile.HOW_IT_WORKS)
                            }
                            subtitle=""
                            active={false}
                            className=""
                        >
                            {isHowItWorks && <NavBarMHowItWorks />}
                        </NavBarMLink>
                        <NavBarMLink
                            icon={<UserLine />}
                            title={ENavBarMenuMobile.ITALIAN_STORIES}
                            onClick={() => router.push(urls.italianStories)}
                            subtitle=""
                            icon2={undefined}
                            icon2Active={undefined}
                            active={false}
                            className=""
                        >
                            {undefined}
                        </NavBarMLink>
                        <NavBarMLink
                            icon={<PhoneLine2 />}
                            title={ENavBarMenuMobile.CONTACTS}
                            onClick={() => router.push(urls.contatcts)}
                            subtitle=""
                            icon2={undefined}
                            icon2Active={undefined}
                            active={false}
                            className=""
                        >
                            {undefined}
                        </NavBarMLink>
                    </MDBNavbarItem>
                </MDBCol>
            )}
            {!isNone && getSelectedMenu(activeMenu)}
        </div>
    );
};

export default NavBarMSubMenu;
