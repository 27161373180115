import ClockFigma from "@/public/custom-icons/icons-2/icons/Clock/ClockFigma.svg";
import MapsLine from "../../public/custom-icons/icons-2/icons/Map/Line.svg";
import MapsMarkerLine from "../../public/custom-icons/icons-2/icons/Map Marker/Line.svg";
import InfoCircleLine from "../../public/custom-icons/icons-2/icons/Info Circle/Line.svg";
import User from "../../public/custom-icons/icons-2/icons/User/Filled.svg";
import UserLine from "../../public/custom-icons/icons-2/icons/User/Line.svg";
import Calendar from "../../public/custom-icons/icons-2/icons/Calendar/Filled.svg";
import CalendarLine from "../../public/custom-icons/icons-2/icons/Calendar/Line.svg";
import Comment from "../../public/custom-icons/icons-2/icons/Comment/Line.svg";
import ClockLine from "../../public/custom-icons/icons-2/icons/Clock/Line.svg";
import FlagLine from "../../public/custom-icons/icons-2/icons/Flag/Line.svg";
import FlagFigma from "../../public/custom-icons/icons-2/icons/Flag/FlagFigma.svg";
import Quote from "../../public/custom-icons/icons-2/icons/Quote/Filled.svg";
import HomeLine from "../../public/custom-icons/icons-2/icons/Home/Line.svg";
import LocationLine from "../../public/custom-icons/icons-2/icons/Location Arrow/Line.svg";
import Phone from "../../public/custom-icons/icons-2/icons/Phone/Line.svg";
import File from "../../public/custom-icons/icons-2/icons/File/Shape.svg";
import EmailLine from "../../public/custom-icons/icons-2/icons/Email/Line.svg";
import MessengerLine from "../../public/custom-icons/icons-2/icons/Messenger/Line.svg";
import WhatsappLine from "../../public/custom-icons/icons-2/icons/Whatsapp/Line.svg";
import TelegramLine from "../../public/custom-icons/icons-2/icons/Telegram/Line.svg";

const contactsSvg = {
    ClockLine,
    FlagLine,
    MapsLine,
    User,
    UserLine,
    Comment,
    MapsMarkerLine,
    Quote,
    InfoCircleLine,
    HomeLine,
    Calendar,
    CalendarLine,
    LocationLine,
    Phone,
    File,
    EmailLine,
    MessengerLine,
    ClockFigma,
    FlagFigma,
    WhatsappLine,
    TelegramLine,
};

export default contactsSvg;
