import { SectionsTitleText } from "../../models/common/common";
import colors from "../../styles/colors.module.scss";

export const titlePrelogin: SectionsTitleText[] = [
    {
        text: "Login to",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "Play Italy",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const subTitlePreLogin: SectionsTitleText[] = [
    {
        text: "Login or subscribe to easily manage your tours and tickets on your own devices.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const titleLogin: SectionsTitleText[] = [
    {
        text: "Login with email",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const subTitleLogin: SectionsTitleText[] = [
    {
        text: "Fill in the information to complete registration",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const titleForgotPassword: SectionsTitleText[] = [
    {
        text: "Forgot your password",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const subTitleForgotPassword: SectionsTitleText[] = [
    {
        text: "Don’t worry, you can still recover it. Insert your e-mail account here.",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const titleChangePassword: SectionsTitleText[] = [
    {
        text: "Change your password",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const subTitleChangePassword: SectionsTitleText[] = [
    {
        text: "",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const titleSignup: SectionsTitleText[] = [
    {
        text: "Register to",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: false,
    },
    {
        text: "Play Italy",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];

export const subTitleSignup: SectionsTitleText[] = [
    {
        text: "Fill in the information to complete registration",
        color: colors.textGray,
        fontWeight: "",
        newLine: false,
    },
];

export const titleDeteleteUser: SectionsTitleText[] = [
    {
        text: "Delete Account?",
        color: colors.textPrimary,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
export const subTitleDeteleteUser: SectionsTitleText[] = [
    {
        text: "",
        color: colors.textGray,
        fontWeight: "fw-bold",
        newLine: false,
    },
];
