import { MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useMemo, useState } from "react";
import moment from "moment/moment";
import { useMutation } from "@tanstack/react-query";
import { countries } from "@/utils/mockData/countries";
import styles from "@/styles/signup/Signup.module.scss";
import { useRouter } from "next/router";
import { URLS } from "@/utils/constants";
import { isPasswordConfirmed } from "@/utils/common/auth";
import {
    EBtnType,
    EModalAuthType,
    ESignCodeStatus,
} from "@/models/common/enums";
import { isValidEmail } from "@/utils/common";
import { postSignUp, verifyAccount } from "@/utils/queries";
import { useStore } from "@/store/index";
import { TSignUp } from "@/models/user";
import TagManager from "react-gtm-module";
import { completeRegistration } from "@/utils/analytics/tagmanager";
import Btn from "../../common/Btn";
import SignUpForm from "./SignUpForm";
import SignUpCheckbox from "./SignUpCheckbox";
import Input from "../../common/Input";
import AuthPolicyAndTerms from "../AuthPolicyAndTerms";

const SignUpBody = ({
    setStaticBackdrop,
}: {
    setStaticBackdrop: (val: boolean) => void;
}) => {
    const router = useRouter();
    const dispatchCommon = useStore(state => state.dispatchCommon);
    const dispatchAuth = useStore(state => state.dispatchAuth);
    const setErroMsg = useStore(state => state.setErrorMsg);

    const [user, setUser] = useState<TSignUp>({
        email: "",
        name: "",
        surname: "",
        password: "",
        country: "",
        confirmPassword: "",
    });
    const [verifyCode, setVerifyCode] = useState<string>("");
    const [flagTermsAndConditions, setFlagTermsAndConditions] =
        useState<boolean>(false);
    const [flagPromotional, setFlagPromotional] = useState<boolean>(false);
    const [formState, setFormState] = useState<ESignCodeStatus>(
        ESignCodeStatus.NOVERIFY
    );
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
    const isFieldValid = (
        value: string | undefined,
        checkValidity?: (v: string) => boolean
    ): boolean => {
        if (checkValidity) {
            return !!value && !!value.trim() && checkValidity(value);
        }

        return !!value && !!value.trim();
    };

    const isRegisterButtonEnabled = useMemo(() => {
        return (
            flagTermsAndConditions &&
            isFieldValid(user.email, isValidEmail) &&
            isFieldValid(user.name) &&
            isFieldValid(user.surname) &&
            isFieldValid(user.country) &&
            isPasswordConfirmed(user.password, user.confirmPassword)
        );
    }, [user, flagTermsAndConditions, dateOfBirth]);

    const isVerifyButtonEnabled = useMemo(
        () => isFieldValid(verifyCode),
        [verifyCode]
    );

    const { mutate: addUser, isLoading } = useMutation(postSignUp, {
        onSuccess: () => {
            // Success actions
            setFormState(ESignCodeStatus.VERIFY);
            setStaticBackdrop(true);
            const countryName = countries.find(obj => obj.id === user.country);
            TagManager.dataLayer(
                completeRegistration(user, countryName?.it ?? "")
            );
        },
        onError: err => {
            // Error actions
            setErroMsg(err as never);
        },
    });

    const { mutate: verify } = useMutation(verifyAccount, {
        onSuccess: () => {
            router.push(URLS.home);
            setStaticBackdrop(false);
            dispatchAuth({
                type: "SET_AUTH_TYPE",
                payload: EModalAuthType.LOGIN,
            });
        },
        onError: err => setErroMsg(err as never),
    });

    const onButtonClick = () => {
        const date = dateOfBirth
            ? { birthDate: moment(dateOfBirth).format("YYYY-MM-DD") }
            : {};
        if (formState === ESignCodeStatus.NOVERIFY) {
            addUser({
                username: user.email,
                password: user.password,
                name: user.name,
                surname: user.surname,
                email: user.email,
                marketingPromotions: flagPromotional,
                country:
                    countries.find(country => country.id === user.country)
                        ?.it || "",
                ...date,
            });
        } else {
            verify({ username: user.email, code: verifyCode });
        }
    };

    const onChange = (e: {
        stopPropagation: () => void;
        preventDefault: () => void;
        target: {
            name: keyof Partial<{ verifyCode: string }>;
            value: unknown;
        };
    }) => {
        e.stopPropagation();
        e.preventDefault();

        const { value } = e.target;

        setVerifyCode((value as string).trim());
    };

    useEffect(() => {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: isLoading });
    }, [dispatchCommon, isLoading]);

    return (
        <>
            {formState === ESignCodeStatus.NOVERIFY && (
                <>
                    <MDBRow>
                        <SignUpForm
                            user={user}
                            dateOfBirth={dateOfBirth}
                            setUser={setUser}
                            setDateOfBirth={setDateOfBirth}
                        />
                    </MDBRow>
                    <MDBRow className="g-0 py-2">
                        <SignUpCheckbox
                            flagPromotional={flagPromotional}
                            flagTermsAndConditions={flagTermsAndConditions}
                            setFlagPromotional={setFlagPromotional}
                            setFlagTermsAndConditions={
                                setFlagTermsAndConditions
                            }
                        />
                    </MDBRow>
                </>
            )}
            {formState === ESignCodeStatus.VERIFY && (
                <MDBRow>
                    <Input
                        type="text"
                        name="verifyCode"
                        id="verifyCode"
                        placeholder="Verification Code"
                        diasbleStatusIcon
                        columnProps={{ size: 12, className: "my-3" }}
                        required
                        value={verifyCode}
                        onChange={onChange as never}
                    />
                </MDBRow>
            )}
            <MDBRow className="g-0 py-4">
                <Btn
                    btnType={EBtnType.PRIMARY}
                    onClick={onButtonClick}
                    disabled={
                        (formState === ESignCodeStatus.NOVERIFY &&
                            !isRegisterButtonEnabled) ||
                        (formState === ESignCodeStatus.VERIFY &&
                            !isVerifyButtonEnabled)
                    }
                    fullWith
                >
                    {formState === ESignCodeStatus.NOVERIFY && "Sign Up"}
                    {formState === ESignCodeStatus.VERIFY && "Verify"}
                </Btn>
            </MDBRow>
            <MDBRow className="g-0 text-center">
                <p className={styles.infoText}>
                    <span className="d-inline-flex">
                        Already have an account?
                    </span>
                    <span
                        className={`text-center ${styles.infoTextLinks} ms-1`}
                        onClick={() =>
                            dispatchAuth({
                                type: "SET_AUTH_TYPE",
                                payload: EModalAuthType.LOGIN,
                            })
                        }
                    >
                        Login
                    </span>
                </p>
            </MDBRow>
            <AuthPolicyAndTerms />
        </>
    );
};

export default SignUpBody;
