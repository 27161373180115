import {
    MDBBtn,
    MDBCol,
    MDBCollapse,
    MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler,
} from "mdb-react-ui-kit";
import { forwardRef, ReactElement, useState } from "react";
import { useRouter } from "next/router";
import styles from "styles/navbar/NavBar.module.scss";
import { useStore } from "@/store/index";
import useWishlistData from "@/utils/queries/hooks/useWishlistData";
import { ZendeskAPI } from "react-zendesk";
import { randomUid } from "@/utils/randomIds";
import { DropDownItems, EZenDeskButton } from "@/models/common/enums";
import userInterfaceSvg from "@/utils/svg-icon/userInterface";
import contactsSvg from "@/utils/svg-icon/contacts";
import { useIsZenDeskOpen } from "@/hooks/useZenDesk";
import NavSubMenu from "./NavSubMenu";
import DropdownMiniMenu from "./DropdownMiniMenu";

const NavBar = forwardRef<
    HTMLDivElement,
    {
        dropdownHandler: (arg: DropDownItems) => void;
        isSmallDropdownOpen: boolean;
        isBigDropdownOpen: boolean;
        closeBigDropdown: () => void;
        closeSmallDropDown: () => void;
    }
>(
    (
        {
            dropdownHandler,
            isSmallDropdownOpen,
            isBigDropdownOpen,
            closeBigDropdown,
            closeSmallDropDown,
        },
        ref
        // eslint-disable-next-line sonarjs/cognitive-complexity
    ): ReactElement => {
        const router = useRouter();
        const pathStyleWhite = "/tours-destinations";
        const openModalAuth = useStore(state => state.openModalAuth);
        const openModalFavourite = useStore(state => state.openModalFavourite);
        // eslint-disable-next-line no-underscore-dangle
        const userId = useStore(state => state.userState.userDetail._id);
        const isZenOpen = useIsZenDeskOpen();
        const { refetch } = useWishlistData();

        const handleWishlist = () => {
            if (userId) {
                refetch();
            }
            openModalFavourite();
        };

        const goToPath = (path: string) => {
            router.push(
                {
                    pathname: path,
                    query: {
                        tourType: null,
                        destination: null,
                    },
                },
                path,
                { shallow: true }
            );
        };

        const checkButton = (identificator: string) => {
            if (identificator === "navigate-tours") {
                goToPath(pathStyleWhite);
            } else {
                ZendeskAPI(
                    "messenger",
                    isZenOpen ? EZenDeskButton.CLOSE : EZenDeskButton.OPEN
                );
            }
        };

        const actionButtons = [
            {
                title: "Choose your tour",
                id: randomUid(),
                icon: contactsSvg.MapsLine,
                style: styles.button_highlight,
                identificator: "navigate-tours",
                path: pathStyleWhite,
            },
            {
                title: "Chat with us",
                id: randomUid(),
                icon: contactsSvg.Comment,
                style: styles.button,
                identificator: "navigate-home",
                path: "/",
            },
        ];

        const buttonIcons = [
            {
                icon: userInterfaceSvg.HeartLine,
                id: randomUid(),
                onClick: handleWishlist,
            },
            {
                icon: contactsSvg.User,
                id: randomUid(),
                onClick: openModalAuth,
            },
        ];

        const standardLink = [
            {
                title: "Italian stories",
                id: randomUid(),
                path: "/italian-stories",
            },
            {
                title: "Contacts",
                id: randomUid(),
                path: "/contacts",
            },
        ];

        const dropdownItems = ["About us", "How it works"];
        const [showNavExternal, setShowNavExternal] = useState(false);
        const [showLateralMenu, setShowLateralMenu] = useState(false);

        return (
            <MDBNavbar
                className="d-flex justify-content-center py-3 shadow-0 displayDesktop"
                expand="lg"
                dark
                bgColor="light"
            >
                <MDBContainer className="mx-lg-0 mx-xl-1 mx-xxl-4 px-0 mx-0 mw-100 pe-4">
                    <MDBCol
                        sm={3}
                        className={`${styles.mobile} justify-content-center px-4`}
                    >
                        <MDBNavbarToggler
                            className="p-0"
                            type="button"
                            data-target="#navbarColor02"
                            aria-controls="navbarColor02"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={() => setShowNavExternal(!showNavExternal)}
                        >
                            <MDBIcon
                                className={`${styles.overlay} square rounded-7 border border-2 p-3 shadow-0 border-primary text-primary`}
                                icon="bars"
                                fas
                            />
                        </MDBNavbarToggler>
                    </MDBCol>
                    <MDBCol
                        sm={3}
                        lg={2}
                        className="d-inline-flex d-xl-flex justify-content-center ps-xl-2"
                    >
                        <MDBNavbarBrand
                            style={{ cursor: "pointer" }}
                            id="play-italy-logo"
                            className={`${styles.vertical_divider_right} align-self-center mx-0 text-primary fw-bold`}
                            onClick={() => router.push(`/`)}
                        >
                            <img className=" mx-4" src="/logo.svg" alt="Logo" />
                        </MDBNavbarBrand>
                    </MDBCol>
                    <MDBCol
                        sm={3}
                        className={`${styles.mobile} justify-content-center px-4`}
                    >
                        <MDBIcon
                            className={`${styles.overlay} top_icons square rounded-7 border border-2 p-3 shadow-0`}
                            fas
                            icon="search"
                        />
                    </MDBCol>
                    <MDBCollapse
                        className={`${styles.overlay} mt-lg-0 mt-3`}
                        show={showNavExternal}
                        navbar
                    >
                        <MDBNavbarNav className="d-inline-flex me-auto mb-2 mb-lg-0 px-0">
                            <MDBCol
                                xl={6}
                                className={`${
                                    !showLateralMenu ? styles.lateralMenu : ""
                                }  d-flex flex-column flex-lg-row py-3 py-lg-0 py-xl-2`}
                            >
                                <div
                                    ref={ref}
                                    className="d-flex align-items-center"
                                >
                                    <div
                                        className={`${styles.linkText} nav-link`}
                                    >
                                        <p
                                            onMouseEnter={() =>
                                                dropdownHandler(
                                                    DropDownItems.TOURS
                                                )
                                            }
                                            className={`${styles.paragraphStyle} my-0 dropdown-toggle `}
                                        >
                                            tours
                                        </p>
                                    </div>
                                    <div
                                        className={`${styles.linkText} nav-link`}
                                    >
                                        <p
                                            onMouseEnter={() =>
                                                dropdownHandler(
                                                    DropDownItems.DESTINATIONS
                                                )
                                            }
                                            className={`${styles.paragraphStyle} my-0 dropdown-toggle`}
                                        >
                                            destinations
                                        </p>
                                    </div>
                                    <div className="dropdown">
                                        <div
                                            className={`${styles.linkText} nav-link dropdown-toggle `}
                                            id="smallDropdownMenu"
                                            data-mdb-toggle="dropdown"
                                        >
                                            <p
                                                className={`${styles.paragraphStyle} my-0`}
                                                onMouseEnter={() =>
                                                    dropdownHandler(
                                                        DropDownItems.HOWITWORKS
                                                    )
                                                }
                                            >
                                                How it works
                                            </p>
                                        </div>
                                        {isSmallDropdownOpen && (
                                            <DropdownMiniMenu
                                                onMouseLeave={
                                                    closeSmallDropDown
                                                }
                                            />
                                        )}
                                    </div>
                                </div>

                                {standardLink.map(item => (
                                    <div
                                        key={item.id}
                                        className="d-flex flex-lg-column justify-content-lg-center justify-content-xl-center py-2 py-lg-0 py-xl-0 px-5 px-lg-0"
                                    >
                                        <MDBNavbarItem className="d-flex active flex-fill">
                                            <MDBNavbarLink
                                                className="d-flex justify-content-between flex-fill align-self-center"
                                                aria-current="page"
                                                onClick={() => {
                                                    router.push(item.path);
                                                }}
                                            >
                                                <div className="d-flex flex-row align-self-center">
                                                    <MDBIcon
                                                        className={`${styles.mobile} ${styles.overlay} square rounded-6 p-2 d-lg-none align-self-center me-4 text-primary`}
                                                        far
                                                        icon="star"
                                                    />
                                                    <p
                                                        className={`${styles.paragraphStyle} my-0 align-self-center`}
                                                        onMouseEnter={() =>
                                                            isSmallDropdownOpen
                                                                ? closeSmallDropDown()
                                                                : isBigDropdownOpen
                                                                ? closeBigDropdown()
                                                                : null
                                                        }
                                                    >
                                                        {item.title}
                                                    </p>
                                                </div>
                                                <MDBIcon
                                                    className={`${styles.mobile} align-self-center text-primary`}
                                                    fas
                                                    icon="chevron-circle-right"
                                                />
                                            </MDBNavbarLink>
                                        </MDBNavbarItem>
                                    </div>
                                ))}
                            </MDBCol>
                            <NavSubMenu
                                className={`${
                                    showLateralMenu ? styles.lateralMenu : ""
                                } ${styles.subMenu}`}
                                callBackTitle="Play Italy"
                                items={dropdownItems}
                                onClick={() => {
                                    setShowLateralMenu(!showLateralMenu);
                                }}
                            />
                            <MDBCol
                                className={`${styles.vertical_divider_right} d-flex justify-content-center justify-content-lg-end py-5 py-lg-0 py-xl-0`}
                                xl={5}
                            >
                                {actionButtons.map(button => (
                                    <MDBBtn
                                        key={button.id}
                                        outline
                                        rounded
                                        color="white"
                                        className={`${button.style} ${
                                            button.path === pathStyleWhite
                                                ? styles.paragraphStyleWhite
                                                : styles.paragraphStyle
                                        } align-self-center p-2 me-2`}
                                        size="sm"
                                        type="button"
                                        id={button.identificator}
                                        onClick={() =>
                                            checkButton(button.identificator)
                                        }
                                    >
                                        {button.title}
                                        <button.icon
                                            className={`${
                                                button.path === pathStyleWhite
                                                    ? styles.svgWhite
                                                    : styles.svg
                                            } mx-2`}
                                            alt=""
                                        />
                                    </MDBBtn>
                                ))}
                            </MDBCol>
                            <MDBCol
                                className="d-inline-flex ps-xl-2 py-0"
                                xl={1}
                            >
                                {buttonIcons.map(element => (
                                    <MDBBtn
                                        key={element.id}
                                        tag="a"
                                        color="none"
                                        className="align-self-center m-1 px-2"
                                        onClick={() => element.onClick()}
                                    >
                                        <element.icon className={styles.svg} />
                                    </MDBBtn>
                                ))}
                            </MDBCol>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        );
    }
);

export default NavBar;
