/* eslint-disable */

import { useEffect, useState } from "react";
import { ZendeskAPI } from "react-zendesk";

export const zenSettings = {
    color: {
        theme: "#ed6f6f",
    },
    launcher: {
        chatLabel: {
            "en-US": "Need Help",
        },
    },
    contactForm: {
        fields: [
            {
                id: "description",
                prefill: { "*": "My pre-filled description" },
            },
        ],
    },
};

export const useIsZenDeskOpen = () => {
    const [isZenOpen, setZenOpen] = useState(false);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isZendeskReady = typeof window !== "undefined" && window.zE;

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const onPageLoad = () => {
            ZendeskAPI("messenger:on", "open", () => {
                setZenOpen(true);
            });
            ZendeskAPI("messenger:on", "close", () => {
                setZenOpen(false);
            });
        };

        // Check if the page has already loaded
        if (document.readyState === "complete") {
            onPageLoad();
        } else {
            window.addEventListener("load", onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener("load", onPageLoad);
        }
    }, [isZendeskReady]);

    return isZenOpen;
};
