import moment from "moment";

export const formatDate = (date: string, locales?: string) =>
    new Date(date).toLocaleDateString(locales || "en-GB", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });

export const getEndDate = (startDate: string | Date, rangeLength: number) => {
    if (!rangeLength || rangeLength <= 1) {
        return moment(startDate).format("MMMM D, YYYY");
    }
    return moment(startDate)
        .startOf("day")
        .add(rangeLength - 1, "days")
        .format("MMMM D, YYYY");
};

export const getNumberOfDays = (
    startDate: string | Date,
    endDate: string | Date
) => {
    return moment(endDate).add(1, "days").diff(moment(startDate), "days");
};

export const getDateMaxYearsAgoDateString = (
    dateInputElement: Date,
    yearsAgo: number
) => {
    const pastDate = new Date(
        dateInputElement.getFullYear() - yearsAgo,
        dateInputElement.getMonth(),
        dateInputElement.getDate()
    );
    return pastDate.toISOString().split("T")[0];
};

export const getDateMaxYearsAgo = (
    dateInputElement: Date,
    yearsAgo: number
) => {
    return new Date(
        dateInputElement.getFullYear() - yearsAgo,
        dateInputElement.getMonth(),
        dateInputElement.getDate()
    );
};

export const getFormattedDate = (date: string | Date): string => {
    return moment(date).format("YYYY-MM-DD");
};
