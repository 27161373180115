import { FC } from "react";
import { useRouter } from "next/router";
import { OpenGraph, Twitter } from "next-seo/lib/types";
import { NextSeo } from "next-seo";
import { getUrl } from "@/utils/queries/utils/utils";

const Seo: FC<{ seoInfo: OpenGraph; keywords?: string[] }> = ({
    seoInfo,
    keywords,
}) => {
    const { asPath, locale, locales = [] } = useRouter();

    const metaDescription = "E-commerce PlayItaly";
    const siteName = "PlayItaly";
    const type = "website";
    const metaTitle = "PlayItaly";
    const metaBaseUrl =
        process.env.NEXT_PUBLIC_WEBAPP_URL || "http://localhost";

    const facebookTag = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
        ? {
              appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
          }
        : undefined;

    const twitterTag: Twitter = {
        site: process.env.NEXT_PUBLIC_TWITTER_SITE_TAG,
        cardType: "summary_large_image",
    };
    const metaLinks = [
        {
            rel: "icon",
            href: "/favicon.ico",
        },
        {
            rel: "apple-touch-icon",
            href: "/favicon.ico",
        },
        {
            rel: "manifest",
            href: "/manifest.json",
        },
    ];

    const additionalMetaTags =
        keywords && keywords.length
            ? {
                  additionalMetaTags: [
                      {
                          name: "keywords",
                          content: keywords.join(", "),
                      },
                  ],
              }
            : {};

    const {
        title = metaTitle,
        description = metaDescription,
        ...res
    } = seoInfo ?? {};

    const localeAlternates = locales.filter(item => item !== locale);

    const handleUrl = (currentLocale?: string, _url?: string) => {
        if (
            currentLocale !== (process.env.NEXT_PUBLIC_DEFAULT_LOCALE ?? "en")
        ) {
            if (_url) return getUrl(metaBaseUrl, `/${currentLocale}/${_url}`);
            return getUrl(metaBaseUrl, `/${currentLocale}`);
        }

        if (_url) return getUrl(metaBaseUrl, `/${_url}`);

        return metaBaseUrl;
    };

    const languageAlternates = localeAlternates.map(_locale => ({
        hrefLang: _locale,
        href: handleUrl(_locale, asPath),
    }));

    return (
        <NextSeo
            title={title}
            titleTemplate={` %s | ${siteName}`}
            defaultTitle={metaTitle}
            description={description}
            canonical={handleUrl(locale, asPath)}
            languageAlternates={languageAlternates}
            openGraph={{
                type,
                description,
                title: `${title} | ${siteName}`,
                siteName,
                images: res.images,
                ...res,
            }}
            facebook={facebookTag}
            twitter={twitterTag}
            additionalLinkTags={metaLinks}
            {...additionalMetaTags}
        />
    );
};

export default Seo;
