import { IPackageState, TActionPackage } from "../models/store";
import { getDefaultPackageDetailState } from "../utils/common/store";

export const reducerPackage = (
    state: IPackageState,
    action: TActionPackage
): IPackageState => {
    switch (action.type) {
        case "SET_CURRENT_SECTION":
            return { ...state, currentSection: action.payload };
        case "RESET_PACKAGE":
            return getDefaultPackageDetailState();
        default:
            return state;
    }
};
