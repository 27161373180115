import { StateCreator, StoreMutatorIdentifier } from "zustand";
import { TError, TServerError } from "@/models/common/common";
import { EModalAuthType, ETypeToastMessage } from "@/models/common/enums";
import { checkAuth } from "@/utils/common/auth";
import { TStore } from "@/models/store";
import { Auth } from "aws-amplify";
import { getErrorMessage } from "@/utils/common";
import { signOut } from "@/utils/queries";

type TSaga = <
    T,
    Mps extends [StoreMutatorIdentifier, unknown][] = [],
    Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
    f: StateCreator<T, Mps, Mcs>,
    name?: string
) => StateCreator<T, Mps, Mcs>;

type TSagaImpl = (
    f: StateCreator<TStore, [], []>,
    name?: string
) => StateCreator<TStore, [], []>;

// eslint-disable-next-line
const sagaImpl: TSagaImpl = (f, _name) => (set, get, store) => {
    // type T = ReturnType<typeof f>;
    const loggedSet: typeof set = (...a) => {
        set(...a);

        // console.log(...(name ? [`${name}:`] : []), get().action);
    };
    // eslint-disable-next-line no-param-reassign
    store.setState = loggedSet;

    return f(loggedSet, get, store);
};

export const saga = sagaImpl as unknown as TSaga;

export const openModalAuth = async (get: () => TStore) => {
    const { dispatchAuth } = get();
    const checkeAuth = await checkAuth();

    if (checkeAuth.isAuth) {
        dispatchAuth({ type: "SET_AUTH_TYPE", payload: EModalAuthType.LOGGED });
        return;
    }
    dispatchAuth({ type: "SET_AUTH_TYPE", payload: EModalAuthType.PRELOGIN });
};

export const openModalFavourite = async (get: () => TStore) => {
    const { dispatchAuth, dispatchFavourite } = get();
    const checkeAuth = await checkAuth();
    if (checkeAuth.isAuth) {
        dispatchFavourite({ type: "SET_IS_OPEN", payload: true });
        return;
    }
    dispatchAuth({ type: "SET_AUTH_TYPE", payload: EModalAuthType.PRELOGIN });
};

export const loadLogout = async (get: () => TStore) => {
    const { dispatchAuth, setErrorMsg } = get();
    try {
        dispatchAuth({ type: "RESET_AUTH" });
        await signOut();
        localStorage.setItem("rememberMe", "notRemember");
    } catch (err) {
        setErrorMsg(err as never);
    }
};

export const loadErrorMessage = (
    err: TServerError | TError,
    get: () => TStore,
    cb?: () => void
) => {
    const { dispatchCommon } = get();

    dispatchCommon({
        type: "SET_TOAST_MESSAGE_DATA",
        payload: {
            message: getErrorMessage(err),
            type: ETypeToastMessage.ERROR,
            cb: cb || (() => dispatchCommon({ type: "RESET_COMMON" })),
        },
    });
};

export const loadSuccessMessage = (
    message: string,
    get: () => TStore,
    cb?: () => void
) => {
    const { dispatchCommon } = get();

    dispatchCommon({
        type: "SET_TOAST_MESSAGE_DATA",
        payload: {
            message,
            type: ETypeToastMessage.SUCCESS,
            cb: cb || (() => dispatchCommon({ type: "RESET_COMMON" })),
        },
    });
};

export const loadChangePassword = async (
    oldPassword: string,
    newPassword: string,
    get: () => TStore
) => {
    const { dispatchCommon, dispatchAuth, setErrorMsg, setUserDetail } = get();
    try {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);

        dispatchCommon({
            type: "SET_TOAST_MESSAGE_DATA",
            payload: {
                type: ETypeToastMessage.SUCCESS,
                message: "Passwor Changed",
            },
        });
        dispatchAuth({
            type: "RESET_AUTH",
        });
        await setUserDetail();
    } catch (err) {
        setErrorMsg(err as never);
    } finally {
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};

export const loadInitStore = async (get: () => TStore) => {
    const { setErrorMsg, userState, setUserDetail, dispatchUser } = get();
    // eslint-disable-next-line no-underscore-dangle
    const userId = userState.userDetail._id;
    try {
        const check = await checkAuth();
        if (check.isAuth && !userId) {
            await setUserDetail();
            return;
        }
        if (!check.isAuth && userId) {
            dispatchUser({ type: "RESET_USER" });
        }
    } catch (err) {
        setErrorMsg(err as never);
    }
};

export const loadHandleStatusMessage = (
    status: "error" | "success" | "sending" | null,
    message: string | Error | null,
    get: () => TStore
) => {
    const { dispatchCommon, setErrorMsg, setSuccessMsg } = get();
    try {
        if (!status) {
            dispatchCommon({ type: "RESET_COMMON" });
            return;
        }
        if (status !== "sending") {
            dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
        }
        if (status === "sending") {
            dispatchCommon({ type: "SET_LOADER_PAGE", payload: true });
            return;
        }

        if (status === "success") {
            setSuccessMsg((message as string) || "");
            return;
        }
        if (status === "error") {
            setErrorMsg({ message: message as never });
        }
    } catch (err) {
        setErrorMsg(err as never);
        dispatchCommon({ type: "SET_LOADER_PAGE", payload: false });
    }
};
