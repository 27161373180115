import { FC } from "react";

const FooterCopy: FC<{ year: number }> = ({ year }) => {
    return (
        <>
            <hr className="hr hr-blurry" />
            <div className="text-center p-3">
                &copy; {year} PLAYITALY.COM All rights reserved.
            </div>
        </>
    );
};

export default FooterCopy;
