import {
    MDBAccordion,
    MDBAccordionItem,
    MDBCol,
    MDBRow,
} from "mdb-react-ui-kit";
import Link from "next/link";
import {
    footerSectionsOne,
    footerSectionsTwo,
} from "../../../utils/mockData/homeMocks";
import style from "../../../styles/Footer.module.scss";
import { randomUid } from "../../../utils/randomIds";

const FooterLinksMobile = () => {
    return (
        <MDBCol
            lg="3"
            xl="3"
            className={`mb-4 ${style.mobile} ${style.footerLinksMob}`}
        >
            <MDBRow className="p-0">
                {[...footerSectionsOne, ...footerSectionsTwo].map(section => (
                    <MDBCol size={12} className="mx-auto" key={section.id}>
                        <MDBAccordion
                            borderless
                            className={style.footerLinksMobAccordion}
                        >
                            <MDBAccordionItem
                                key={randomUid()}
                                collapseId={1}
                                headerTitle={section.title}
                                className={`${style.accordionItem} ${style.accordionItemMobile} border-1`}
                            >
                                {section.links.map(link => {
                                    return (
                                        <p
                                            className={`${style.text_content} text-start text-white`}
                                            key={link.id}
                                        >
                                            <Link
                                                href={link.href}
                                                className="text-reset"
                                            >
                                                {link.title}
                                            </Link>
                                        </p>
                                    );
                                })}
                            </MDBAccordionItem>
                        </MDBAccordion>
                    </MDBCol>
                ))}
            </MDBRow>
        </MDBCol>
    );
};
export default FooterLinksMobile;
