import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useRef,
    useState,
} from "react";
import { DropDownItems } from "@/models/common/enums";
import { BREACKPOINT_TABLET } from "@/utils/constants";
import { useWindowSizes } from "@/hooks/useWindowSizes";
import { useOnClickOutside } from "@/hooks/useOnClickOutiside";
import { useIsZenDeskOpen } from "@/hooks/useZenDesk";
import styles from "../../../styles/navbar/NavBar.module.scss";
import DropdownMenu from "./DropdownMenu";
import NavBar from "./NavBar";
import NavBarM from "./NavBarM/NavBarM";
import useNavBarData from "../../../utils/queries/hooks/useNavBarData";

type NavBarLayoutProps = {
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const NavBarLayout: FC<NavBarLayoutProps> = ({ setShowModal }) => {
    const { data: dropDownData } = useNavBarData();
    const [isBigDropdownOpen, setBigDropdownOpen] = useState(false);
    const [isSmallDropdownOpen, setSmallDropdownOpen] = useState(false);
    const refNavBar = useRef<HTMLDivElement>(null);
    const refDropDown = useRef<HTMLDivElement>(null);
    const [dropdownChoice, setDropdownChoice] = useState<DropDownItems>(
        DropDownItems.TOURS
    );
    const isZenOpen = useIsZenDeskOpen();

    const [width] = useWindowSizes();
    const isMoblie = width <= BREACKPOINT_TABLET && width > 0;

    const isSameDropdownItem = (selectedItem: DropDownItems) => {
        return selectedItem === dropdownChoice;
    };

    const dropdownHandler = (selectedItem: DropDownItems) => {
        if (selectedItem !== DropDownItems.HOWITWORKS) {
            setSmallDropdownOpen(false);
            setBigDropdownOpen(
                !(isSameDropdownItem(selectedItem) && isBigDropdownOpen)
            );
            setDropdownChoice(selectedItem);
        } else {
            setBigDropdownOpen(false);
            setSmallDropdownOpen(!isSmallDropdownOpen);
        }
    };

    const handleClickOutside = useCallback(() => {
        setBigDropdownOpen(false);
        setSmallDropdownOpen(false);
    }, []);

    useOnClickOutside([refNavBar, refDropDown], handleClickOutside);

    return (
        <div
            className={styles.menuSticky}
            style={{ zIndex: `${isZenOpen ? "1000" : "1030"}` }}
        >
            {!isMoblie && (
                <div
                    className={styles.fixedBanner}
                    onClick={() => setShowModal(true)}
                >
                    <span className={styles.label}>
                        Click here to receive a discount worth up to €500 on
                        your first Play Italy trip
                    </span>
                </div>
            )}

            {!isMoblie && (
                <NavBar
                    ref={refNavBar}
                    dropdownHandler={dropdownHandler}
                    isSmallDropdownOpen={isSmallDropdownOpen}
                    isBigDropdownOpen={isBigDropdownOpen}
                    closeBigDropdown={() => setBigDropdownOpen(false)}
                    closeSmallDropDown={() => setSmallDropdownOpen(false)}
                />
            )}
            {isMoblie && (
                <NavBarM
                    dropdownDataDestinations={dropDownData!.toursByDestination}
                    dropdownDataTours={dropDownData!.toursHome}
                    setShowModal={setShowModal}
                />
            )}
            {isBigDropdownOpen && (
                <DropdownMenu
                    ref={refDropDown}
                    dropdownChoice={dropdownChoice}
                    dropdownDataDestinations={dropDownData!.toursByDestination}
                    dropdownDataTours={dropDownData!.toursHome}
                    onMouseLeave={() => {
                        if (isBigDropdownOpen) setBigDropdownOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default NavBarLayout;
