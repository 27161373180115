import EyeLine from "@/public/custom-icons/icons-2/icons/Eye/Line.svg";
import HeartLine from "../../public/custom-icons/icons-2/icons/Heart/Line.svg";
import Heart from "../../public/custom-icons/icons-2/icons/Heart/Filled.svg";
import StarLine from "../../public/custom-icons/icons-2/icons/Star/Line.svg";
import StarFilled from "../../public/custom-icons/icons-2/icons/Star/Filled.svg";
import StarFilledFigma from "../../public/custom-icons/icons-2/icons/Star/StarFigmaIcon.svg";
import ArrowRightLine from "../../public/custom-icons/icons-2/icons/Arrow Right 2/Line.svg";
import ArrowLeftLine from "../../public/custom-icons/icons-2/icons/Arrow Left 2/Line.svg";
import ArrowDownSimpleLine from "../../public/custom-icons/icons-2/icons/Arrow Down Simple/Line.svg";
import ArrowUpSimpleLine from "../../public/custom-icons/icons-2/icons/Arrow Up Simple/Line.svg";
import ArrowLeftSimpleLine from "../../public/custom-icons/icons-2/icons/Arrow Left Simple/Line.svg";
import ArrowRightSimpleLine from "../../public/custom-icons/icons-2/icons/Arrow Right Simple/Line.svg";
import CloseLine from "../../public/custom-icons/icons-2/icons/Close/Line.svg";
import ShareSquare from "../../public/custom-icons/icons-2/icons/Share Square/Line.svg";
import MoreLine from "../../public/custom-icons/icons-2/icons/More/Line.svg";
import SmileFilled from "../../public/custom-icons/icons-2/icons/Smile/Filled.svg";
import PlusCircle from "../../public/custom-icons/icons-2/icons/Plus 1/Line.svg";
import MinusCircle from "../../public/custom-icons/icons-2/icons/Minus 1/Line.svg";
import BurgerLine from "../../public/custom-icons/icons-2/icons/Burger/Line.svg";
import CheckLine from "../../public/custom-icons/icons-2/icons/Check/Line.svg";
import ArrowDownSquareLine from "../../public/custom-icons/icons-2/icons/Arrow Down Square/Line.svg";
import ArrowUpSquareLine from "../../public/custom-icons/icons-2/icons/Arrow Up Square/Line.svg";
import CloseSquareLine from "../../public/custom-icons/icons-2/icons/Close Square/Line.svg";

const userInterfaceSvg = {
    Heart,
    HeartLine,
    ArrowRightLine,
    ArrowLeftLine,
    StarLine,
    StarFilled,
    StarFilledFigma,
    ArrowDownSimpleLine,
    ArrowUpSimpleLine,
    ArrowLeftSimpleLine,
    ArrowRightSimpleLine,
    CloseLine,
    ShareSquare,
    MoreLine,
    SmileFilled,
    PlusCircle,
    MinusCircle,
    BurgerLine,
    CheckLine,
    CloseSquareLine,
    ArrowDownSquareLine,
    ArrowUpSquareLine,
    EyeLine,
};

export default userInterfaceSvg;
