import { MDBRow } from "mdb-react-ui-kit";
import styles from "@/styles/auth/Auth.module.scss";
import { useRouter } from "next/router";
import { useStore } from "@/store/index";
import axios from "axios";

const AuthPolicyAndTerms = () => {
    const router = useRouter();
    const dispatch = useStore(state => state.dispatchAuth);

    const closeModal = () => {
        dispatch({
            type: "RESET_AUTH",
        });
    };

    const handleClick = (href: string) => {
        closeModal();
        router.push(href);
    };

    const downloadFileAtURL = (url: string | undefined, fileName: string) => {
        if (url) {
            axios
                .get(url, { responseType: "arraybuffer" })
                .then(blob => {
                    const newBlob = new Blob([blob.data], {
                        type: "application/pdf",
                    });
                    const blobURL = window.URL.createObjectURL(newBlob);
                    const aTag = document.createElement("a");
                    aTag.href = blobURL;
                    aTag.setAttribute("download", fileName);
                    document.body.appendChild(aTag);
                    aTag.click();
                    aTag.remove();
                })
                .catch(error => console.log(error));
        }
    };

    return (
        <MDBRow className="g-0 w-100 text-center">
            <p className={styles.infoText}>
                By logging into my PlayItaly account, I accept the{" "}
                <span
                    className={`d-inline-flex ${styles.infoTextLinks}`}
                    onClick={() => handleClick("/info/privacy")}
                >
                    Privacy Policy
                </span>{" "}
                and the{" "}
                <span
                    className={`d-inline-flex ${styles.infoTextLinks}`}
                    onClick={() =>
                        downloadFileAtURL(
                            "https://d3t4iiwihaed0b.cloudfront.net/terms-condition/Terms_Conditions.pdf",
                            "Terms & Conditions"
                        )
                    }
                >
                    terms and conditions
                </span>{" "}
                of Play Italy
            </p>
        </MDBRow>
    );
};

export default AuthPolicyAndTerms;
