import { FC } from "react";
import { MDBContainer, MDBFooter, MDBRow } from "mdb-react-ui-kit";
import FooterCopy from "./FooterCopy";
import FooterCta from "./FooterCta";
import FooterLinks from "./FooterLinks";
import FooterLogo from "./FooterLogo";

import style from "../../../styles/Footer.module.scss";
import FooterLinksMobile from "./FooterLinksMobile";

const FooterLayout: FC = () => {
    const year = new Date().getFullYear();

    return (
        <MDBFooter
            className={`${style.footBgColor} ${style.footerLayout} text-center minWidth100vwFromXL`}
        >
            <MDBContainer className={`${style.footerLayoutContainer}`}>
                <section>
                    <MDBContainer className="text-center text-md-start mt-sm-5">
                        <MDBRow className="mt-sm-3">
                            <FooterLogo />
                            <FooterLinks />
                            <FooterLinksMobile />
                            <FooterCta />
                        </MDBRow>
                    </MDBContainer>
                </section>
            </MDBContainer>
            <FooterCopy year={year} />
        </MDBFooter>
    );
};

export default FooterLayout;
