import { useMutation } from "@tanstack/react-query";
import { getPackageDetailData, putFavourite } from "@/utils/queries";
import { useStore } from "@/store/index";
import { ETypeToastMessage } from "@/models/common/enums";
import TagManager from "react-gtm-module";

export const useToggleFavourite = () => {
    const dispatchUser = useStore(state => state.dispatchUser);
    const dispatchCommon = useStore(state => state.dispatchCommon);

    const userDetail = useStore(state => state.userState.userDetail);

    return useMutation({
        mutationFn: putFavourite,
        // Notice the second argument is the variables object that the `mutate` function receives
        onSuccess: ({ id, data }) => {
            dispatchCommon({
                type: "SET_TOAST_MESSAGE_DATA",
                payload: {
                    type: ETypeToastMessage.SUCCESS,
                    message: `Tour has been ${
                        data.favouriteTours.length >
                        (userDetail.favouriteTours?.length ?? 0)
                            ? "added"
                            : "removed"
                    }  from your Wishlist`,
                },
            });
            dispatchUser({
                type: "SET_USER_DETAIL",
                payload: { ...userDetail, favouriteTours: data.favouriteTours },
            });
            if (
                data.favouriteTours.length >
                (userDetail.favouriteTours?.length ?? 0)
            ) {
                getPackageDetailData(id as string).then(tour => {
                    TagManager.dataLayer({
                        dataLayer: {
                            event: "add_to_wishlist",
                            currency: "EUR",
                            value: tour.quality.price,
                            items: [
                                {
                                    item_id: tour.quality.tourId,
                                    item_name: tour.quality.tourTitle,
                                    index: 0,
                                    item_brand: "Play Italy",
                                    item_category: tour.quality.tourType,
                                    item_variant: "quality",
                                    price: tour.quality.price,
                                    quantity: 1,
                                },
                            ],
                        },
                    });
                });
            }
        },
    });
};
