import { OpenGraph } from "next-seo/lib/types";

const commonDescription = "E-commerce di Play Italy";

const homepageSeo: OpenGraph = {
    title: "Homepage",
    description: commonDescription,
};

const contactsSeo: OpenGraph = {
    title: "Contacts",
    description: commonDescription,
};

const faqSeo: OpenGraph = {
    title: "Faq",
    description: commonDescription,
};

const italianStoriesSeo: OpenGraph = {
    title: "Italian Stories",
    description: commonDescription,
};

const toursDestinationSeo: OpenGraph = {
    title: "Search",
    description: commonDescription,
};
const privacySeo: OpenGraph = {
    title: "Privacy",
    description: commonDescription,
};
const termsSeo: OpenGraph = {
    title: "Terms And Conditions",
    description: commonDescription,
};

const playWithUsSeo: OpenGraph = {
    title: "Play With Us",
    description: commonDescription,
};

const fourOhFourSeo: OpenGraph = {
    title: "404",
    description: commonDescription,
};

const profileSeo: OpenGraph = {
    title: "Profile",
    description: commonDescription,
};

const bookingSeo: OpenGraph = {
    title: "Bookings",
    description: commonDescription,
};

const checkoutSeo: OpenGraph = {
    title: "Checkout",
    description: commonDescription,
};

const fiveHundredSeo: OpenGraph = {
    title: "500",
    description: commonDescription,
};

export enum ESeoPage {
    HOME,
    ITALIANSTORIES,
    PLAYWITHUS,
    FOUROHFOUR,
    FIVEHUNDRED,
    TOURSDESTINATION,
    PRIVACY,
    TERMS,
    CONTACTS,
    USER,
    BOOKINGS,
    FAQ,
    CHECKOUT,
}

export const seoContents = [
    {
        id: ESeoPage.HOME,
        ...homepageSeo,
    },
    {
        id: ESeoPage.ITALIANSTORIES,
        ...italianStoriesSeo,
    },
    {
        id: ESeoPage.PLAYWITHUS,
        ...playWithUsSeo,
    },
    {
        id: ESeoPage.FOUROHFOUR,
        ...fourOhFourSeo,
    },
    {
        id: ESeoPage.FIVEHUNDRED,
        ...fiveHundredSeo,
    },
    {
        id: ESeoPage.TOURSDESTINATION,
        ...toursDestinationSeo,
    },
    {
        id: ESeoPage.PRIVACY,
        ...privacySeo,
    },
    {
        id: ESeoPage.TERMS,
        ...termsSeo,
    },
    {
        id: ESeoPage.CONTACTS,
        ...contactsSeo,
    },
    {
        id: ESeoPage.FAQ,
        ...faqSeo,
    },
    {
        id: ESeoPage.USER,
        ...profileSeo,
    },
    {
        id: ESeoPage.BOOKINGS,
        ...bookingSeo,
    },
    {
        id: ESeoPage.CHECKOUT,
        ...checkoutSeo,
    },
];
