/* eslint-disable sonarjs/no-use-of-empty-return-value */
import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import moment from "moment/moment";
import ReactDatePicker from "react-datepicker";
import { countries } from "@/utils/mockData/countries";
import { checkPassword, isPasswordConfirmed } from "@/utils/common/auth";
import { isValidEmail } from "@/utils/common";
import { TSignUp } from "@/models/user";
import Input from "../../common/Input";
import DatePickerButton from "../../common/DatePickerButton";

const SignUpForm: FC<{
    user: TSignUp;
    dateOfBirth: Date | null;
    setUser: Dispatch<SetStateAction<TSignUp>>;
    setDateOfBirth: Dispatch<SetStateAction<Date | null>>;
}> = ({ user, setUser, dateOfBirth, setDateOfBirth }) => {
    const onChange = (e: {
        stopPropagation: () => void;
        preventDefault: () => void;
        target: {
            name: keyof Partial<TSignUp>;
            value: unknown;
        };
    }) => {
        e.stopPropagation();
        e.preventDefault();

        const { name, value } = e.target;

        setUser({ ...user, [name]: value });
    };

    useEffect(() => {
        const checkTabPress = (e: KeyboardEvent, id: string) => {
            if (e.key === "Tab") {
                switch (id) {
                    case "email":
                        document?.getElementById("name")?.focus();
                        break;
                    case "name":
                        document?.getElementById("surname")?.focus();
                        break;
                    case "surname":
                        document?.getElementById("password")?.focus();
                        break;
                    case "password":
                        document?.getElementById("confirmPassword")?.focus();
                        break;
                    case "confirmPassword":
                        document?.getElementById("email")?.focus();
                        break;
                    default:
                        break;
                }
            }
        };
        document
            ?.getElementById("email")
            ?.addEventListener("keyup", event => checkTabPress(event, "email"));
        document
            ?.getElementById("name")
            ?.addEventListener("keyup", event => checkTabPress(event, "name"));
        document
            ?.getElementById("surname")
            ?.addEventListener("keyup", event =>
                checkTabPress(event, "surname")
            );
        document
            ?.getElementById("password")
            ?.addEventListener("keyup", event =>
                checkTabPress(event, "password")
            );
        document
            ?.getElementById("confirmPassword")
            ?.addEventListener("keyup", event =>
                checkTabPress(event, "confirmPassword")
            );
    }, []);

    return (
        <form>
            <Input
                type="email"
                name="email"
                id="email"
                inputProps={{ autoComplete: "username" }}
                placeholder="Email"
                columnProps={{ size: 12, className: "my-3" }}
                required
                diasbleStatusIcon
                value={user.email}
                onChange={onChange as never}
                validationCb={value => isValidEmail(value as string)}
            />
            <Input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                diasbleStatusIcon
                columnProps={{ size: 12, className: "my-3" }}
                required
                value={user.name}
                onChange={onChange as never}
            />
            <Input
                type="text"
                name="surname"
                id="surname"
                placeholder="Last Name"
                diasbleStatusIcon
                columnProps={{ size: 12, className: "my-3" }}
                required
                value={user.surname}
                onChange={onChange as never}
            />
            <ReactDatePicker
                selected={dateOfBirth || null}
                onChange={date => {
                    setDateOfBirth(date);
                }}
                customInput={
                    <DatePickerButton
                        className="my-3"
                        placeholderText="Birth Date"
                    />
                }
                maxDate={moment().subtract(18, "years").toDate()}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                yearDropdownItemNumber={15}
                dateFormat="MMMM d, yyyy"
                disabledKeyboardNavigation
                withPortal
            />
            <Input
                type="select"
                columnProps={{ size: 12, className: "my-3" }}
                onChangeSelect={onChange as never}
                name="country"
                id="country"
                required
                value={user.country}
                options={[
                    { value: "", label: "Select your Country" },
                    ...countries.map(country => ({
                        value: country.id,
                        label: country.en,
                    })),
                ]}
            />
            <Input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                inputProps={{ autoComplete: "new-password" }}
                columnProps={{ size: 12, className: "my-3" }}
                required
                value={user.password}
                hideValueAttribute
                onChange={onChange as never}
                validationCb={value => checkPassword(value as string)}
            />
            <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                inputProps={{ autoComplete: "new-password" }}
                columnProps={{ size: 12, className: "my-3" }}
                required
                value={user.confirmPassword}
                hideValueAttribute
                onChange={onChange as never}
                validationCb={value =>
                    isPasswordConfirmed(user.password, value as string)
                }
            />
        </form>
    );
};

export default SignUpForm;
