import { useEffect, useState } from "react";
import { create, StateCreator } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import {
    IAuthStore,
    ICommonStore,
    IFavouriteStore,
    IPackageStore,
    TActions,
    TStore,
} from "../models/store";
import {
    emptyState,
    getDefaultAuthState,
    getDefaultCommonState,
    getDefaultFavouriteState,
    getDefaultPackageDetailState,
} from "../utils/common/store";
import { reducerCommon } from "./reducer-commont";
import { reducerPackage } from "./reducer-package";
import {
    loadLogout,
    openModalAuth,
    openModalFavourite,
    saga,
    loadErrorMessage,
    loadChangePassword,
    loadInitStore,
    loadSuccessMessage,
    loadHandleStatusMessage,
} from "./saga";
import { reducerAuth } from "./reducer-auth";
import { reducerFavourite } from "./reducer-favourite";
import { createCheckoutSlice } from "./checkout/slice-checkout";
import { createUserSlice } from "./user/slice-user";

const isProd = process.env.NEXT_PUBLIC_NODE_ENV === "prod";

const createCommonSlice: StateCreator<TStore, [], [], ICommonStore> = (
    set,
    get
) => ({
    commonState: { ...getDefaultCommonState() },
    dispatchCommon: action =>
        set(state => ({
            ...state,
            action: action as TActions,
            commonState: reducerCommon(state.commonState, action),
        })),
    setErrorMsg: (error, cb) => loadErrorMessage(error, get, cb),
    setSuccessMsg: (msg, cb) => loadSuccessMessage(msg, get, cb),
    handleStatusMsg: (status, message) =>
        loadHandleStatusMessage(status, message, get),
    initStore: () => loadInitStore(get),
});

const createPackageSlice: StateCreator<
    TStore,
    [],
    [],
    IPackageStore
> = set => ({
    packageState: { ...getDefaultPackageDetailState() },
    dispatchPackage: action =>
        set(state => ({
            ...state,
            action: action as TActions,
            packageState: reducerPackage(state.packageState, action),
        })),
});

const createAuthSlice: StateCreator<TStore, [], [], IAuthStore> = (
    set,
    get
) => ({
    authState: { ...getDefaultAuthState() },
    dispatchAuth: action =>
        set(state => ({
            ...state,
            action: action as TActions,
            authState: reducerAuth(state.authState, action),
        })),
    openModalAuth: () => openModalAuth(get),
    logout: () => loadLogout(get),
    changePassword: (oldPassword, newPassword) =>
        loadChangePassword(oldPassword, newPassword, get),
});

const createFavouriteSlice: StateCreator<TStore, [], [], IFavouriteStore> = (
    set,
    get
) => ({
    favouriteState: { ...getDefaultFavouriteState() },
    dispatchFavourite: action =>
        set(state => ({
            ...state,
            action: action as TActions,
            favouriteState: reducerFavourite(state.favouriteState, action),
        })),
    openModalFavourite: () => openModalFavourite(get),
});

export const usePersistedStore = create<TStore>()(
    saga(
        persist(
            isProd
                ? (...a) => ({
                      ...createCheckoutSlice(...a),
                      ...createCommonSlice(...a),
                      ...createPackageSlice(...a),
                      ...createUserSlice(...a),
                      ...createAuthSlice(...a),
                      ...createFavouriteSlice(...a),
                  })
                : devtools((...a) => ({
                      ...createCheckoutSlice(...a),
                      ...createCommonSlice(...a),
                      ...createPackageSlice(...a),
                      ...createUserSlice(...a),
                      ...createAuthSlice(...a),
                      ...createFavouriteSlice(...a),
                  })),
            {
                name: "store",
                version: 1,
                storage: createJSONStorage(() => localStorage),
            }
        )
    )
);

export const useStore = ((selector, compare) => {
    const store = usePersistedStore(selector, compare);
    const [hydrated, setHydrated] = useState(false);
    useEffect(() => setHydrated(true), []);

    return hydrated ? store : selector(emptyState);
}) as typeof usePersistedStore;
