import { IOverride, RoomReadbleTO } from "@/models/detail";
import { IRoomSelection } from "@/models/common/common";
import {
    findCountryNameById,
    isValidPhoneNumber,
    isValidWithoutSpace,
} from ".";

import {
    IMainCustomer,
    IRoom,
    ITraveler,
    TInvoice,
    TPrices,
} from "../../models/checkout";

import { EGender } from "../../models/common/enums";

import { IUser } from "../../models/user";
import { countries } from "../mockData/countries";

export const isEuropeanCountry = (country: string) => {
    const selectedCountry = countries.filter(item => item.id === country);
    if (selectedCountry.at(0)?.it === "Italia") return false;
    return selectedCountry.at(0)?.ue ?? false;
};

export const isCompleteFormDetails = (form: IMainCustomer) => {
    const {
        phone,
        fiscalCode,
        address,
        city,
        county,
        country,
        zipCode,
        emergencyContactName,
        emergencyContactPhone,
        emergencyContactRelationship,
    } = form;

    return !!(
        isValidPhoneNumber(phone) &&
        fiscalCode &&
        address &&
        city &&
        county &&
        country &&
        zipCode &&
        emergencyContactName &&
        isValidPhoneNumber(emergencyContactPhone) &&
        emergencyContactRelationship
    );
};

export const isCompleteFormInvoice = (
    form: TInvoice,
    userType: "PRIVATE" | "COMPANY" | undefined,
    emergencyContactName: string,
    emergencyContactRelationship: string,
    emergencyContactPhone: string
) => {
    const {
        addressCity,
        // addressPostalCode,
        // addressProvince,
        addressStreet,
        country,
        name,
        taxCode,
        vatNumber,
    } = form;

    if (userType === "PRIVATE" && country === "IT") {
        return (
            name !== "" &&
            isValidWithoutSpace(taxCode) &&
            taxCode !== "" &&
            addressStreet !== "" &&
            addressStreet !== undefined &&
            // addressPostalCode !== "" &&
            addressCity !== "" &&
            emergencyContactName !== "" &&
            emergencyContactPhone !== "" &&
            emergencyContactRelationship !== ""
        );
    }

    if (
        userType === "PRIVATE" &&
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        isEuropeanCountry(country!)
    ) {
        return (
            name !== "" &&
            addressStreet !== "" &&
            addressStreet !== undefined &&
            // addressPostalCode !== "" &&
            addressCity !== "" &&
            emergencyContactName !== "" &&
            emergencyContactPhone !== "" &&
            emergencyContactRelationship !== ""
        );
    }

    if (userType === "COMPANY" && country === "IT") {
        return (
            name !== "" &&
            vatNumber !== "" &&
            addressStreet !== "" &&
            addressStreet !== undefined &&
            // addressPostalCode !== "" &&
            addressCity !== "" &&
            // addressProvince !== "" &&
            emergencyContactName !== "" &&
            emergencyContactPhone !== "" &&
            emergencyContactRelationship !== ""
        );
    }

    if (userType === "COMPANY" && isEuropeanCountry(country)) {
        return (
            name !== "" &&
            vatNumber !== "" &&
            addressStreet !== "" &&
            addressStreet !== undefined &&
            // addressPostalCode !== "" &&
            addressCity !== "" &&
            emergencyContactName !== "" &&
            emergencyContactPhone !== "" &&
            emergencyContactRelationship !== ""
        );
    }

    if (!isEuropeanCountry(country)) {
        return (
            name !== "" &&
            // vatNumber !== "" &&
            addressStreet !== "" &&
            addressStreet !== undefined &&
            // addressPostalCode !== "" &&
            addressCity !== "" &&
            emergencyContactName !== "" &&
            emergencyContactPhone !== "" &&
            emergencyContactRelationship !== ""
        );
    }

    return true;
};

const createAdultTraveler = (
    isLeader: boolean,
    index: number,
    userDetail?: IUser
): ITraveler => {
    if (isLeader && userDetail) {
        return {
            isLeader,
            name: userDetail.name,
            surname: userDetail.surname,
            email: userDetail.email,
            gender:
                userDetail.gender === "None" || !userDetail.gender
                    ? EGender.NONE
                    : (userDetail.gender as EGender),
            roomNumber: index + 1,
            isChildren: false,
            age: 18,
            dateOfBirth: userDetail.birthDate,
            phone: userDetail.phone,
            cityOfBirth: userDetail.cityOfBirth,
            fiscalCode: userDetail.taxIdCode,
            address: userDetail.address,
            city: userDetail.city,
            nationality: userDetail.country,
        };
    }
    return {
        isLeader,
        name: "",
        surname: "",
        email: "",
        gender: EGender.NONE,
        roomNumber: index + 1,
        isChildren: false,
        age: 18,
        phone: "",
        nationality: "Italian",
    };
};

const createChildTraveler = (index: number, child: number): ITraveler => ({
    isLeader: false,
    name: "",
    surname: "",
    email: "",
    gender: EGender.NONE,
    roomNumber: index + 1,
    isChildren: true,
    age: child,
    nationality: "Italian",
});

export const generateTravelers = (
    rooms: IRoom[],
    userDetail?: IUser,
    existingTravelers: ITraveler[] = []
): ITraveler[] => {
    let existingIndex = 0;

    const newTravelers: ITraveler[] = rooms.reduce((acc, curr, index) => {
        const arrByAdultsNumber: ITraveler[] = Array.from(
            { length: curr.adults },
            (_, i) => {
                const isLeader = i === 0 && index === 0;

                if (
                    existingIndex < existingTravelers.length &&
                    existingTravelers[existingIndex].travelerNumber
                ) {
                    const traveler = {
                        ...existingTravelers[existingIndex],
                        isLeader,
                    };
                    existingIndex += 1;
                    return traveler;
                }

                return createAdultTraveler(isLeader, index, userDetail);
            }
        );
        //  const arrByChildren: ITraveler[] = [];
        // curr.children.forEach(child => {
        //     if (
        //         existingIndex < existingTravelers.length &&
        //         existingTravelers[existingIndex].isChildren
        //     ) {
        //         const traveler = existingTravelers[existingIndex];
        //         existingIndex += 1;
        //         arrByChildren.push(traveler);
        //     } else {
        //         arrByChildren.push(createChildTraveler(index, child));
        //     }
        // });

        // return [...acc, ...arrByAdultsNumber, ...arrByChildren];
        // }, [] as ITraveler[]);

        // return newTravelers;
        // };

        console.log("crr", curr.children);
        console.log("existingTravelers", existingTravelers);
        const arrByChildren: ITraveler[] = [];
        curr.children.forEach(child => {
            if (
                existingIndex < existingTravelers.length &&
                existingTravelers[existingIndex].isChildren
            ) {
                const traveler = existingTravelers[existingIndex];
                existingIndex += 1;
                arrByChildren.push(traveler);
            } else {
                arrByChildren.push(createChildTraveler(index, child));
            }
        });

        return [...acc, ...arrByAdultsNumber, ...arrByChildren];
    }, [] as ITraveler[]);

    return newTravelers.map((curr, i) => ({
        ...curr,
        travelerNumber: i + 1,
    }));
};

export const getInvoiceData = (toInvoiced: boolean, invoice: TInvoice | null) =>
    toInvoiced && invoice
        ? {
              invoice: {
                  ...invoice,
                  country: findCountryNameById(invoice.country),
              },
              toInvoiced,
          }
        : {
              toInvoiced,
          };

export const getPrices = (
    rooms: IRoomSelection[],
    overrideSelected: IOverride | undefined,
    roomInfo: RoomReadbleTO[]
): TPrices =>
    rooms.reduce(
        (acc, room) => {
            let { grossPrice, netPrice, markup } = acc;
            const selectedRoomInfoOverride =
                overrideSelected?.roomsOverride.find(
                    element => element.type === room.roomType
                );

            const selectedRoomInfo = roomInfo.find(
                element => element.type === room.roomType
            );

            const roomGrossPrice =
                selectedRoomInfoOverride?.grossPrice ||
                selectedRoomInfo?.grossPrice;

            const roomNetPrice =
                selectedRoomInfoOverride?.netPrice ||
                selectedRoomInfo?.netPrice;

            const roomMarkup =
                selectedRoomInfoOverride?.markup || selectedRoomInfo?.markup;

            grossPrice += +(roomGrossPrice ?? 0);
            netPrice += +(roomNetPrice ?? 0);
            markup += +(roomMarkup ?? 0);

            return {
                grossPrice,
                netPrice,
                markup,
            };
        },
        { grossPrice: 0, netPrice: 0, markup: 0 }
    );
