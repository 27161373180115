import { IFavouriteState, TActionFavourite } from "@/models/store";
import { getDefaultFavouriteState } from "@/utils/common/store";

export const reducerFavourite = (
    state: IFavouriteState,
    action: TActionFavourite
): IFavouriteState => {
    switch (action.type) {
        case "SET_IS_OPEN":
            return { ...state, isOpen: action.payload };
        case "RESET_FAVOURITE":
            return getDefaultFavouriteState();
        default:
            return state;
    }
};
