import React from "react";
import { EBtnType } from "@/models/common/enums";
import styles from "@/styles/auth/Auth.module.scss";
import Btn from "../common/Btn";

type ButtonLoggedListTO = {
    title: string;
    icon: React.ReactNode;
    onClick: () => void;
};

type Props = {
    buttonLoggedList: ButtonLoggedListTO[];
    onClick: () => void;
};

const LoggedModalBody = ({ buttonLoggedList, onClick }: Props) => (
    <>
        {buttonLoggedList.map((curr, i) => (
            <div key={curr.title} className="m-0 p-0">
                <Btn
                    btnType={EBtnType.TRANSPARENT}
                    onClick={curr.onClick}
                    className="border-0 my-0"
                    fullWith
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                >
                    <div className="d-flex">
                        <span>{curr.icon}</span>
                        <span className={`${styles.loggedText} ms-2`}>
                            {curr.title}
                        </span>
                    </div>
                </Btn>
                {buttonLoggedList.length > i + 1 && (
                    <div className="PISectionDivider my-3" />
                )}
            </div>
        ))}
        <Btn
            btnType={EBtnType.PRIMARY}
            onClick={onClick}
            fullWith
            className="mt-4"
        >
            Logout
        </Btn>
    </>
);

export default LoggedModalBody;
