import { MDBBtn } from "mdb-react-ui-kit";
import { FC, ReactNode } from "react";
import { EBtnType } from "../../models/common/enums";
import styles from "../../styles/common/Btn.module.scss";

const Btn: FC<{
    type?: "button" | "reset" | "submit";
    btnType?: EBtnType;
    onClick: () => void;
    defaultStyle?: string;
    className?: string;
    children: ReactNode;
    disabled?: boolean;
    fullWith?: boolean;
}> = ({
    type = "button",
    btnType,
    onClick,
    defaultStyle = "shadow-0",
    className = "",
    fullWith,
    disabled,
    children,
}) => {
    const btnTypes = {
        [EBtnType.PRIMARY]: styles.primary,
        [EBtnType.SECONDARY]: styles.secondary,
        [EBtnType.GRAY]: styles.gray,
        [EBtnType.TRANSPARENT]: styles.transparent,
        [EBtnType.BLACK]: styles.black,
        [EBtnType.BLUE]: styles.blue,
        [EBtnType.TRANSPARENTBLACK]: styles.transparentBlack,
        [EBtnType.TRANSPARENTNOHOVER]: styles.transparentNoHover,
    };

    const classSelected =
        // eslint-disable-next-line security/detect-object-injection
        btnType && btnTypes[btnType]
            ? btnTypes[btnType]
            : btnTypes[EBtnType.PRIMARY];

    return (
        <MDBBtn
            type={type}
            className={`${classSelected} ${
                disabled ? styles.disabled : ""
            } ${defaultStyle} ${className} ${fullWith ? "w-100" : ""}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </MDBBtn>
    );
};

export default Btn;
