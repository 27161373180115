import { ICommontState, TActionCommon } from "../models/store";
import { getDefaultCommonState } from "../utils/common/store";

export const reducerCommon = (
    state: ICommontState,
    action: TActionCommon
): ICommontState => {
    switch (action.type) {
        case "SET_TOAST_MESSAGE_DATA":
            return { ...state, toastMessageData: action.payload };
        case "SET_LOADER_PAGE":
            return { ...state, loadingPage: action.payload };
        case "RESET_COMMON":
            return getDefaultCommonState();
        default:
            return state;
    }
};
