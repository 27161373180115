import Btn from "@/components/common/Btn";
import Input from "@/components/common/Input";
import { EBtnType, EModalAuthType } from "@/models/common/enums";
import { useStore } from "@/store/index";
import { isValidEmail } from "@/utils/common";
import { checkPassword, isPasswordConfirmed } from "@/utils/common/auth";
import { forgotPassword, forgotPasswordSubmit } from "@/utils/queries";
import { useMutation } from "@tanstack/react-query";
import { MDBRow } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";

const ForgotPasswordBody = ({
    setStaticBackdrop,
}: {
    setStaticBackdrop: (val: boolean) => void;
}) => {
    const [status, setStatus] = useState<"SEND_EMAIL" | "CHANGE_PASSWORD">(
        "SEND_EMAIL"
    );
    const [email, setEmail] = useState("");
    const [submitNewPassword, setSubmitNewPassword] = useState<{
        password: string;
        confirmPassword: string;
        code: string;
    }>({ code: "", password: "", confirmPassword: "" });

    const dispatch = useStore(state => state.dispatchCommon);
    const dispatchAuth = useStore(state => state.dispatchAuth);
    const setErroMsg = useStore(state => state.setErrorMsg);

    const isSendEmail = status === "SEND_EMAIL";

    const { mutate: forgotPasswordEmail, isLoading } = useMutation(
        forgotPassword,
        {
            onSuccess: () => {
                setStatus("CHANGE_PASSWORD");
                setStaticBackdrop(true);
            },
            onError: err => setErroMsg(err as never),
        }
    );

    const { mutate: forgotPasswordSubmitPsw, isLoading: isLoadingSubmit } =
        useMutation(forgotPasswordSubmit, {
            onSuccess: () => {
                setStatus("SEND_EMAIL");
                dispatch({
                    type: "SET_LOADER_PAGE",
                    payload: false,
                });
                dispatchAuth({
                    type: "SET_AUTH_TYPE",
                    payload: EModalAuthType.LOGIN,
                });
                setStaticBackdrop(false);
            },
            onError: err => setErroMsg(err as never),
        });

    useEffect(
        () =>
            dispatch({
                type: "SET_LOADER_PAGE",
                payload: isLoading || isLoadingSubmit,
            }),
        [dispatch, isLoading, isLoadingSubmit]
    );

    useEffect(() => {
        const checkTabPress = (e: KeyboardEvent, id: string) => {
            if (e.key === "Tab") {
                switch (id) {
                    case "code":
                        document?.getElementById("password")?.focus();
                        break;
                    case "password":
                        document?.getElementById("confirmPassword")?.focus();
                        break;
                    case "confirmPassword":
                        document?.getElementById("code")?.focus();
                        break;
                    default:
                        break;
                }
            }
        };
        document
            ?.getElementById("code")
            ?.addEventListener("keyup", event => checkTabPress(event, "code"));
        document
            ?.getElementById("password")
            ?.addEventListener("keyup", event =>
                checkTabPress(event, "password")
            );
        document
            ?.getElementById("confirmPassword")
            ?.addEventListener("keyup", event =>
                checkTabPress(event, "confirmPassword")
            );
    }, [isSendEmail]);

    const renderInputEmail = (
        <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            columnProps={{ size: 12, className: "my-3" }}
            inputProps={{ autoComplete: "username" }}
            required
            value={email}
            onDelete={() => setEmail("")}
            onChange={e => setEmail(e.target.value)}
            validationCb={value => isValidEmail(value as string)}
        />
    );

    const renderPasswords = (
        <form>
            <Input
                type="text"
                name="code"
                id="code"
                placeholder="Code - check your email"
                columnProps={{ size: 12, className: "my-3" }}
                required
                value={submitNewPassword.code}
                onChange={e =>
                    setSubmitNewPassword({
                        ...submitNewPassword,
                        code: e.target.value,
                    })
                }
            />
            <Input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                inputProps={{ autoComplete: "new-password" }}
                columnProps={{ size: 12, className: "my-3" }}
                required
                value={submitNewPassword.password}
                hideValueAttribute
                onChange={e =>
                    setSubmitNewPassword({
                        ...submitNewPassword,
                        password: e.target.value,
                    })
                }
                validationCb={value => checkPassword(value as string)}
            />
            <Input
                type="password"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Confirm Password"
                inputProps={{ autoComplete: "confirm-password" }}
                columnProps={{ size: 12, className: "my-3" }}
                required
                value={submitNewPassword.confirmPassword}
                hideValueAttribute
                onChange={e =>
                    setSubmitNewPassword({
                        ...submitNewPassword,
                        confirmPassword: e.target.value,
                    })
                }
                validationCb={value =>
                    isPasswordConfirmed(
                        submitNewPassword.password,
                        value as string
                    )
                }
            />
        </form>
    );

    return (
        <>
            <MDBRow>{isSendEmail ? renderInputEmail : renderPasswords}</MDBRow>
            <MDBRow className="g-0 py-2">
                <Btn
                    btnType={EBtnType.PRIMARY}
                    onClick={() =>
                        isSendEmail
                            ? forgotPasswordEmail({ username: email })
                            : forgotPasswordSubmitPsw({
                                  username: email,
                                  code: submitNewPassword.code,
                                  newPassword: submitNewPassword.password,
                              })
                    }
                    disabled={
                        !(isSendEmail
                            ? isValidEmail(email?.trim())
                            : isPasswordConfirmed(
                                  submitNewPassword.password,
                                  submitNewPassword.confirmPassword
                              ) && submitNewPassword.code.trim())
                    }
                    fullWith
                >
                    {isSendEmail ? "Recovery Password" : "Send new Password"}
                </Btn>
            </MDBRow>
        </>
    );
};

export default ForgotPasswordBody;
