import Search from "../../public/custom-icons/home-custom/search.svg";
import Star from "../../public/custom-icons/icon/star.svg";
import StarFilled from "../../public/custom-icons/icons-2/icons/Star/Filled.svg";
import ImageLine from "../../public/custom-icons/icons-2/icons/Image/Line.svg";
import EnvelopeLine from "../../public/custom-icons/search-custom/envelope.svg";
import FileLine from "../../public/custom-icons/search-custom/file.svg";
import PhoneLine from "../../public/custom-icons/search-custom/phone.svg";
import PhoneLine2 from "../../public/custom-icons/icons-2/icons/Phone/Line.svg";

const generalSvg = {
    Search,
    Star,
    StarFilled,
    ImageLine,
    EnvelopeLine,
    FileLine,
    PhoneLine,
    PhoneLine2,
};

export default generalSvg;
