import { useEffect, useState } from "react";
import style from "../../styles/common/ToastMessage.module.scss";
import {
    getTypeMsgClass,
    getTypeMsgSvgIcon,
} from "../../utils/common/toastMessage";
import { useStore } from "../../store";
import { ETypeToastMessage } from "../../models/common/enums";

const ToastMessage = () => {
    const [display, setDisplay] = useState(false);
    const { message, type, cb, timer } = useStore(
        state => state.commonState?.toastMessageData
    );
    const dispatchCommon = useStore(state => state.dispatchCommon);

    useEffect(() => {
        setDisplay(!!message);

        const timerDisplay = setTimeout(() => {
            setDisplay(false);
            if (cb) cb();
            dispatchCommon({
                type: "SET_TOAST_MESSAGE_DATA",
                payload: {
                    message: "",
                    type: ETypeToastMessage.INFO,
                },
            });
        }, timer || 3000);

        return () => {
            clearTimeout(timerDisplay);
        };
    }, [message, timer]);

    return display ? (
        <div className={`${style.container} ${getTypeMsgClass(type)} p-3`}>
            {getTypeMsgSvgIcon(type)}
            <p className="m-0">{message}</p>
        </div>
    ) : (
        <div />
    );
};

export default ToastMessage;
