import { MDBCol } from "mdb-react-ui-kit";
import React, {
    FC,
    HTMLInputTypeAttribute,
    ReactNode,
    useEffect,
    useState,
} from "react";
import styles from "@/styles/common/Input.module.scss";
import { ColumnProps } from "../../typings/mdb-react-ui-kit";
import mediaControlSvg from "../../utils/svg-icon/mediaControl";
import userInterfaceSvg from "../../utils/svg-icon/userInterface";

export type TInputStatus = "error" | "success" | "default";

export type TInpuntValue = string | number | readonly string[] | undefined;

export interface IInputProps {
    label?: string;
    placeholder?: string;
    name: string;
    id?: string;
    type?: HTMLInputTypeAttribute | "select";
    value: TInpuntValue;
    hideValueAttribute?: boolean;
    required?: boolean;
    columnProps?: ColumnProps;
    options?: { value: string; label: string }[];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeSelect?: (value: React.ChangeEvent<HTMLSelectElement>) => void;
    onDelete?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    inputStatus?: TInputStatus;
    diasbleStatusIcon?: boolean;
    statusDefault?: boolean;
    validationCb?: (value: TInpuntValue) => boolean;
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
    inputSelectProps?: React.InputHTMLAttributes<HTMLSelectElement>;
    disabled?: boolean;
    flagDefaultStatus?: boolean;
    hideBorder?: boolean;
    inputClassName?: string;
    isSelectConfigurator?: boolean;
}

type TInpuntStatus = {
    className: string;
    icon: ReactNode;
    status: TInputStatus;
};

const getIcon = (
    type: HTMLInputTypeAttribute | "select",
    validation: boolean
) => {
    const { CloseCircleFilled } = mediaControlSvg;
    const { CheckLine, ArrowDownSimpleLine, EyeLine } = userInterfaceSvg;

    if (type === "password") {
        return (
            <div className={`${styles.icon}`}>
                <EyeLine />
            </div>
        );
    }

    if (type === "select") {
        return (
            <div className={`${styles.icon} ${styles.iconSelect}`}>
                <ArrowDownSimpleLine />
            </div>
        );
    }
    if (validation) {
        return (
            <div className={`${styles.icon} ${styles.iconSuccess}`}>
                <CheckLine />
            </div>
        );
    }
    return (
        <div className={`${styles.icon} ${styles.iconError}`}>
            <CloseCircleFilled />
        </div>
    );
};

const Input: FC<IInputProps> = ({
    label,
    id,
    type,
    name,
    value,
    hideValueAttribute,
    placeholder,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onBlur = () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChangeSelect = () => {},
    options = [],
    onDelete,
    validationCb,
    columnProps = { size: 12 },
    diasbleStatusIcon,
    required,
    inputProps = {},
    inputSelectProps = {},
    statusDefault,
    disabled,
    flagDefaultStatus,
    hideBorder,
    inputClassName = "",
    isSelectConfigurator,
}) => {
    const [typeInput, setTypeInput] = useState<
        HTMLInputTypeAttribute | "select"
    >(type || "text");
    const [focused, setFocused] = useState<boolean>(false);
    const [inputStatus, setInputStatus] = useState<TInpuntStatus>({
        className: "",
        icon: "",
        status: "default",
    });

    const isSelect = type === "select";
    const isPassword = type === "password";

    const defaultValidationCb = (val: TInpuntValue) =>
        !(required && !(val as string)?.trim());

    const getValidationSatusData = (validation: boolean): TInpuntStatus => ({
        className: isSelectConfigurator
            ? ""
            : validation
            ? styles.inputSuccess
            : styles.inputError,
        icon: getIcon(type || "select", validation),
        status: validation ? "success" : "error",
    });

    const getInputStatusData = (): TInpuntStatus => {
        if (!focused) {
            return {
                className: "",
                icon: isPassword
                    ? getIcon(type, false)
                    : isSelectConfigurator
                    ? getIcon("select", false)
                    : "",
                status: "default",
            };
        }
        if (flagDefaultStatus) {
            return { className: "", icon: "", status: "default" };
        }
        if (disabled || statusDefault) {
            return { className: "", icon: "", status: "default" };
        }
        if (validationCb) {
            return getValidationSatusData(validationCb(value));
        }
        if (!validationCb && required) {
            return getValidationSatusData(defaultValidationCb(value));
        }
        if (!required) {
            return { className: "", icon: "", status: "default" };
        }
        return { className: "", icon: "", status: "default" };
    };

    const valueAttribute = hideValueAttribute ? {} : { value };

    useEffect(() => {
        setInputStatus(getInputStatusData());
    }, [value, validationCb, required, flagDefaultStatus]);

    return (
        <MDBCol
            {...columnProps}
            className={`${styles.container} ${columnProps.className || ""}`}
        >
            {label && (
                <p className="w-100">
                    {label}
                    {required && "*"}
                </p>
            )}
            <div className="position-relative">
                {!diasbleStatusIcon && (
                    <span
                        onClick={e => {
                            if (type === "password") {
                                setTypeInput(
                                    typeInput === "password"
                                        ? "text"
                                        : "password"
                                );
                                return;
                            }
                            if (inputStatus.status === "error" && onDelete) {
                                onDelete(e);
                            }
                        }}
                        className={onDelete || isPassword ? "pointer" : ""}
                    >
                        {inputStatus.icon}
                    </span>
                )}
                {!isSelect && (
                    <input
                        type={typeInput}
                        name={name}
                        onFocus={() => setFocused(true)}
                        placeholder={placeholder || ""}
                        className={`${styles.input} w-100 ${
                            inputStatus.className
                        } ${hideBorder ? "border-0" : ""} ${inputClassName}`}
                        id={id || name}
                        required
                        onChange={onChange}
                        disabled={disabled}
                        onBlur={onBlur}
                        {...inputProps}
                        {...valueAttribute}
                    />
                )}
                {isSelect && (
                    <select
                        name={name}
                        className={`${styles.input} w-100 ${inputStatus.className} pointer ${inputClassName}`}
                        onFocus={() => setFocused(true)}
                        id={id || name}
                        required
                        value={value}
                        onChange={onChangeSelect as never}
                        {...inputSelectProps}
                        {...valueAttribute}
                    >
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                )}
            </div>
        </MDBCol>
    );
};

export default Input;
