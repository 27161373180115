import { MDBCardBody, MDBCardText, MDBCardTitle } from "mdb-react-ui-kit";
import { FC } from "react";
import styles from "../../../../styles/home/Home.CardTour.module.scss";
import contactsSvg from "../../../../utils/svg-icon/contacts";

const CardBody: FC<{
    title: string;
    duration: number;
    nightDuration: number;
    destinationCount: number;
    isSmall: boolean;
    className: string;
    handleClick?: () => void;
}> = ({
    title,
    duration,
    nightDuration,
    destinationCount,
    isSmall,
    className = "",
    handleClick,
}) => {
    const { FlagLine, ClockLine } = contactsSvg;

    return (
        <MDBCardBody
            className={`d-flex flex-column justify-content-between pb-0 ${className}`}
            onClick={handleClick}
            style={{ cursor: "pointer" }}
        >
            <MDBCardTitle
                className={`${
                    isSmall ? styles.textTitleSmall : styles.textTitle
                } text-start fw-bold`}
                title={title}
            >
                {title}
            </MDBCardTitle>
            <div className="d-flex justify-content-start">
                <MDBCardText
                    className={`${
                        isSmall ? styles.captionSmall : styles.caption
                    } d-flex justify-content-center align-content-center m-0`}
                >
                    <ClockLine
                        className="me-1"
                        width="15"
                        height="15"
                        viewBox="0 -3 25 25"
                    />
                    {`${duration} days ${nightDuration} nights`}
                </MDBCardText>
                <MDBCardText
                    className={`${
                        isSmall ? styles.captionSmall : styles.caption
                    } ps-2`}
                >
                    <FlagLine
                        className="me-1"
                        width="15"
                        height="15"
                        viewBox="0 0 25 25"
                    />
                    {destinationCount} Destinations
                </MDBCardText>
            </div>
        </MDBCardBody>
    );
};

export default CardBody;
