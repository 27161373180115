import { MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import { FC } from "react";
import { checkRating } from "@/utils/common";
import styles from "../../../../styles/home/Home.CardTour.module.scss";
import userInterfaceSvg from "../../../../utils/svg-icon/userInterface";

const CardFooter: FC<{
    price: number;
    rating: number;
    reviewsCount: number;
    isSmall: boolean;
    className?: string;
    handleClick?: () => void;
}> = ({
    price,
    rating,
    reviewsCount,
    isSmall,
    className = "",
    handleClick,
}) => {
    const { StarFilled } = userInterfaceSvg;

    return (
        <MDBCardBody
            className={`${styles.dispShrink} justify-content-between pt-2 pb-4 ${className}`}
            style={{ cursor: "pointer" }}
            onClick={handleClick}
        >
            <div>
                <MDBCardText
                    className={`${
                        isSmall ? styles.textPriceSmall : styles.textPrice
                    } fw-bold m-0`}
                >
                    From {price} €
                </MDBCardText>
                <div className={`${styles.startingPriceStyle}`}>
                    Starting price per person
                    <br /> in double room
                </div>
            </div>
            <div className={`${styles.dispShrink} m-0 p-0`}>
                {checkRating(rating) && (
                    <MDBCardText
                        className={`${
                            isSmall ? styles.captionSmall : styles.caption
                        } ${styles.dispShrink} m-0`}
                    >
                        <StarFilled
                            className={`${styles.colorSvg} me-1`}
                            width="15"
                            height="15"
                            viewBox="0 2 25 25"
                        />
                        <span className="m-0 fw-bold">{Number(rating)}</span>
                    </MDBCardText>
                )}
                {reviewsCount > 0 && (
                    <MDBCardText
                        className={`${
                            isSmall ? "d-none" : styles.caption
                        } ms-2`}
                    >
                        {`(${reviewsCount} reviews)`}
                    </MDBCardText>
                )}
            </div>
        </MDBCardBody>
    );
};

export default CardFooter;
