import { URLS } from "@/utils/constants";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useRouter } from "next/router";
import styles from "../../../styles/navbar/DropdownMenu.module.scss";
import { randomUid } from "../../../utils/randomIds";
import fileTypesSvg from "../../../utils/svg-icon/fileTypes";
import userInterfaceSvg from "../../../utils/svg-icon/userInterface";

const DropdownMiniMenu = ({ onMouseLeave }: { onMouseLeave: () => void }) => {
    const router = useRouter();

    const hiwDropdownItems = [
        {
            title: "Play with us",
            id: randomUid(),
            icon: userInterfaceSvg.StarLine,
            identificator: "navigate-play-with-us",
            route: "/play-with-us",
        },
        {
            title: "Our Policies",
            id: randomUid(),
            icon: fileTypesSvg.FileDocLine,
            route: URLS.privacyPolicies,
        },
        {
            title: "FAQ",
            id: randomUid(),
            icon: fileTypesSvg.QuestionLine,
            identificator: "navigate-faq",
            route: "/faq",
        },
    ];

    return (
        <ul
            className={`${styles.dropdownMiniContent} d-lg-block d-none dropdown-menu py-3 `}
            aria-labelledby="smallDropdownMenu"
            onMouseLeave={onMouseLeave}
        >
            {hiwDropdownItems.map(el => (
                <div key={el.id} className="py-3 pe-5 ps-3">
                    <MDBRow
                        id={el.identificator}
                        className={styles.dropdownMiniText}
                        onClick={() => router.push(el.route)}
                    >
                        <MDBCol
                            size={3}
                            className="d-flex justify-content-end align-items-center px-2"
                        >
                            <el.icon />
                        </MDBCol>
                        <MDBCol>
                            <p className="my-0 align-self-center">{el.title}</p>
                        </MDBCol>
                    </MDBRow>
                </div>
            ))}
        </ul>
    );
};

export default DropdownMiniMenu;
