import ShoppingTagLine from "@/public/custom-icons/icons-2/icons/Shopping Tag/Line.svg";
import Cart from "../../public/custom-icons/icons-2/icons/Shopping Cart/Filled.svg";
import CartLine from "../../public/custom-icons/icons-2/icons/Shopping Cart/Line.svg";
import DiscountCouponLine from "../../public/custom-icons/icons-2/icons/Coupon Discount/Line.svg";
import DiscountCouponFilled from "../../public/custom-icons/icons-2/icons/Coupon Discount/Filled.svg";
import CarLine from "../../public/custom-icons/icons-2/icons/Car/Line.svg";
import FilterLine from "../../public/custom-icons/icons-2/icons/Filter/Line.svg";
import ShoppingBag from "../../public/custom-icons/icons-2/icons/Shopping Bag/Line.svg";

const shoppingSvg = {
    Cart,
    CartLine,
    DiscountCouponLine,
    CarLine,
    ShoppingBag,
    DiscountCouponFilled,
    FilterLine,
    ShoppingTagLine,
};

export default shoppingSvg;
