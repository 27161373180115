import style from "../../styles/common/ToastMessage.module.scss";
import { ETypeToastMessage } from "../../models/common/enums";
import userInterfaceSvg from "../svg-icon/userInterface";

const { CheckLine, CloseLine } = userInterfaceSvg;

export const getTypeMsgClass = (typeMsg: ETypeToastMessage) =>
    ({
        [ETypeToastMessage.INFO]: style.messageInfo,
        [ETypeToastMessage.ERROR]: style.messageError,
        [ETypeToastMessage.SUCCESS]: style.messageSuccess,
    }[typeMsg]);

export const getTypeMsgSvgIcon = (typeMsg: ETypeToastMessage) =>
    ({
        [ETypeToastMessage.INFO]: <div />,
        [ETypeToastMessage.ERROR]: <CloseLine />,
        [ETypeToastMessage.SUCCESS]: <CheckLine />,
    }[typeMsg]);
