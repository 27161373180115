import { FC } from "react";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { URLS } from "@/utils/constants";
import { useRouter } from "next/router";
import { randomUid } from "../../../../utils/randomIds";
import fileTypesSvg from "../../../../utils/svg-icon/fileTypes";
import userInterfaceSvg from "../../../../utils/svg-icon/userInterface";

const NavBarMHowItWorks: FC<{ className?: string }> = ({ className }) => {
    const router = useRouter();

    const howItWorksItems = [
        {
            title: "Play with us",
            id: randomUid(),
            icon: userInterfaceSvg.StarLine,
            identificator: "navigate-play-with-us",
            route: "/play-with-us",
        },
        {
            title: "Our Policies",
            id: randomUid(),
            icon: fileTypesSvg.FileDocLine,
            route: URLS.privacyPolicies,
        },
        {
            title: "FAQ",
            id: randomUid(),
            icon: fileTypesSvg.QuestionLine,
            identificator: "navigate-faq",
            route: "/faq",
        },
    ];

    return (
        <ul className={`py-2 ps-3 px-0 ${className}`}>
            {howItWorksItems.map(el => (
                <div key={el.id} className="py-3">
                    <MDBRow
                        id={el.identificator}
                        className="d-flex  align-items-center justify-content-center"
                        onClick={() => router.push(el.route)}
                    >
                        <MDBCol size={1}>
                            <el.icon />
                        </MDBCol>
                        <MDBCol size={11} className="me-auto">
                            <p className="my-0 align-self-center">{el.title}</p>
                        </MDBCol>
                    </MDBRow>
                </div>
            ))}
        </ul>
    );
};

NavBarMHowItWorks.defaultProps = {
    className: "",
};

export default NavBarMHowItWorks;
