/* eslint-disable no-bitwise, no-plusplus */

export const randomUid = (): string => {
    const symbols = "0123456789abcdefghilmnopqrstuvwxyz";
    const keys = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    let uuid = "";
    let i = 0;
    let rb = (Math.random() * 0xffffffff) | 0;
    while (i++ < 36) {
        const count = keys[i - 1];
        const r = rb & 0xf;
        const v = count === "x" ? r : (r & 0x3) | 0x8;
        uuid += count === "-" || count === "4" ? count : symbols[v];
        rb = i % 8 === 0 ? (Math.random() * 0xffffffff) | 0 : rb >> 4;
    }
    return uuid;
};
