import { SectionsTitleText } from "@/models/common/common";
import colors from "../../styles/colors.module.scss";

export const titleModalNewsletter: SectionsTitleText[] = [
    {
        text: "Join the newsletter to receive our discount code worth up to 500€",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: true,
    },
];

export const subtitleModalNewsletter: SectionsTitleText[] = [
    {
        text: " Be sure to check the email for the promocode and the possibility to book a free video call where we can assist you during your reservation process",
        color: colors.textBlack,
        fontWeight: "fw-bold",
        newLine: true,
    },
];
