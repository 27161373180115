import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import {
    MDBCol,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBRow,
} from "mdb-react-ui-kit";
import styles from "@/styles/common/CustomModal.module.scss";
import userInterfaceSvg from "@/utils/svg-icon/userInterface";
import SectionTitle from "@/common/SectionTitle";
import { ISectionTitle } from "@/models/common/common";
import { useIsClient } from "@/utils/hooks/useIsClient";
import { useIsSM } from "@/utils/hooks/useWindowSizes";

const CustomModal: FC<{
    sectionTitle?: ISectionTitle;
    displayModal: boolean;
    setShow?: (e: boolean) => void;
    setDisplayModal?: () => void;
    staticBackdrop?: boolean;
    children?: ReactNode;
    className?: string;
    classNameBody?: string;
    size?:
        | "sm"
        | "lg"
        | "xl"
        | "fullscreen"
        | "fullscreen-sm-down"
        | "fullscreen-md-down"
        | "fullscreen-lg-down"
        | "fullscreen-xl-down"
        | "fullscreen-xxl-down";
    noTitle?: boolean;
    classNameContent?: string;
    flagDisable?: boolean;
    showingDelay?: number;
}> = ({
    sectionTitle,
    displayModal,
    setDisplayModal,
    staticBackdrop,
    children,
    setShow,
    className = "",
    classNameBody = "mb-3 px-2 px-lg-5 mx-0",
    size = "lg",
    noTitle = false,
    classNameContent = "text-center w-100 border-0 px-2 px-lg-5 mt-5",
    flagDisable = false,
    showingDelay,
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const isClient = useIsClient();
    const { CloseLine } = userInterfaceSvg;

    const [isShown, setIsShown] = useState(!showingDelay);

    const setShowModal = useCallback(
        (e: boolean) => {
            if (setShow) {
                setShow(e);
            } else if (!e && setDisplayModal) {
                setDisplayModal();
            }
        },
        [setDisplayModal, setShow]
    );

    const renderCloseBtn = (
        <span
            className={`${styles.modalBtnClose} text-center pointer`}
            onClick={() => setShowModal(false)}
        >
            <CloseLine />
        </span>
    );

    const isSM = useIsSM();

    useEffect(() => {
        if (showingDelay) {
            setTimeout(() => {
                setIsShown(true);
            }, showingDelay);
        }
        if (flagDisable) {
            if (displayModal && isSM) {
                // eslint-disable-next-line sonarjs/no-duplicate-string
                document.body.classList.add("overflow-hidden");
                const root = document.getElementsByTagName("html")[0];
                root.classList.add("overflow-hidden");
            } else {
                document.body.classList.remove("overflow-hidden");
                const root = document.getElementsByTagName("html")[0];
                root.classList.remove("overflow-hidden");
            }
        }
    }, [displayModal, flagDisable, isSM]);

    return isClient && isShown ? (
        <MDBModal
            show={displayModal}
            setShow={(e: boolean) => !e && setDisplayModal?.()}
            tabIndex="-1"
            staticBackdrop={staticBackdrop}
        >
            <MDBModalDialog
                className={`${styles.modal} 
                mt-5 mt-md-4 px-0 ${className}
                `}
                size={size}
                shouldCloseOnOverlayClick={false}
                scrollable={!isSM}
            >
                <MDBModalContent className={styles.modalContent}>
                    <div className={classNameContent}>
                        {renderCloseBtn}
                        {!noTitle && (
                            <SectionTitle
                                sectionTitle={{
                                    classNameTitle: `text-center ${styles.title}`,
                                    classNameSubTitle: `text-center ${styles.subtitle}`,
                                    ...sectionTitle!,
                                }}
                            />
                        )}
                    </div>
                    <MDBModalBody
                        style={
                            noTitle
                                ? { paddingLeft: "0", paddingRight: "0" }
                                : {}
                        }
                    >
                        <MDBRow className="justify-content-center w-100 mx-0">
                            <MDBCol size={12} className={` ${classNameBody}  `}>
                                {children}
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    ) : (
        <div />
    );
};

export default CustomModal;
