import React, { FC } from "react";
import Link from "next/link";

import { useRouter } from "next/router";

import styles from "../../styles/ErrorPage/ErrorPage.module.scss";

const ErrorPageBody: FC<{
    isGlobal?: boolean;
    errorCode: string;
    errorMessage: string;
}> = ({ errorCode, errorMessage, isGlobal = false }) => {
    const router = useRouter();

    return (
        <div className={styles.container}>
            <span className={styles.title}>Oh no!</span>
            <span className={styles.FourOhFour}>{errorCode}</span>
            <p className={styles.message}>{errorMessage}</p>

            <div className={styles.buttonArea}>
                {!isGlobal && (
                    <p
                        className={styles.buttonBack}
                        onClick={() => router.back()}
                    >
                        Go back
                    </p>
                )}
                <Link href="/">
                    <p className={styles.buttonHome}>Homepage</p>
                </Link>
            </div>
        </div>
    );
};

export default ErrorPageBody;
