import { FC, ReactNode, useMemo, useState } from "react";
import { MDBBtn, MDBCol } from "mdb-react-ui-kit";
import { useRouter } from "next/router";
import CardTour from "@/components/home/cards/cardTour/CardTour";
import styles from "../../../../styles/navbar/NavBarM.module.scss";
import NavBarMLink from "./NavBarMLink";
import NavBarMBackIcon from "./NavBarMBackIcon";
import userInterfaceSvg from "../../../../utils/svg-icon/userInterface";
import {
    ToursByDestinationEntity,
    ToursByTourTypeEntity,
} from "../../../../models/home";
import { ENavBarMenuMobile } from "../../../../models/common/enums";
import { TBreackpointsCarousel } from "../../../../models/common/common";
import Carousel from "../../carousel/Carousel";

// TODO: Remove eslint disable
const NavBarMSubMenuSelected: FC<{
    className: string;
    icon: ReactNode;
    dropdownChoice: ENavBarMenuMobile;
    dropdownOptions: ToursByDestinationEntity[] | ToursByTourTypeEntity[];
    onClick: () => void;
}> = ({ className, icon, dropdownChoice, dropdownOptions, onClick }) => {
    const { ArrowRightSimpleLine } = userInterfaceSvg;
    const router = useRouter();
    const path = "/tours-destinations";

    const [selectedOption, setSelectedOption] = useState<number | null>(null);

    const options = useMemo(() => {
        return dropdownOptions.map(item => {
            if (dropdownChoice === ENavBarMenuMobile.ALL_TOURS) {
                return (item as ToursByTourTypeEntity).tourType;
            }
            return (item as ToursByDestinationEntity).city;
        });
    }, [dropdownOptions, dropdownChoice]);

    const breackPointCarousel: TBreackpointsCarousel = {
        0: {
            slidesPerView: 1.3,
            spaceBetween: 16,
            slidesOffsetAfter: 24,
            slidesOffsetBefore: 24,
        },
        576: {
            slidesPerView: 2.3,
            spaceBetween: 32,
            slidesOffsetAfter: 24,
            slidesOffsetBefore: 24,
        },
        768: {
            slidesPerView: 3,
            height: 149,
            spaceBetween: 32,
            slidesOffsetAfter: 24,
            slidesOffsetBefore: 24,
        },
        992: {
            slidesPerView: 1,
            width: 220,
            spaceBetween: 32,
            slidesOffsetAfter: 24,
            slidesOffsetBefore: 24,
        },
    };

    const visibleCards = useMemo(() => {
        if (selectedOption !== null) {
            return (
                dropdownOptions.at(selectedOption)?.tours?.map(element => {
                    return (
                        /* eslint-disable no-underscore-dangle */
                        <CardTour
                            className="mx-2 mx-md-0"
                            key={element._id}
                            content={element}
                            isSmall
                        />
                        /* eslint-enable no-underscore-dangle */
                    );
                }) ?? []
            );
        }
        return null;
    }, [dropdownOptions, selectedOption]);

    const handleRouting = () => {
        const url = {
            pathname: path,
            query: {
                tourType:
                    dropdownChoice === ENavBarMenuMobile.ALL_TOURS &&
                    selectedOption !== null
                        ? (
                              dropdownOptions.at(
                                  selectedOption
                              ) as ToursByTourTypeEntity
                          ).type
                        : null,
                destination:
                    dropdownChoice === ENavBarMenuMobile.DESTINATIONS &&
                    selectedOption !== null
                        ? (
                              dropdownOptions.at(
                                  selectedOption
                              ) as ToursByDestinationEntity
                          ).city
                        : null,
            },
        };
        if (router.pathname !== path) {
            router.push(url);
        } else {
            router.replace(url).then(() => router.reload());
        }
    };

    /* eslint-disable react/no-array-index-key */
    return (
        <MDBCol
            className={`${styles.navBarMSubMenu} ${className} container-fluid py-3 px-0 fadeInElement`}
        >
            <NavBarMLink
                icon={selectedOption ? "" : icon}
                icon2Active={<NavBarMBackIcon />}
                title={
                    selectedOption
                        ? dropdownOptions.at(selectedOption)!.title
                        : dropdownChoice === ENavBarMenuMobile.ALL_TOURS
                        ? "Tour types"
                        : "Destinations"
                }
                onClick={
                    selectedOption !== null
                        ? () => setSelectedOption(null)
                        : onClick
                }
                active
                subtitle=""
                icon2={undefined}
                className=""
            >
                {undefined}
            </NavBarMLink>
            <div
                className={`${styles.navBarMSubMenuSelectedContent} overflow-auto`}
            >
                {selectedOption === null &&
                    options.map((option, index) => (
                        <NavBarMLink
                            key={`${option}-${index}`}
                            title={option}
                            onClick={() => setSelectedOption(index)}
                            icon2={<ArrowRightSimpleLine />}
                            icon2Active={<NavBarMBackIcon />}
                            icon={undefined}
                            subtitle=""
                            active={false}
                            className=""
                        >
                            {undefined}
                        </NavBarMLink>
                    ))}
                {selectedOption !== null && (
                    <MDBCol className="m-4 fadeInElement ">
                        <p className={styles.navBarMSubMenuSelectedDescription}>
                            {dropdownOptions.at(selectedOption)?.body}
                        </p>

                        <div className="d-flex justify-content-center my-3">
                            <MDBBtn
                                className={`${styles.navBarMSubMenuSelectedBtn} rounded-9`}
                                onClick={handleRouting}
                            >
                                View More
                            </MDBBtn>
                        </div>
                    </MDBCol>
                )}
                {visibleCards && (
                    <Carousel
                        classNameContainer="d-flex justify-content-center align-items-center mt-4 px-0 mx-0 w-100"
                        breakpoints={breackPointCarousel}
                        containerSizes={{ xs: 12, md: 12, size: 12 }}
                        visibleSlides={2}
                    >
                        {visibleCards.map(el => (
                            <div className="d-flex align-items-center container-fluid justify-content-center px-0 mx-0">
                                {el}
                            </div>
                        ))}
                    </Carousel>
                )}
            </div>
        </MDBCol>
    );
};
export default NavBarMSubMenuSelected;
